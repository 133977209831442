import React, { useState } from 'react';
import { Link, Redirect, useParams } from 'react-router-dom';
import { Button, Tabs } from 'antd';
import { LinkOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { ERROR, LOADING, PROBLEM_TAB, ROUTES, SUCCESS, VERDICT } from '~/config/constants';
import { history } from '~/store/reducers';
import { Statement } from '~/components/Problems/View/Statement';
import { CodeEditor } from '~/components/Problems/View/CodeEditor';
import { useContestState, useFlagsDispatch } from '~/hooks';
import { Loader } from '~/components';
import { ContestParamsType, ContestSettingsParams, ContestTab, Judge, LoaderAction, NotificationType, ProblemTab, ProblemVerdict, ProgrammingLanguage, Source } from '~/types';
import { apiSubmitContestProblem } from '~/services';
import { ProblemState } from '~/store/types';

import './styles.scss';

const { TabPane } = Tabs;

export const ContestProblemView = () => {
  
  const { key, subTab, subSubTab } = useParams<ContestParamsType>();
  const { problems, registered, canRejudge, settings, timing, canRegister, canUpdate } = useContestState(key);
  const [source, setSource] = useState<Source>({
    language:
      (subTab && problems[subTab]?.settings?.languages?.[0]) || ProgrammingLanguage.TEXT, plainText: ''
  });
  const { updateFlags } = useFlagsDispatch();
  const { t } = useTranslation();
  
  const onSubmit = async (setLoading: LoaderAction) => {
    if (subTab) {
      const now = new Date().getTime();
      setLoading([now, LOADING]);
      const result = await apiSubmitContestProblem(key, subTab, source.plainText, source.language)();
      if (result.success === SUCCESS) {
        if (result.object.answer === ProblemVerdict.AC) {
          updateFlags({
            lastNotification: {
              type: NotificationType.SUCCESS,
              title: result.object.answer,
              description: VERDICT[ProblemVerdict.AC].print
            }
          })();
          setLoading([now, SUCCESS]);
          history.push(ROUTES.CONTESTS.VIEW(key, ContestTab.SUBMISSIONS));
        } else if (result.object.answer === ProblemVerdict.PA) {
          updateFlags({
            lastNotification: {
              type: NotificationType.SUCCESS,
              title: result.object.answer,
              description: `${t(VERDICT[ProblemVerdict.PA].print)}, ${result.object.submitPoints} ${t('points')}`
            }
          })();
          setLoading([now, SUCCESS]);
        } else {
          updateFlags({
            lastNotification: {
              type: NotificationType.WARNING,
              title: result.object.answer,
              description: t(VERDICT[result.object.answer]?.print || result.object.answer)
            }
          })();
          setLoading([now, ERROR]);
        }
      } else {
        updateFlags({ lastNotification: { type: NotificationType.ERROR, description: result.message } })();
        setLoading([now, ERROR]);
      }
    }
  };
  
  if (!subTab) {
    return <Redirect to={ROUTES.CONTESTS.VIEW(key, ContestTab.PROBLEMS)} />;
  }
  if (!problems[subTab]) {
    return <Redirect to={ROUTES.CONTESTS.VIEW(key, ContestTab.PROBLEMS)} />;
  }
  if (problems[subTab].judge !== Judge.JUKI_JUDGE) {
    return (
      <div>
        <h5>{problems[subTab].name}</h5>
        <a href={problems[subTab].link} target="_blank" rel="noopener noreferrer">
          <Button icon={<LinkOutlined />}>{t('view') + ' ' + t('problem')}</Button>
        </a>
      </div>
    );
  }
  const now = new Date().getTime();
  const canSubmit = (registered && settings.start <= now && now < settings.start + timing.duration) && (
    (!settings[ContestSettingsParams.LIMIT_PROBLEM_TIME]) ||
    (settings[ContestSettingsParams.LIMIT_PROBLEM_TIME] && (now < settings.start + problems[subTab].start + problems[subTab].duration))
  );
  return (
    <Loader
      loading={!problems[subTab]}
      component={() => (
        <div className="content-contest-problem-viewer">
          <div className="content-tab">
            <Tabs
              activeKey={subSubTab}
              onChange={(_subSubTab: string) => history.push(ROUTES.CONTESTS.VIEW(key, ContestTab.PROBLEM, subTab, _subSubTab as ProblemTab))}
            >
              <TabPane
                tab={<div className="tab-label">{t(PROBLEM_TAB[ProblemTab.STATEMENT].print)}</div>}
                key={ProblemTab.STATEMENT}
              />
              {canSubmit && (
                <TabPane
                  tab={<div className="tab-label">{t(PROBLEM_TAB[ProblemTab.EDITOR].print)}</div>}
                  key={ProblemTab.EDITOR}
                />
              )}
            </Tabs>
            {!registered && !canRejudge && !canUpdate && (
              <Link to={ROUTES.CONTESTS.VIEW(key, ContestTab.OVERVIEW)}>
                <Button type="primary">
                  {t('to submit, first register')}
                </Button>
              </Link>
            )}
          </div>
          <div className="content-main">
            {subSubTab === ProblemTab.STATEMENT && (
              <Statement
                problem={{
                  ...problems[subTab],
                  settings: {
                    ...problems[subTab]?.settings,
                    languages: problems[subTab]?.settings?.languages.filter(lan => settings.languages.indexOf(lan) !== -1)
                  }
                } as unknown as ProblemState}
                setSource={setSource}
                canSubmit={canSubmit}
                registered={registered}
                canRegister={canRegister && (new Date().getTime() <= settings.start + timing.duration)}
                canRejudge={canRejudge}
                languages={problems[subTab]?.settings.languages}
              />
            )}
            {subSubTab === ProblemTab.EDITOR && (registered || canUpdate || canRejudge)  && (
              <CodeEditor
                redirect={!canSubmit ? ROUTES.CONTESTS.VIEW(key, ContestTab.PROBLEM, subTab, ProblemTab.STATEMENT) : ''}
                source={source}
                setSource={setSource}
                onSubmit={onSubmit}
                languages={problems[subTab]?.settings.languages}
              />
            )}
          </div>
        </div>
      )}
    />
  );
};
