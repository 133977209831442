import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button } from 'antd';
import { CheckOutlined, CloseOutlined, LinkOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { Card, Period, TimerClock } from '~/components/shared';
import { ROUTES } from '~/config/constants';
import { useContestDispatch, useContestState } from '~/hooks';
import { ContestParamsType, ContestSettingsParams, ContestTab, Judge, ProblemTab } from '~/types';

import './styles.scss';

export const ContestProblems = () => {
  
  const { loadContest } = useContestDispatch();
  const { key } = useParams<ContestParamsType>();
  const { problems, settings, timing, registered } = useContestState(key);
  const { t } = useTranslation();
  
  return (
    <div className="content">
      {Object.values(problems).map(problem => {
        const now = new Date().getTime();
        const canSubmit = (registered && settings.start <= now && now < settings.start + timing.duration) && (
          (!settings[ContestSettingsParams.LIMIT_PROBLEM_TIME]) ||
          (settings[ContestSettingsParams.LIMIT_PROBLEM_TIME] && (now < settings.start + problem.start + problem.duration))
        );
        return (
          <Card
            style={{ borderTop: `8px solid ${problem.color}` }}
            contentHead={
              <div className="problem-status child-center">
                <div
                  className={'text-m bold problem-index' + (problem.myPoints !== -1 ? (problem.myPoints === problem.points ? ' accepted' : ' wrong') : '')}
                >
                  {problem.myPoints !== -1 && (
                    problem.myPoints === problem.points ? <CheckOutlined /> : <CloseOutlined />
                  )}
                  {problem.index}
                </div>
                <div className="problem-id text-xs semi-bold">ID: {problem.id}</div>
              </div>
            }
            contentTitle={<div className="text-m bold"> {problem.name} </div>}
            contentInfo={
              <div className="problem-info">
                {settings[ContestSettingsParams.LIMIT_PROBLEM_TIME] && (
                  <div className="problem-timing child-center">
                    {!canSubmit ? (<div className="closed child-center text-s bold">{t('closed')}</div>) : (
                      <TimerClock
                        startDate={new Date(settings.start + problem.start)}
                        endDate={new Date(settings.start + problem.start + problem.duration)}
                        labels={{
                          [Period.LIVE]: 'close on',
                          [Period.PAST]: 'closed ago',
                          [Period.CALC]: '...',
                          [Period.FUTURE]: 'open on'
                        }}
                        onFinish={loadContest(key)}
                      />
                    )}
                  </div>
                )}
                <div className="problem-info-data child-center">
                  <div className="problem-score child-center">
                    <div className="problem-score-points text-s bold">{problem.points}</div>
                    <div className="problem-score-label text-t semi-bold">{t('score')}</div>
                  </div>
                  {/*<div className="divider"> |</div>*/}
                  {/*<div className="problem-success child-center">*/}
                  {/*  <div className="problem-success-points">{(problem.successRate * 100).toFixed(1)} %</div>*/}
                  {/*  <div className="problem-success-label">{t('success rate')}</div>*/}
                  {/*</div>*/}
                </div>
              </div>
            }
            contentAction={problem.judge === Judge.JUKI_JUDGE ? (
              <Link to={ROUTES.CONTESTS.VIEW(key, ContestTab.PROBLEM, problem.index, ProblemTab.STATEMENT)}>
                <Button>
                  {t(((problem.myPoints !== -1 && problem.myPoints === problem.points) || (
                    !canSubmit
                  )) ? 'view' : 'solve') + ' ' + t('problem')}
                </Button>
              </Link>
            ) : (
              <a href={problem.link} target="_blank" rel="noopener noreferrer">
                <Button icon={<LinkOutlined />}>
                  {t(problem.myPoints !== -1 && problem.myPoints === problem.points ? 'view' : 'solve') + ' ' + t('problem')}
                </Button>
              </a>
            )}
            key={problem.index}
          />
        );
      })}
    </div>
  );
};
