
import { UserState } from '../../store/types';
import { API, authorizedRequest, POST, PUT} from '../server.config';
import { AccountStatus, ErrorResponse, OkListResponse, OkObjectResponse, SearchParams } from '../../commons/types';
import { clean } from './index';
import { searchBuilder } from '../../commons/helpers';

export const apiUsersList = (params: SearchParams, nickname?: string) => async (): Promise<ErrorResponse | OkListResponse<UserState>> => {
  return clean(await authorizedRequest(API.ACCOUNT.USER(nickname) + searchBuilder(params)));
};

export const apiUser = (nickname: string) => async (): Promise<ErrorResponse | OkObjectResponse<UserState>> => {
  return clean(await authorizedRequest(API.ACCOUNT.USER(nickname)));
};

export const apiUserChangeStatus = (nickname: string, status: AccountStatus) => async (): Promise<ErrorResponse | OkObjectResponse<UserState>> => {
  return clean(await authorizedRequest(API.ACCOUNT.CHANGE_STATUS(nickname, status), POST));
};

export const apiUserChangePermissions = (nickname: string, permissions: Array<{ key: string, value: string }>) => async (): Promise<ErrorResponse | OkObjectResponse<UserState>> => {
  return clean(await authorizedRequest(API.ACCOUNT.CHANGE_PERMISSIONS(nickname), PUT, JSON.stringify(permissions)));
};
