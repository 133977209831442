import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';

import { useAccountDispatch, useFlagsDispatch } from '~/hooks';
import { LoaderAction, NotificationType } from '~/types';
import { SignUpInput, SignUpModal } from '~/components';

const signUpSchema = yup.object().shape({
  givenName: yup.string()
    .required('Required')
    .min(3, 'must be at least 3 characters'),
  familyName: yup.string()
    .required('Required')
    .min(3, 'must be at least 3 characters'),
  email: yup.string()
    .required('Required')
    .email('must be a valid email'),
  checkbox: yup.boolean()
    .required('you must accept the terms of service')
    .oneOf([true], 'you must accept the terms of service'),
  nickname: yup.string()
    .required('Required')
    .min(3, 'must be at least 3 characters'),
  password: yup.string()
    .required('Required')
    .min(8, 'must be at least 8 characters')
    .matches(/^(?=.*\d)(?=.*[a-z])[\w~@#$%^&*+=`|{}:;!.?"()]{8,}$/,
      'must have at least one uppercase, one lowercase letter and one number'),
  passwordConfirmation: yup.string()
    .required('Required')
    .oneOf([yup.ref('password'), ''], 'both passwords must match')
});

const SignUp = () => {
  
  const useFormSignUpInput = useForm<SignUpInput>({
    resolver: yupResolver(signUpSchema),
    mode: 'onChange',
    reValidateMode: 'onChange'
  });
  const { signUp, googleSignIn } = useAccountDispatch();
  const { updateFlags } = useFlagsDispatch();
  
  const onSubmit = (data: SignUpInput, setLoading: LoaderAction) => {
    signUp(data.givenName, data.familyName, data.nickname, data.email, data.password, setLoading)();
  };
  const loginWithGoogle = (setLoading?: LoaderAction) => (response: any) => {
    if (response && !response.error) {
      googleSignIn(response.tokenId, setLoading)();
    } else {
      updateFlags({ lastNotification: { type: NotificationType.ERROR, description: response.error } })();
    }
  };
  
  return (
    <SignUpModal
      imageSource="/images/juki-sign-person.svg"
      onCancel={updateFlags({ openSignUpModal: false })}
      onSubmit={onSubmit}
      useFormSignUpInput={useFormSignUpInput}
      signUpWithGoogle={loginWithGoogle}
      reactAppGoogleClientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ''}
    />
  );
};

export default SignUp;
