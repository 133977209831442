export enum ProfileSettingOptions {
  NOTIFICATION = 'emailNotification',
  TALKS = 'newTalk',
  LOW_RATE = 'lowRate',
  SOCIAL = 'socialDis',
  DIAGNOSTIC = 'diagnostic',
  LANGUAGE = 'preferredLanguage',
  THEME = 'preferredTheme'
}

export enum AccountStatus { // SYNCHRONIZED CONSTANT
  ACTIVE = 'ACTIVE',
  REPORTED = 'REPORTED',
  ARCHIVED = 'ARCHIVED'
}
