import React, { useEffect, useState } from 'react';
import { Button, Select } from 'antd';
import { PlusCircleOutlined, LinkOutlined, ExclamationOutlined } from '@ant-design/icons';

import { MetaProblemSearcher } from '../../../../commons/types/contest';

import './ProblemSearcher.scss';

const { Option } = Select;

type Props = {
  selectProblem: (problems: Array<MetaProblemSearcher>) => void
  judgeData: { tags: Array<string>, problems: Array<MetaProblemSearcher> },
}

export const ProblemSearcher = ({ selectProblem, judgeData }: Props) => {
  const [problem, setProblem] = useState<MetaProblemSearcher | undefined>();
  useEffect(() => setProblem(judgeData.problems[0]), [judgeData.problems]);
  useEffect(() => {
    // if (judgeData.tags.length) {
    //   JUDGE_TAGS_SELECTOR.values.length = 0;
    //   JUDGE_TAGS_SELECTOR.values.push(...judgeData.tags.map(tag => ({
    //     name: tag === '__ALL__' ? 'ALL' : tag,
    //     value: tag
    //   })));
    //   setFilters(prevState => ({ ...prevState, tag: judgeData.tags[0] }));
    // }
  }, [judgeData.tags]);
  return (
    <div className="content-problem-searcher">
      <div className="content-problem-searcher-actions">
        {judgeData.problems.length > 1 && (
          <Button
            onClick={() => selectProblem([judgeData.problems[Math.floor(Math.random() * judgeData.problems.length)]])}
            icon={<ExclamationOutlined />}
          >
            Select a random problem
          </Button>
        )}
      </div>
      <div className="content-problem-picker">
        {problem && judgeData.problems.length ? (
          <>
            <Select
              dropdownClassName="dropdown-problem-searcher"
              showSearch
              value={JSON.stringify(problem)}
              onSelect={value => setProblem(JSON.parse(value) as MetaProblemSearcher)}
            >
              {judgeData.problems.map((problem) => (
                <Option value={JSON.stringify(problem)}>
                  <div className="option-problem-name">{problem.id} {problem.name}</div>
                  <div className="option-problem-tag text-xs semi-bold">
                    {problem.tag}
                    <span className="option-problem-rating">{problem.rating}</span>
                  </div>
                </Option>
              ))}
            </Select>
            <Button onClick={() => selectProblem([problem])} icon={<PlusCircleOutlined />}>
              Add
            </Button>
            <a href={problem.url} target="_blank" rel="noopener noreferrer">
              <Button icon={<LinkOutlined />}>
                View
              </Button>
            </a>
          </>
        ) : (
          <div> There aren't problem to select </div>
        )}
      </div>
    </div>
  );
};
