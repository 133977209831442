import {
  ProblemActions,
  ProblemActionTypes,
  ProblemsListActions,
  ProblemsListActionTypes,
  ProblemsState
} from '../types';
import { EMPTY_PROBLEM } from '~/config/constants';
import { objectsUpdate } from '~/helpers';

export const problems = (state: ProblemsState = {}, action: ProblemActionTypes) => {
  const newState = { ...state };
  switch (action.type) {
    case ProblemActions.UPDATE_PROBLEMS:
      action.problems.forEach(problem => {
        if (newState[problem.id]?.settings?.groupsPoint) {
          newState[problem.id].settings.groupsPoint = {};
        }
        newState['' + problem.id] = objectsUpdate(EMPTY_PROBLEM, newState[problem.id], problem);
      });
      return newState;
    default:
      return state;
  }
};

export const problemsList = (state: Array<string> = [], action: ProblemsListActionTypes) => {
  switch (action.type) {
    case ProblemsListActions.REPLACE_PROBLEMS_LIST:
      return [...action.problems];
    default:
      return state;
  }
};
