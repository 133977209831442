import React, { ReactNode } from 'react';
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons';
import { Layout } from 'antd';

import { classNames } from '~/commons/helpers';
import { useFlagsDispatch, useFlagsState } from '../../../hooks';

import './Layouts.scss';

const { Sider } = Layout;
/*
 <LayoutBody>

 </LayoutBody>
 */
// export const LayoutBody = ({ children }: { children: ReactNode }) => {
//   return (
//     <div className="layout-body">
//       <div className="content-layout-body">
//         {children}
//       </div>
//     </div>
//   );
// }

/*
 <LayoutSideBar>
 ...
 </LayoutSideBar>
 <LayoutSideMenu>
 ...
 </LayoutSideMenu>
 ---------------------------------------
 |         |                           |
 |  SIDE   |                           |
 |         |       SIDE  MAIN          |
 |  BAR    |                           |
 |         |                           |
 ---------------------------------------
 */
export const LayoutSideBar = ({ className, children }: { className?: string, children: ReactNode }) => {
  const { closeSideBar } = useFlagsState();
  const { updateFlags } = useFlagsDispatch();
  return (
    <div
      className={classNames('layout-side-bar', { [className + '']: !!className }, { open: !closeSideBar }, { close: closeSideBar })}>
      <div className="content-layout-side-bar">
        {children}
      </div>
      <div onClick={updateFlags({ closeSideBar: !closeSideBar })} className="button-action-open-close">
        {closeSideBar ? <RightCircleOutlined /> : <LeftCircleOutlined />}
      </div>
    </div>
  );
};

export const JukiSider = ({ sideBar, sideMain }: { className?: string, sideBar: (isClosed: boolean) => ReactNode, sideMain: (isClosed: boolean) => ReactNode }) => {
  const { closeSideBar } = useFlagsState();
  const { updateFlags } = useFlagsDispatch();
  return (
    <Layout className="juki-layout-sider">
      <Sider
        collapsible
        onCollapse={(collapsed) => {
          updateFlags({ closeSideBar: collapsed })();
        }}
        breakpoint="lg"
        className={classNames('juki-layout-sider-side-bar', { open: !closeSideBar }, { close: closeSideBar })}
      >
        {sideBar(closeSideBar)}
      </Sider>
      <Layout className={classNames('juki-layout-sider-side-main', { open: !closeSideBar }, { close: closeSideBar })}>
        {sideMain(closeSideBar)}
      </Layout>
    </Layout>
    
    
  );
};

export const LayoutSideMain = ({ className, children }: { className?: string, children: ReactNode }) => {
  const { closeSideBar } = useFlagsState();
  return (
    <div
      className={classNames('layout-side-main', { [className + '']: !!className }, { 'side-bar-open': !closeSideBar }, { 'side-bar-close': closeSideBar })}>
      <div className="content-layout-side-main">
        {children}
      </div>
    </div>
  );
};

/*
 <LayoutTitle>
 ...
 </LayoutTitle>
 <LayoutMenu>
 ...
 </LayoutMenu>
 <LayoutMainFooter>
 ...
 </LayoutMainFooter>

 -----------------------------------------
 |  -----------------------------------  |
 |  |            TITLE                |  |
 |  -----------------------------------  |
 |  -----------------------------------  |
 |  |                                 |  |
 |  |                                 |  |
 |  |            MAIN                 |  |
 |  |                                 |  |
 |  |                                 |  |
 |  -----------------------------------  |
 |  -----------------------------------  |
 |  |         MAIN FOOTER             |  |
 |  -----------------------------------  |
 */

export const LayoutTitle = ({ className, children }: { className?: string, children: ReactNode }) => {
  return (
    <Layout className={classNames('juki-layout-title', { [className + '']: !!className })}>
      <div className="juki-layout-title-space">
        {children}
      </div>
    </Layout>
  );
};


export const JukiLayout = ({ className, children, type = 'main', fullWidth = false }: { className?: string, children: ReactNode, type?: 'title' | 'main', fullWidth?: boolean }) => {
  return (
    <section className={classNames('juki-layout-' + type, { [className + '']: !!className }, {'full-width': fullWidth})}>
      <div className={`juki-layout-${type}-space`}>
        {children}
      </div>
    </section>
  );
};

export const LayoutMain = ({ className, children }: { className?: string, children: ReactNode }) => {
  return (
    <div className={classNames('layout-main', { [className + '']: !!className })}>
      <div className="content-layout-main">
        {children}
      </div>
    </div>
  );
};

export const LayoutMainFooter = ({ className, children }: { className?: string, children: ReactNode }) => {
  return (
    <div className={classNames('layout-footer', { [className + '']: !!className })}>
      <div className="content-layout-footer">
        {children}
      </div>
    </div>
  );
};

/*
 <LayoutBody>

 </LayoutBody>
 */
// export const LayoutBody = ({ children }: { children: ReactNode }) => {
//   return (
//     <div className="layout-body">
//       <div className="content-layout-body">
//         {children}
//       </div>
//     </div>
//   );
// }

/*
 <LayoutSideMenu>
 ...
 </LayoutSideMenu>
 <LayoutSideBar>
 ...
 </LayoutSideBar>

 ---------------------------------------
 |              HEADER                 |
 ---------------------------------------
 |         |                           |
 |  SIDE   |                           |
 |         |       SIDE  MAIN          |
 |  BAR    |                           |
 |         |                           |
 ---------------------------------------
 */
