import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Divider, Input, Modal, Select, Tabs, Upload } from 'antd';
import { CloudUploadOutlined, DownloadOutlined, PlusCircleOutlined, UploadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { apiProblemTestCase, apiProblemUploadTestCase, downloadFile, getProblemTestCases } from '~/services';
import { LoaderState, ProblemMode, ProblemParamsType, TestCase, TypeTestCase } from '~/types';
import { ErrorNotification, FieldEditable, Loader, LoaderButton, SuccessNotification } from '~/components';
import { useProblemsDispatch, useProblemState } from '~/hooks';
import { mex } from '~/helpers';
import { ERROR, LOADING, NONE, PROBLEM_MODE, PROBLEM_MODES, SUCCESS } from '~/config/constants';
import { ModalData, TestCaseViewerProps } from './types';

import './styles.scss';

const { TextArea } = Input;
const { TabPane } = Tabs;
const { Option } = Select;

const ACCEPTED_MAXIMUM_SIZE_BLOB = 500000;

const modalDataDefault: ModalData = {
  visible: false,
  file: null,
  text: '',
  key: '',
  index: 0,
  typeFile: TypeTestCase.INPUT,
  textCaseId: '',
  group: 0,
  mode: ProblemMode.TOTAL,
  bigFile: false
};

const hasBlob = (prop: any): prop is { file: Blob } => {
  return !!(prop && prop.file);
};

const TestCaseViewer = ({ modalData, setModalData, setReRender }: TestCaseViewerProps) => {
  
  const [test, setTest] = useState({ group: modalData.group, text: modalData.text });
  const { t } = useTranslation();
  useEffect(() => {
    setTest({ group: modalData.group, text: modalData.text });
  }, [modalData]);
  
  return (
    <Modal
      visible={modalData.visible}
      onOk={() => null}
      onCancel={() => setModalData(modalDataDefault)}
      footer={null}
      className="layout-test-case-editor"
      key={modalData.textCaseId + '_' + modalData.typeFile}
    >
      <Tabs defaultActiveKey={!!modalData.text ? '1' : '2'}>
        <TabPane tab="Viewer" key="1">
          <div className="group-box"><span className="text-m bold">Group :</span>{test.group}</div>
          {modalData.bigFile ? <div>{t('file too large to display')}</div> : (
            <div className="test-case-box">
              <span>{test.text}</span>
            </div>
          )}
          <Button
            icon={<DownloadOutlined />}
            onClick={() => {
              if (modalData.file) {
                downloadFile(modalData.file, 'txt', modalData.key + '_input_' + (modalData.index + 1) + '.txt');
              }
            }}
            disabled={!modalData.file}
          >
            {t('download test case')}
          </Button>
        </TabPane>
        <TabPane tab="Editor" key="2">
          {modalData.mode === ProblemMode.POINTS && (
            <div className="group-box">
              <span className="text-m bold">Group:</span>
              <FieldEditable<number>
                value={test.group}
                onChange={(value) => setTest(prevState => ({ ...prevState, group: value }))}
              />
            </div>
          )}
          {modalData.bigFile ? <div>FILE TOO LARGE TO EDIT</div> : (
            <TextArea
              autoSize={{ minRows: 10, maxRows: 20 }}
              value={test.text}
              onChange={({ target }) => setTest(prevState => ({ ...prevState, text: target.value }))}
              autoFocus={!modalData.text}
            />
          )}
          <LoaderButton
            onClick={(setLoader) => async () => {
              setLoader([1, LOADING]);
              const result = await apiProblemUploadTestCase(modalData.key, modalData.typeFile, test.group, modalData.bigFile ? modalData.file : new Blob([test.text]), modalData.textCaseId)();
              if (result.success === SUCCESS) {
                SuccessNotification({ description: 'Success on load a file' });
                setLoader([1, SUCCESS]);
                setModalData(modalDataDefault);
                setReRender(prevState => prevState + 1);
              } else {
                setLoader([1, ERROR]);
                ErrorNotification({ description: 'Error on load a file' });
              }
            }}
            icon={<CloudUploadOutlined />}
          >
            {t('upload test case')}
          </LoaderButton>
        </TabPane>
      </Tabs>
    </Modal>
  );
};

export const ContestCreateTests = () => {
  
  const { key } = useParams<ProblemParamsType>();
  const [listTestCases, setListTestCases] = useState<Array<TestCase>>([]);
  const [reRender, setReRender] = useState(0);
  const [loader, setLoader] = useState<LoaderState>([1, NONE]);
  const [modalData, setModalData] = useState<ModalData>(modalDataDefault);
  const problem = useProblemState(key);
  const { updateProblem } = useProblemsDispatch();
  const [groupsPoint, setGroupPoints] = useState<{ [key: string]: number }>(problem.settings.groupsPoint);
  const { t } = useTranslation();
  useEffect(() => {
    const now = new Date().getTime();
    setLoader([now, LOADING]);
    getProblemTestCases(key)().then(result => {
      if (result.success === SUCCESS) {
        setListTestCases(result.list);
      } else {
        ErrorNotification({ description: 'Error on load the test cases' });
      }
      setLoader([now, SUCCESS]);
    });
  }, [key, reRender]);
  useEffect(() => setGroupPoints(problem.settings.groupsPoint), [problem.settings.groupsPoint]);
  
  return (
    <Loader
      loading={loader[1] === LOADING}
      component={() => (
        <div className="test-cases-layout">
          <div className="content-problem-mode-save">
            <div>
              {t('problem Mode')}:
              <Select
                onChange={(mode: ProblemMode) => {
                  updateProblem({ ...problem, settings: { ...problem.settings, mode } }, setLoader)();
                }}
                value={problem.settings.mode}
                style={{ width: '110px' }}
              >
                {PROBLEM_MODES.map(input => (
                  <Option value={input}> {PROBLEM_MODE[input].print} </Option>
                ))}
              </Select>
            </div>
            {problem.settings.mode === ProblemMode.POINTS && (
              <div>
                <LoaderButton
                  onClick={() => () => {
                    updateProblem({ ...problem, settings: { ...problem.settings, groupsPoint } }, setLoader)();
                  }}
                >{t('save')}</LoaderButton>
              </div>
            )}
          </div>
          {problem.settings.mode === ProblemMode.POINTS && (
            <div className="content-group-point">
              <div className="group-point-box">
                <div className="group-point-group">{t('group')}</div>
                <div className="group-point-point">{t('point')}</div>
              </div>
              {Object.keys(groupsPoint).map((group, index) => {
                return (
                  <div className="group-point-box">
                    <div className="group-point-group">
                      <FieldEditable
                        value={group}
                        onChange={(value) => {
                          const newGroups: { [key: string]: number } = {};
                          Object.keys(groupsPoint).forEach((g, i) => {
                            if (index === i) {
                              newGroups[value] = groupsPoint[g];
                            } else {
                              newGroups[g] = groupsPoint[g];
                            }
                          });
                          setGroupPoints(newGroups);
                        }}
                      />
                    </div>
                    <div className="group-point-point">
                      <FieldEditable<number>
                        value={groupsPoint[group]}
                        onChange={(value) => setGroupPoints(prevState => ({ ...prevState, [group]: value }))}
                      />
                    </div>
                  </div>
                );
              })}
              <div className="group-point-box">
                <Button
                  icon={<PlusCircleOutlined />}
                  onClick={() => {
                    setGroupPoints(prevState => ({
                      ...prevState,
                      [mex(Object.keys(prevState).map(group => +group))]: 0
                    }));
                  }}
                />
              </div>
            </div>
          )}
          <TestCaseViewer modalData={modalData} setModalData={setModalData} setReRender={setReRender} />
          <Divider />
          <div className="test-cases-box">
            <h5>{t('test cases')}</h5>
            <div className="test-case-list-box">
              <div className="item-test-case">
                <div className="text-l bold" title="TEST CASE ID">#</div>
                {problem.settings.mode === ProblemMode.POINTS && (
                  <div className="text-l bold test-case-group">{t('group')}</div>
                )}
                <div className="text-l bold test-case-input">{t('input')}</div>
                <div className="text-l bold test-case-output">{t('output')}</div>
              </div>
              {listTestCases.map((testCase, index) => (
                <div className="item-test-case">
                  <div title={testCase.id}>{index + 1}</div>
                  {problem.settings.mode === ProblemMode.POINTS && (
                    <div className="test-case-group">{testCase.group}</div>
                  )}
                  {[TypeTestCase.INPUT, TypeTestCase.OUTPUT].map(type => (
                    <div className={'test-case-' + type}>
                      <div>
                        {testCase[type === TypeTestCase.INPUT ? 'input' : 'output'] ? (
                          <div>
                            {type} {t('file OK')}
                            <LoaderButton
                              type="text"
                              onClick={(setLoader) => async () => {
                                setLoader([1, LOADING]);
                                const file = await apiProblemTestCase(key, type, testCase.id)();
                                if (file) {
                                  setModalData({
                                    visible: true,
                                    file,
                                    text: file.size < ACCEPTED_MAXIMUM_SIZE_BLOB ? await file.text() : '',
                                    key,
                                    index,
                                    typeFile: type,
                                    textCaseId: testCase.id,
                                    group: testCase.group,
                                    mode: problem.settings.mode,
                                    bigFile: !(file.size < ACCEPTED_MAXIMUM_SIZE_BLOB)
                                  });
                                  setLoader([1, SUCCESS]);
                                } else {
                                  setLoader([1, ERROR]);
                                }
                              }}
                            >
                              {t('view')} / {t('edit')}
                            </LoaderButton>
                          </div>
                        ) : <div>{t('no input file')}
                          <Button type="text" onClick={() => {
                            setModalData({
                              visible: true,
                              file: new Blob(['']),
                              text: '',
                              key,
                              index,
                              typeFile: type,
                              textCaseId: testCase.id,
                              group: testCase.group,
                              mode: problem.settings.mode,
                              bigFile: false
                            });
                          }
                          }>{t('new')}</Button>
                        </div>}
                        <Upload
                          customRequest={async (...props) => {
                            if (hasBlob(props[0])) {
                              setModalData({
                                visible: true,
                                file: props[0].file,
                                text: props[0].file.size < ACCEPTED_MAXIMUM_SIZE_BLOB ? await props[0].file.text() : '',
                                key,
                                index,
                                typeFile: type,
                                textCaseId: testCase.id,
                                group: testCase.group,
                                mode: problem.settings.mode,
                                bigFile: !(props[0].file.size < ACCEPTED_MAXIMUM_SIZE_BLOB)
                              });
                              // @ts-ignore
                              props[0].onSuccess?.({}, props[0].file);
                            }
                          }}
                        >
                          <Button icon={<UploadOutlined />}>
                            {t('upload a new file')}
                          </Button>
                        </Upload>
                      </div>
                    </div>
                  ))}
                </div>
              ))}
              <div className="item-test-case">
                <div className="text-m bold">{t('create new test case')}</div>
                {problem.settings.mode === ProblemMode.POINTS && <div>0</div>}
                {[TypeTestCase.INPUT, TypeTestCase.OUTPUT].map(type => (
                  <div className={'test-case-' + type}>
                    <div>
                      <div>{t('new')} {type} {t('file')}
                        <Button type="text" onClick={() => {
                          setModalData({
                            visible: true,
                            file: new Blob(['']),
                            text: '',
                            key,
                            index: -1,
                            typeFile: type,
                            textCaseId: '',
                            group: 0,
                            mode: problem.settings.mode,
                            bigFile: false
                          });
                        }
                        }>{t('new')}</Button>
                      </div>
                      <Upload
                        customRequest={async (...props) => {
                          if (hasBlob(props[0])) {
                            setModalData({
                              visible: true,
                              file: props[0].file,
                              text: props[0].file.size < ACCEPTED_MAXIMUM_SIZE_BLOB ? await props[0].file.text() : '',
                              key,
                              index: -1,
                              typeFile: type,
                              textCaseId: '',
                              group: 0,
                              mode: problem.settings.mode,
                              bigFile: !(props[0].file.size < ACCEPTED_MAXIMUM_SIZE_BLOB)
                            });
                            // @ts-ignore
                            props[0].onSuccess?.({}, props[0].file);
                          }
                        }}
                      >
                        <Button icon={<UploadOutlined />}>
                          {t('upload a new file')}
                        </Button>
                      </Upload>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    />
  );
};
