import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';

import { account } from './account';
import { contests, contestsList } from './contest';
import { flags } from './flags';
import { problems, problemsList } from './problem';
import { ranking } from './ranking';
import { users } from './users';
import { profiles } from './profiles';

import { CLEAR_REDUX_DATA } from '../types';

export const history = createBrowserHistory();

const appReducer = combineReducers({
  router: connectRouter(history),
  account,
  contests,
  flags,
  contestsList,
  problems,
  problemsList,
  ranking,
  users,
  profiles
});

export type RootState = ReturnType<typeof appReducer>;

const rootReducer = (state: RootState | undefined, action: any) => {
  if (action.type === CLEAR_REDUX_DATA) { // to remove the redux status
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
