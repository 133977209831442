import { AccountStateFetch, SubmissionProfile } from '../../store/types/account'; // to bit.dev
import { API, API_COUCH, authorizedFormDataRequest, authorizedRequest, GET, POST, PUT } from '../server.config';
import { ErrorResponse, OkObjectResponse, OkPagedListResponse, SearchParams } from '../../commons/types';
import { clean } from './index';
import { searchBuilder } from '../../commons/helpers';

export const apiSignUpUser = (givenName: string, familyName: string, nickname: string, email: string, password: string) => async (): Promise<ErrorResponse | OkObjectResponse<AccountStateFetch>> => {
  return clean(await authorizedRequest(API.ACCOUNT.SIGNUP(), POST, JSON.stringify({
    givenName,
    familyName,
    nickname,
    email,
    password
  })));
};

export const apiPing = async (): Promise<ErrorResponse | OkObjectResponse<AccountStateFetch>> => {
  return clean(await authorizedRequest(API.ACCOUNT.PING()));
};

export const apiLoginUser = (nickname: string, password: string) => async (): Promise<ErrorResponse | OkObjectResponse<AccountStateFetch>> => {
  return clean(await authorizedRequest(API.ACCOUNT.SIGNIN(), POST, JSON.stringify({ nickname, password })));
};

export const apiGoogleLoginUser = (tokenId: string) => async (): Promise<ErrorResponse | OkObjectResponse<AccountStateFetch>> => {
  return clean(await authorizedRequest(API.ACCOUNT.GOOGLE_SIGNIN(), POST, JSON.stringify({ tokenId })));
};

export const apiRecoverPassword = (email: string) => async (): Promise<ErrorResponse | OkObjectResponse<{success: true, message: string}>> => {
  return clean(await authorizedRequest(API_COUCH.ACCOUNT.RESET_PASSWORD(), POST, JSON.stringify({ email })));
};

export const apiLogoutUser = async (): Promise<ErrorResponse | OkObjectResponse<undefined>> => {
  return clean(await authorizedRequest(API.ACCOUNT.LOGOUT(), POST));
};

export const apiUserUpdate = (body?: any) => async (): Promise<ErrorResponse | OkObjectResponse<AccountStateFetch>> => {
  return clean(await authorizedRequest(API.ACCOUNT.UPDATE(), PUT, JSON.stringify(body)));
};

const getUserMySubmissionsStack: Array<AbortController> = [];
export const apiUserMySubmissions = (params: SearchParams) => async (): Promise<ErrorResponse | OkPagedListResponse<SubmissionProfile>> => {
  while (getUserMySubmissionsStack.length) {
    getUserMySubmissionsStack.pop()?.abort();
  }
  const controller = new AbortController();
  const signal = controller.signal;
  getUserMySubmissionsStack.push(controller);
  return clean(await authorizedRequest(API.ACCOUNT.MY_STATUS() + searchBuilder(params), GET, '', signal));
};

export const apiUserUpdateImageUser = (blob: Blob) => async (): Promise<ErrorResponse | OkObjectResponse<AccountStateFetch>> => {
  const formData = new FormData();
  formData.append('file', blob);

  return clean(await authorizedFormDataRequest(API.ACCOUNT.UPDATE_IMAGE(), PUT, formData));
};

export const apiUserUpdatePasswordUser = (oldPassword: string, newPassword: string) => async (): Promise<ErrorResponse | OkObjectResponse<AccountStateFetch>> => {
  return clean(await authorizedRequest(API.ACCOUNT.CHANGE_PASSWORD(), PUT, JSON.stringify({
    oldPassword,
    newPassword
  })));
};
