import React, { useRef } from 'react';
import { Checkbox, Divider, Form, Input, Modal, Row } from 'antd';
import { Controller } from 'react-hook-form';
import GoogleLogin from 'react-google-login';
import { useTranslation } from 'react-i18next';

import { LoaderButton } from '../LoaderButton';
import { LoaderAction } from '../../types';
import { consoleWarn } from '../../helpers';
import { SignUpInput, SignUpModalProps } from './types';

import './styles.scss';

export const SignUpModal = ({ onCancel, onSubmit, imageSource, useFormSignUpInput, signUpWithGoogle, reactAppGoogleClientId }: SignUpModalProps) => {
  
  const { handleSubmit, control, errors, formState } = useFormSignUpInput;
  const refSetLoading = useRef<undefined | LoaderAction>();
  const { t } = useTranslation();
  const { isValid } = formState;
  
  return (
    <Modal visible footer={null} onCancel={onCancel} className="modal-sign-up" centered>
      <div className="layout-left child-center">
        <div className="content-main">
          <p className="text-xh bold">{t('sign up')}</p>
          <p className="text-h">{t('Happy you join us!')}</p>
          <img src={imageSource} alt={'hello!'} />
        </div>
      </div>
      <div className="layout-right child-center">
        <div className="content-main">
          {signUpWithGoogle && reactAppGoogleClientId && (
            <>
              <GoogleLogin
                clientId={reactAppGoogleClientId}
                onSuccess={signUpWithGoogle(refSetLoading.current)}
                onFailure={(err) => consoleWarn({ message: 'FAILURE GOOGLE LOGIN', err })}
                cookiePolicy="single_host_origin"
                buttonText={t('sign up with Google')}
              />
              <Divider>{t('or')}</Divider>
            </>
          )}
          <Form layout="vertical">
            <Row>
              <Form.Item className="half" label={t('first name')}>
                <Controller
                  control={control}
                  as={<Input className="short-box" />}
                  name="givenName"
                  defaultValue=""
                />
                <p className="validator-error">{t(errors.givenName && errors.givenName.message)}</p>
              </Form.Item>
              <Form.Item className="half" label={t('last name')}>
                <Controller
                  control={control}
                  as={<Input className="short-box margin-left-box" />}
                  name="familyName"
                  defaultValue=""
                />
                <p className="validator-error">{t(errors.familyName && errors.familyName.message)}</p>
              </Form.Item>
            </Row>
            <Form.Item label={t('nickname')}>
              <Controller
                control={control}
                as={<Input className="normal-box" />}
                name="nickname"
                defaultValue=""
              />
              <p className="validator-error">{t(errors.nickname && errors.nickname.message)}</p>
            </Form.Item>
            <Form.Item label={t('e-mail address')}>
              <Controller
                control={control}
                as={<Input className="normal-box" />}
                name="email"
                defaultValue=""
              />
              <p className="validator-error">{t(errors.email && errors.email.message)}</p>
            </Form.Item>
            
            <Form.Item label={t('password')}>
              <div className="password-restriction text-s">{t('minimum 8 characters')}</div>
              <Controller
                control={control}
                as={<Input.Password className="normal-box" />}
                name="password"
                defaultValue=""
              />
              <p className="validator-error">{t(errors.password && errors.password.message)}</p>
            </Form.Item>
            <Form.Item label={t('confirm password')}>
              <div className="password-restriction text-s">{t('minimum 8 characters')}</div>
              <Controller
                control={control}
                as={<Input.Password className="normal-box" />}
                name="passwordConfirmation"
                defaultValue=""
              />
              <p className="validator-error">{t(errors.passwordConfirmation && errors.passwordConfirmation.message)}</p>
            </Form.Item>
            <Form.Item>
              <Controller
                control={control}
                name="checkbox"
                defaultValue={false}
                render={({ onChange, value }: any) => (
                  <Checkbox
                    className="checkbox-label text-xs"
                    onChange={e => onChange(e.target.checked)}
                    checked={value}
                  >
                    {t('I accept the Terms of Service and Privacy Policy')}
                  </Checkbox>
                )}
              />
              <p className="validator-error">{t(errors.checkbox && errors.checkbox.message)}</p>
            </Form.Item>
            <Form.Item className="sign-up-content">
              <LoaderButton
                type="primary"
                onClick={(setLoader) => {
                  refSetLoading.current = setLoader;
                  return handleSubmit((data: SignUpInput) => onSubmit(data, setLoader));
                }}
                disabled={!isValid}
              >
                {t('sign up')}
              </LoaderButton>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Modal>
  );
};

export * from './types';
