import React, { useState } from 'react';
import ResizeObserver from 'rc-resize-observer';
import { TableProps as RcTableProps } from 'rc-table/lib/Table';
import { ColumnsType } from 'antd/lib/table/interface';
import { Table } from 'antd';

import { LoaderIcon } from '~/components';
import { classNames } from '~/helpers';

import './styles.scss';

export const ResizeTable = <RecordType extends object = any>({ className, columns, dataSource, loading }: {
  className: string,
  dataSource?: RcTableProps<RecordType>['data'],
  columns?: ColumnsType<RecordType>,
  loading?: boolean
}) => {
  const [size, setSize] = useState({ width: 0, height: 0 });

  return (
    <ResizeObserver onResize={({ width, height }) => setSize({ width, height })}>
      <div className={classNames('juki-table-layout', { [className]: !!className })}>
        <Table
          scroll={{ y: size.height - 48 }}
          pagination={false}
          columns={columns}
          dataSource={dataSource}
          loading={{ indicator: <LoaderIcon />, spinning: !!loading }}
        />
      </div>
    </ResizeObserver>
  );
}
