import React, { Dispatch } from 'react';
import { Select } from 'antd';
import { Redirect } from 'react-router';
import { useTranslation } from 'react-i18next';

import { LoaderAction, ProfileSettingOptions, ProgrammingLanguage, Source, Theme } from '~/types';
import { useAccountState } from '~/hooks';
import { LoaderButton, MonacoEditor } from '~/components';
import { PROGRAMMING_LANGUAGES } from '~/config/constants';

import './styles.scss';

const { Option } = Select;

export const CodeEditor = ({ source, setSource, onSubmit, languages, redirect }:
  { source: Source, setSource: Dispatch<React.SetStateAction<Source>>, onSubmit: (setLoader: LoaderAction) => void, languages: Array<ProgrammingLanguage>, redirect: string }) => {
  
  const { mySettings } = useAccountState();
  const { t } = useTranslation();
  
  const languageOptions: Array<JSX.Element> = [];
  languages.sort((lanA, lanB) => lanA.localeCompare(lanB)).forEach(lan => {
    if (PROGRAMMING_LANGUAGES[lan]) {
      languageOptions.push(<Option value={lan}>{PROGRAMMING_LANGUAGES[lan].name}</Option>);
    }
  });
  if (redirect) {
    return <Redirect to={redirect} />;
  }
  return (
    <div className="layout-code-editor">
      <div className="content-language-selector">
        {languageOptions.length ? (
          <Select
            onChange={(lang: ProgrammingLanguage) => setSource(prevState => ({ ...prevState, language: lang }))}
            value={source.language}
            style={{ width: 120 }}
          >
            {languageOptions}
          </Select>
        ) : null}
      </div>
      <div className="content-monaco-editor">
        <MonacoEditor
          language={PROGRAMMING_LANGUAGES[source.language].monaco}
          source={source.plainText}
          dark={mySettings[ProfileSettingOptions.THEME] === Theme.DARK}
          onChange={(newValue: string) => setSource(prevState => ({ ...prevState, plainText: newValue }))}
          height="100%"
        />
      </div>
      <div className="content-actions">
        {/*<Button disabled={!source.plainText}> RUN CODE </Button>*/}
        <LoaderButton type="primary" disabled={!source.plainText} onClick={(setLoader) => () => onSubmit(setLoader)}>
          {t('submit')}
        </LoaderButton>
      </div>
    </div>
  );
};
