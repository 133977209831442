export type PagedArray<T> = {
  list: Array<T>,
  pageNumber: number,
  pageSize: number,
  totalPages: number,
  totalElements: number
}

export enum Language {
  SPANISH = 'es',
  ENGLISH = 'en'
}

export enum Theme {
  DARK = 'dark',
  LIGHT = 'light'
}

export enum ProgrammingLanguage {
  CPP = 'CPP',
  CPP14 = 'CPP14',
  CPP17 = 'CPP17',
  JAVA = 'JAVA',
  PYTHON = 'PYTHON',
  PYTHON3 = 'PYTHON3',
  JAVASCRIPT = 'JAVASCRIPT',
  JSON = 'JSON',
  TEXT = 'TEXT',
  MARKDOWN = 'MARKDOWN',
  ARDUINO = 'ARDUINO'
}

export enum NotificationType {
  SUCCESS = 'SUCCESS',
  INFO = 'INFO',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
}

export enum ScopeData {
  USER = 'USER',
  PROBLEM = 'PROBLEM',
  CONTEST = 'CONTEST',
  ATTEMPT = 'ATTEMPT'
}

export enum Status {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  LOADING = 'LOADING',
  NONE = 'NONE'
}
