import React from 'react';

export const Test = () => {
  const fun = async () => {
    const requestHeaders = new Headers();
    requestHeaders.set('Accept', 'application/json');
    requestHeaders.set('Content-Type', 'application/json');
    const url = 'https://backend-java-online-judge.herokuapp.com/api/auth/signup';
    for (let i = 10; i < 100; i++) {
      const body = {
        'givenName': 'user_test_' + i,
        'familyName': 'user_test_' + i,
        'nickname': 'user_test_' + i,
        'email': `user_test_${i}@user_test_${i}.com`,
        'password': 'user_test_' + i
      };
      await fetch(url, {
        method: 'POST',
        headers: requestHeaders,
        credentials: 'include',
        body: JSON.stringify(body)
      });
      const registerUrl = 'https://backend-java-online-judge.herokuapp.com/api/contest/jj-contests/register';
      await fetch(registerUrl, {
        method: 'POST',
        headers: requestHeaders,
        credentials: 'include'
      });
      const logoutUrl = 'https://backend-java-online-judge.herokuapp.com/api/auth/logout';
      await fetch(logoutUrl, {
        method: 'POST',
        headers: requestHeaders,
        credentials: 'include'
      });
    }
  };
  return (
    <button onClick={fun}>
      Click
    </button>
  );
};
