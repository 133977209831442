import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router';

import { NONE, ROUTES, SEC_30 } from '~/config/constants';
import { useContestDispatch, useContestState, useInterval } from '~/hooks';
import { ContestParamsType, ContestTab, LoaderState, ProblemTab } from '~/types';
import { SubmissionsTable } from '../Status/SubmissionTable';
import { Pagination } from '~/components';
import { DataIndexSubmission, RowDataPendingSubmission } from '../Status/SubmissionTable/types';
import { Redirect } from 'react-router-dom';

export const ContestJudge = () => {
  
  const { key } = useParams<ContestParamsType>();
  const { pendingSubmissions, problems, settings, timing, canRejudge, canUpdate } = useContestState(key);
  const { loadContestPendingStatus } = useContestDispatch();
  const [loading, setLoading] = useState<LoaderState>([new Date().getTime(), NONE]);
  const { search } = useLocation();
  const firstCallRef = useRef(0);
  useEffect(() => {
    if (settings.start < new Date().getTime()) {
      loadContestPendingStatus(key, search, !!firstCallRef.current, setLoading)();
      firstCallRef.current++;
    }
  }, [key, loadContestPendingStatus, search, settings.start]);
  useInterval(() => {
    loadContestPendingStatus(key, search, false, setLoading)();
    // ( started ) && ( live)
  }, settings.start < new Date().getTime() && new Date().getTime() - (3 * SEC_30) <= settings.start + timing.duration ? SEC_30 : 0);
  
  if (!canRejudge) {
    return <Redirect to={ROUTES.CONTESTS.VIEW(key, ContestTab.OVERVIEW)} />;
  }
  
  const data = pendingSubmissions.list.map((submission): RowDataPendingSubmission => ({
    [DataIndexSubmission.KEY]: submission.submitId,
    [DataIndexSubmission.WHEN]: submission.when,
    [DataIndexSubmission.TIME_USED]: submission.timeUsed,
    [DataIndexSubmission.MEMORY_USED]: submission.memoryUsed,
    [DataIndexSubmission.VERDICT]: { verdict: submission.answer, points: submission.submitPoints },
    [DataIndexSubmission.LANGUAGE]: submission.language,
    [DataIndexSubmission.PROBLEM]: {
      link: ROUTES.CONTESTS.VIEW(key, ContestTab.PROBLEM, problems[submission?.indexProblem]?.index, ProblemTab.STATEMENT),
      index: problems[submission?.indexProblem]?.index || '',
      name: problems[submission?.indexProblem]?.name
    },
    [DataIndexSubmission.JUDGE]: { submission, canUpdate }
  }));
  
  const head = {
    [DataIndexSubmission.WHEN]: true,
    [DataIndexSubmission.TIME_USED]: true,
    [DataIndexSubmission.MEMORY_USED]: true,
    [DataIndexSubmission.VERDICT]: true,
    [DataIndexSubmission.LANGUAGE]: true,
    [DataIndexSubmission.PROBLEM]: true,
    [DataIndexSubmission.JUDGE]: true
  };
  
  return (
    <>
      <div className="content-main-contest-status">
        <SubmissionsTable data={data} loading={loading} contestKey={key} head={head} />
      </div>
      {!!pendingSubmissions.totalElements && <Pagination totalElements={pendingSubmissions.totalElements} />}
    </>
  );
};
