import React, { useState } from 'react';

import { useInterval } from '../../../hooks';

import './LineLoader.scss';

export const LineLoader = () => {
  const [pos, setPos] = useState(true);
  useInterval(() => {
    setPos(prevState => !prevState);
  }, 3000);
  return (
    <div className="layout-line-spinner">
      <div className={pos ? ' loader-point-left-to-right' : ' loader-point-right-to-left'} />
    </div>
  );
}
