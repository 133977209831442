import { ProblemInput, ProblemMode, ProblemOutput, ProblemStatus } from '../types';

export const PROBLEM_STATUS: { [key in ProblemStatus]: { value: ProblemStatus, print: string } } = {
  [ProblemStatus.PUBLIC]: { value: ProblemStatus.PUBLIC, print: 'public' },
  [ProblemStatus.PRIVATE]: { value: ProblemStatus.PRIVATE, print: 'private' },
  [ProblemStatus.ARCHIVED]: { value: ProblemStatus.ARCHIVED, print: 'archived' }
};

export const PROBLEM_INPUT: { [key in ProblemInput]: { value: ProblemInput, print: string } } = {
  [ProblemInput.STANDARD]: { value: ProblemInput.STANDARD, print: 'standard' },
  [ProblemInput.INTERACTIVE]: { value: ProblemInput.INTERACTIVE, print: 'interactive' }
};

export const PROBLEM_OUTPUT: { [key in ProblemOutput]: { value: ProblemOutput, print: string } } = {
  [ProblemOutput.STANDARD]: { value: ProblemOutput.STANDARD, print: 'standard' },
  [ProblemOutput.DYNAMIC]: { value: ProblemOutput.DYNAMIC, print: 'dynamic' },
  [ProblemOutput.INTERACTIVE]: { value: ProblemOutput.INTERACTIVE, print: 'interactive' }
};

export const PROBLEM_MODES = [ProblemMode.TOTAL, ProblemMode.POINTS];

export const PROBLEM_MODE: { [key in ProblemMode]: { value: ProblemMode, print: string } } = {
  [ProblemMode.TOTAL]: { value: ProblemMode.TOTAL, print: 'Total' },
  [ProblemMode.POINTS]: { value: ProblemMode.POINTS, print: 'Point' }
};
