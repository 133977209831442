import { AccountStatus, LoaderAction } from '~/types';
import { SuccessNotification } from '~/components';
import {
  actionLoaderWrapper,
  apiUserChangePermissions,
  apiUserChangeStatus,
  apiUsersList
} from '~/services';
import { UserActions, UserState } from '../types';
import { objectOfSearch } from '~/helpers';
import { PAGE, PAGE_SIZE, PAGE_SIZES } from '~/config/constants';

const replaceUsers = (users: Array<UserState>) => {
  return {
    type: UserActions.REPLACE_USERS,
    users
  };
};

export const loadAllUsers = (search: string, setLoader: LoaderAction) => {
  return async (dispatch: Function) => {
    const params = objectOfSearch(search);
    if (!params[PAGE]?.length) {
      params[PAGE] = [1];
    }
    if (!params[PAGE_SIZE]?.length) {
      params[PAGE_SIZE] = [PAGE_SIZES[0]];
    }
    await actionLoaderWrapper(
      apiUsersList(params),
      dispatch,
      (result) => {
        dispatch(replaceUsers(result.list));
      },
      setLoader
    );
  };
};

export const changeStatusUser = (nickname: string, status: AccountStatus, setLoader: LoaderAction) => {
  return async (dispatch: Function) => {
    await actionLoaderWrapper(
      apiUserChangeStatus(nickname, status),
      dispatch,
      (result) => {
        SuccessNotification({ description: 'User archived, loading...' });
        dispatch(loadAllUsers('', setLoader));
      },
      setLoader
    );
  };
};

export const changePermissionsUser = (nickname: string, permissions: Array<{ key: string, value: string }>, setLoader: LoaderAction) => {
  return async (dispatch: Function) => {
    await actionLoaderWrapper(
      apiUserChangePermissions(nickname, permissions),
      dispatch,
      (result) => {
        SuccessNotification({ description: 'User changed, loading...' });
        dispatch(loadAllUsers('', setLoader));
      },
      setLoader
    );
  };
};
