import * as sha512 from 'js-sha512';

const apiKey = process.env.REACT_APP_CODEFORCES_API_KEY;
const secretKey = process.env.REACT_APP_CODEFORCES_SECRET_API_KEY;

export const codeforcesRequest = async (url: string) => {
  return await fetch(url, {})
    .then((response: any) => response.json())
    .then((myJson: any) => {
      if (myJson.status === 'OK') {
        return myJson.result;
      }
      console.error(`Error Request to ${url}:`, myJson.comment);
      return null;
    })
    .catch((error) => {
      console.error('Error:', error);
      return null;
    });
};

export const codeforcesProblemsList = (tags: Array<string> = []): string => {
  const time = Math.round(new Date().getTime() / 1000);
  const hash = sha512.sha512(`123456/problemset.problems?apiKey=${apiKey}&tags=${tags.join('.')}&time=${time}#${secretKey}`);
  return `https://codeforces.com/api/problemset.problems?apiKey=${apiKey}&tags=${tags.join('.')}&time=${time}&apiSig=123456${hash}`;
};

export const codeforcesProblems = async (tags: Array<string>) => {
  return await codeforcesRequest(codeforcesProblemsList(tags));
};
