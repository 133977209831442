import React, { FC } from 'react';
import { Divider, Input, Select, Switch } from 'antd';

import { DatePicker, FieldEditable } from '~/components';
import { isSameDay, isSameHour, isToday, range, startOfDay } from '~/helpers';
import { ContestSettingsParams, ProgrammingLanguage } from '~/types';
import { ACCEPTED_PROGRAMMING_LANGUAGES, PROGRAMMING_LANGUAGES } from '~/config/constants';
import { ContestCreateSetupProps } from './types';
import { useTranslation } from 'react-i18next';

const { TextArea } = Input;
const { Option } = Select;

export const ContestSetup: FC<ContestCreateSetupProps> = ({ contestNew, setContestNew, newContest }) => {
  
  const { t } = useTranslation();
  
  return (
    <>
      <div className="content-start-date">
        {t('start date')}:
        <DatePicker
          value={new Date(contestNew.settings.start)}
          disabledDate={(current) => current && current < startOfDay(new Date())}
          disabledTime={(current) => ({
            disabledHours: () => !current ? range(0, 24) : isToday(current) ? range(0, (new Date()).getHours()) : [],
            disabledMinutes: () => !current ? range(0, 59) : isSameHour(current, new Date()) ? range(0, new Date().getMinutes()) : [],
            disabledSeconds: () => []
          })}
          onOk={(current) => {
            const endDate = new Date(contestNew.settings.start + contestNew.timing.duration);
            if (current >= endDate) {
              setContestNew(prevState => ({
                ...prevState,
                settings: { ...prevState.settings, start: current.getTime() }
              }));
            } else {
              setContestNew(prevState => ({
                ...prevState,
                settings: { ...prevState.settings, start: current.getTime() },
                timing: {
                  ...prevState.timing,
                  duration: endDate.getTime() - current.getTime(),
                  toSolve: endDate.getTime() - current.getTime()
                }
              }));
            }
          }}
          showTime
          format="DD MMMM YYYY, HH:mm"
          bordered={false}
          showToday={false}
          showNow={false}
          className="juki-field-editable"
        />
      </div>
      <div className="content-end-date">
        {t('end date')}:
        <DatePicker
          value={new Date(contestNew.settings.start + contestNew.timing.duration)}
          disabledDate={(current) => current && current < startOfDay(new Date(contestNew.settings.start))}
          disabledTime={(current) => ({
            disabledHours: () => !current ? range(0, 24) : isSameDay(current, new Date(contestNew.settings.start)) ? range(0, new Date(contestNew.settings.start).getHours()) : [],
            disabledMinutes: () => !current ? range(0, 59) : isSameHour(current, new Date(contestNew.settings.start)) ? range(0, new Date(contestNew.settings.start).getMinutes()) : [],
            disabledSeconds: () => []
          })}
          onOk={(current) => {
            let duration = current.getTime() - contestNew.settings.start;
            if (duration <= 0) {
              duration = 5 * 60 * 60 * 1000;
            }
            setContestNew(prevState => ({
              ...prevState,
              timing: {
                ...prevState.timing,
                duration,
                toSolve: duration
              }
            }));
          }}
          showTime
          format="DD MMMM YYYY, HH:mm"
          bordered={false}
          showToday={false}
          showNow={false}
          className="juki-field-editable"
        />
      </div>
      <div className="content-lasts">
        {t('duration')}
        <FieldEditable<number>
          value={contestNew.timing.duration / 1000 / 60}
          onChange={(value) => setContestNew(prevState => ({
            ...prevState,
            timing: { ...prevState.timing, duration: value * 1000 * 60, toSolve: value * 1000 * 60 }
          }))}
        />
        {t('minute')}{(contestNew.timing.duration > 60 * 1000) && 's'}
      </div>
      <div className="content-penalty">
        {t('penalty by incorrect answer')}
        <FieldEditable<number>
          value={contestNew.timing.penalty / 1000 / 60}
          onChange={(value) => setContestNew(prevState => ({
            ...prevState,
            timing: { ...prevState.timing, penalty: value * 1000 * 60 }
          }))}
        />
        {t('minute')}{(contestNew.timing.penalty > 60 * 1000) && 's'}
      </div>
      <div className="content-frozen">
        {t('scoreboard frozen after of')}
        <FieldEditable<number>
          value={contestNew.timing.frozen / 1000 / 60}
          onChange={(value) => setContestNew(prevState => ({
            ...prevState,
            timing: { ...prevState.timing, frozen: Math.min(value * 1000 * 60, prevState.timing.duration) }
          }))}
        />
        {t('minute')}{(contestNew.timing.frozen > 60 * 1000) && 's'}
      </div>
      <div className="content-un-judged">
        {t('submissions will not be responded to after of')}
        <FieldEditable<number>
          value={contestNew.timing.unJudged / 1000 / 60}
          onChange={(value) => setContestNew(prevState => ({
            ...prevState,
            timing: { ...prevState.timing, unJudged: Math.min(value * 1000 * 60, prevState.timing.duration) }
          }))}
        />
        {t('minute')}{(contestNew.timing.unJudged > 60 * 1000) && 's'}
      </div>
      <div className="content-to-solve">
        {t('time for solve')}
        <FieldEditable<number>
          value={contestNew.timing.toSolve / 1000 / 60}
          onChange={(value) => {
            setContestNew(prevState => ({
              ...prevState,
              timing: {
                ...prevState.timing,
                toSolve: Math.max(1, Math.min(value * 1000 * 60, prevState.timing.duration))
              }
            }));
          }}
        />
        {t('minute')}{(contestNew.timing.toSolve > 60 * 1000) && 's'}
      </div>
      <div className="content-languages">
        {t('enabled languages')}:
        <Select
          mode="multiple"
          onChange={(languages: Array<ProgrammingLanguage>) => setContestNew(prevState => ({
            ...prevState,
            settings: { ...prevState.settings, languages }
          }))}
          value={contestNew.settings[ContestSettingsParams.LANGUAGES]}
          style={{ width: '200px' }}
        >
          {ACCEPTED_PROGRAMMING_LANGUAGES.map(language => (
            <Option value={language} key={language}> {PROGRAMMING_LANGUAGES[language].name} </Option>
          ))}
        </Select>
      </div>
      <Divider />
      <div className="content-clarifications">
        <div>
          <div className="text-m semi-bold">
            {t('clarifications')}
            <span className="bold"> {!contestNew.settings[ContestSettingsParams.CLARIFICATIONS] && t('not')} </span>
            {t('available')}
          </div>
          <Switch
            checked={contestNew.settings[ContestSettingsParams.CLARIFICATIONS]}
            onChange={(value) => setContestNew(prevState => ({
              ...prevState,
              settings: { ...prevState.settings, clarifications: value }
            }))}
          />
        </div>
      </div>
      <Divider />
      <div className="content-registration">
        <div>
          <div className="text-m semi-bold">
            {contestNew.settings[ContestSettingsParams.OPEN_REGISTRATION] ? (
              <><span className="text-m bold color-primary">{t('open')}</span> {t('enrollment')}</>
            ) : (
              <><span className="text-m bold color-primary">{t('close')}</span> {t('enrollment')}</>
            )}
          </div>
          <Switch
            checked={contestNew.settings[ContestSettingsParams.OPEN_REGISTRATION]}
            onChange={(value) => setContestNew(prevState => ({
              ...prevState,
              settings: { ...prevState.settings, openRegistration: value }
            }))}
          />
        </div>
        <div>
          {contestNew.settings[ContestSettingsParams.OPEN_REGISTRATION] ?
            t('anyone can register for this contest') : (
              <>
                {t('only the following users can register for the contest')}: <br />
                <span className="text-s semi-bold">{t('write the nicknames separated by commas')}</span>
                <TextArea
                  autoSize={{ minRows: 2, maxRows: 5 }}
                  value={contestNew.members.guests.join(',')}
                  onChange={({ target }) => setContestNew(prevState => ({
                    ...prevState,
                    members: { ...contestNew.members, guests: target.value.split(',') }
                  }))}
                />
              </>
            )}
        </div>
      </div>
      <Divider />
      <div className="content-scoreboard">
        <div>
          <div className="text-m semi-bold">
            {contestNew.settings[ContestSettingsParams.OPEN_SCOREBOARD] ? (
              <><span className="text-m bold color-primary">{t('open')}</span> {t('scoreboard')}</>
            ) : (
              <><span className="text-m bold color-primary">{t('close')}</span> {t('scoreboard')}</>
            )}
          </div>
          <Switch
            checked={contestNew.settings[ContestSettingsParams.OPEN_SCOREBOARD]}
            onChange={(value) => setContestNew(prevState => ({
              ...prevState,
              settings: { ...prevState.settings, openScoreboard: value }
            }))}
          />
        </div>
        <div>
          {contestNew.settings[ContestSettingsParams.OPEN_SCOREBOARD] ?
            t('anyone can view the scoreboard of the contest') : (
              <>
                {t('only the following users can view the scoreboard of the contest')}: <br />
                <span className="text-s semi-bold">{t('write the nicknames separated by commas')}</span>
                <TextArea
                  autoSize={{ minRows: 2, maxRows: 5 }}
                  value={contestNew.members.spectators}
                  onChange={({ target }) => setContestNew(prevState => ({
                    ...prevState,
                    members: { ...prevState.members, spectators: target.value.split(',') }
                  }))}
                />
              </>
            )}
        </div>
      </div>
      <Divider />
      <div className="content-type-judge">
        <div>
          <div className="text-m semi-bold">
            {!contestNew.settings[ContestSettingsParams.MANUAL_JUDGE] ? t('automatic judge') : (
              <>
                {t('manual judge')}
                <span className="normal">, {t('judges needed to judge a submission')} </span>
                <FieldEditable<number>
                  value={contestNew.settings[ContestSettingsParams.NUMBER_MANUAL_JUDGES]}
                  onChange={(value) => {
                    setContestNew(prevState => ({
                      ...prevState,
                      settings: {
                        ...prevState.settings,
                        [ContestSettingsParams.NUMBER_MANUAL_JUDGES]: Math.max(0, Math.min(9, value))
                      }
                    }));
                  }}
                />
              </>
            )}
          </div>
          {newContest && (
            <Switch
              checked={contestNew.settings[ContestSettingsParams.MANUAL_JUDGE]}
              onChange={(value) => setContestNew(prevState => ({
                ...prevState,
                settings: { ...prevState.settings, [ContestSettingsParams.MANUAL_JUDGE]: value }
              }))}
            />
          )}
        </div>
      </div>
      <Divider />
      <h6>{t('judges')}</h6>
      <div className="content-judges">
        {t('for the contest, only the following users will be able to answer the clarifications, judge the pending submissions (if contest is configured) or rejudge submissions')}: <br />
        <span className="text-s semi-bold"> {t('write the nicknames separated by commas')} </span>
        <TextArea
          autoSize={{ minRows: 2, maxRows: 5 }}
          value={contestNew.members.judges}
          onChange={({ target }) => setContestNew(prevState => ({
            ...prevState,
            members: { ...prevState.members, judges: target.value.split(',') }
          }))}
        />
      </div>
      <h6>{t('administrators')}</h6>
      <div className="content-admins">
        {t('only the following users will be able to edit the contest also will have permissions as a judge')}: <br />
        <span className="text-s semi-bold"> {t('write the nicknames separated by commas')} </span>
        <TextArea
          autoSize={{ minRows: 2, maxRows: 5 }}
          value={contestNew.members.admins}
          onChange={({ target }) => setContestNew(prevState => ({
            ...prevState,
            members: { ...prevState.members, admins: target.value.split(',') }
          }))}
        />
      </div>
    </>
  );
};
