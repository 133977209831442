import { Judge, ProblemVerdict, ProgrammingLanguage } from '../types';

export const JUDGE = {
  [Judge.JUKI_JUDGE]: {
    value: Judge.JUKI_JUDGE,
    print: 'Juki Judge',
    logo: 'https://i.ibb.co/gvC4twc/juki.png',
    url: 'https://jukijudge.com'
  },
  [Judge.CODEFORCES]: {
    value: Judge.CODEFORCES,
    print: 'Codeforces',
    logo: 'https://vjudge.net/static/images/remote_oj/CodeForces_favicon.png',
    url: 'https://codeforces.com'
    
  },
  [Judge.UVA_ONLINE_JUDGE]: {
    value: Judge.UVA_ONLINE_JUDGE,
    print: 'Uva Online Judge',
    logo: 'https://vjudge.net/static/images/remote_oj/UVA_favicon.ico',
    url: 'https://onlinejudge.org'
  },
  [Judge.AT_CODER]: {
    value: Judge.AT_CODER,
    print: 'AtCoder',
    logo: 'https://bira37.github.io/a93aa273c58de43a48942a1f6b14418d.svg',
    url: 'https://atcoder.jp'
  },
  [Judge.CODECHEF]: {
    value: Judge.CODECHEF,
    print: 'Codechef',
    logo: 'https://bira37.github.io/7559bdeef5edfc8d20cd19a499105d71.svg',
    url: 'https://www.codechef.com'
  }
};

export const ACCEPTED_PROGRAMMING_LANGUAGES = [
  ProgrammingLanguage.CPP,
  ProgrammingLanguage.CPP14,
  ProgrammingLanguage.CPP17,
  ProgrammingLanguage.JAVA,
  ProgrammingLanguage.JAVASCRIPT,
  ProgrammingLanguage.PYTHON,
  ProgrammingLanguage.PYTHON3,
  ProgrammingLanguage.ARDUINO
];

export const VERDICT: { [KEY in ProblemVerdict]: { value: ProblemVerdict, print: string, color: string } } = {
  [ProblemVerdict.AC]: { value: ProblemVerdict.AC, print: 'accepted', color: '#43D787' },
  [ProblemVerdict.PA]: { value: ProblemVerdict.PA, print: 'partial accepted', color: '#4EFA9D' },
  [ProblemVerdict.CE]: { value: ProblemVerdict.CE, print: 'compilation error', color: '#FE9F9F' },
  [ProblemVerdict.RTE]: { value: ProblemVerdict.RTE, print: 'run time error', color: '#FE9F9F' },
  [ProblemVerdict.TLE]: { value: ProblemVerdict.TLE, print: 'time limit exceed', color: '#FE9F9F' },
  [ProblemVerdict.MLE]: { value: ProblemVerdict.MLE, print: 'memory limit exceed', color: '#FE9F9F' },
  [ProblemVerdict.WA]: { value: ProblemVerdict.WA, print: 'wrong answer', color: '#FE9F9F' },
  [ProblemVerdict.PENDING]: { value: ProblemVerdict.PENDING, print: 'pending', color: '#79B6FD' }
};

export const PROBLEM_VERDICTS = [
  ProblemVerdict.AC,
  ProblemVerdict.PA,
  ProblemVerdict.CE,
  ProblemVerdict.RTE,
  ProblemVerdict.TLE,
  ProblemVerdict.MLE,
  ProblemVerdict.WA,
  ProblemVerdict.PENDING
];
