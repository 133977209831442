import React, { useEffect } from 'react';
import { BugFilled } from '@ant-design/icons';

import { LoaderIcon } from '../Loader';

import './styles.scss';

export const NotFound = ({ redirectAction }: { redirectAction?: Function }) => {
  
  useEffect(() => {
    if (redirectAction) {
      redirectAction();
    }
  }, [redirectAction]);
  
  return (
    <div className="not-found child-center">
      <BugFilled />
      <h3>NOT FOUND</h3>
      <div className="text-xh bold">Redirecting...</div>
      <LoaderIcon />
    </div>
  );
};
