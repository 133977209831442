import { ContestProblem } from './problem';
import {
  AnswerClarification,
  ContestTimeStatus,
  PagedArray,
  ProgrammingLanguage,
  ProblemVerdict, ContestSettingsParams, ContestStatus
} from '~/types';

export type ContestScoreboardContestant = {
  nickname: string,
  familyName: string,
  givenName: string,
  imageUrl: string
  totalPoints: number,
  totalPenalty: number,
  problems: { [key: string]: { attempts: number, time: number, points: number, success: boolean } }
}

export type ContestScoreboard = {
  contestants: Array<ContestScoreboardContestant>,
  meta: { timeUpdated: number }
}

export interface ContestNewState {
  name: string,
  description: string,
  key: string,
  settings: {
    [ContestSettingsParams.START]: number,
    [ContestSettingsParams.CLARIFICATIONS]: boolean,
    [ContestSettingsParams.OPEN_REGISTRATION]: boolean,
    [ContestSettingsParams.OPEN_SCOREBOARD]: boolean,
    [ContestSettingsParams.LIMIT_PROBLEM_TIME]: boolean,
    [ContestSettingsParams.LANGUAGES]: Array<ProgrammingLanguage>,
    [ContestSettingsParams.FROZEN]: boolean,
    [ContestSettingsParams.MANUAL_JUDGE]: boolean,
    [ContestSettingsParams.NUMBER_MANUAL_JUDGES]: number,
  },
  timing: {
    duration: number, // milliseconds
    penalty: number, // milliseconds
    frozen: number, // milliseconds
    unJudged: number, // milliseconds
    toSolve: number, // milliseconds
  },
  problems: { [key: string]: ContestProblem },
  members: {
    admins: Array<string>,
    guests: Array<string>,
    spectators: Array<string>,
    contestants: Array<string>,
    judges: Array<string>,
  },
  tags: Array<string>,
}

export interface Submission {
  answer: ProblemVerdict,
  submitId: string,
  when: number,
  timeUsed: number,
  memoryUsed: number,
  language: ProgrammingLanguage,
  submitPoints: number,
}

export interface ContestMySubmission extends Submission {
  indexProblem: string,
}

export interface ContestSubmission extends ContestMySubmission {
  imageUrl: string,
  nickname: string,
}

export interface ContestPendingSubmission extends Submission {
  indexProblem: string,
  manualJudged: boolean
}

export interface ContestState extends ContestNewState {
  status: ContestStatus,
  clarifications: Array<AnswerClarification>,
  scoreboard: ContestScoreboard,
  ownerNickname: string,
  totalRegistered: number,
  registered: boolean,
  canUpdate: boolean,
  canViewProblems: boolean,
  canViewScoreBoard: boolean,
  canRegister: boolean,
  canRejudge: boolean,
  submissions: PagedArray<ContestSubmission>,
  mySubmissions: PagedArray<ContestMySubmission>,
  pendingSubmissions: PagedArray<ContestPendingSubmission>,
}

export type ContestsState = { [key: string]: ContestState };

export enum ContestsActions {
  UPDATE_CONTESTS = 'UPDATE_CONTESTS'
}

interface UpdateContestsType {
  type: ContestsActions.UPDATE_CONTESTS,
  contests: Array<ContestState>,
}

export type ContestActionTypes = UpdateContestsType;

export enum ContestsListActions {
  REPLACE_CONTESTS_LIST = 'REPLACE_CONTESTS_LIST'
}

interface ReplaceContestsListType {
  type: ContestsListActions.REPLACE_CONTESTS_LIST,
  contests: Array<string>,
  timeStatus: ContestTimeStatus
}

export type ContestsListActionTypes = ReplaceContestsListType;
