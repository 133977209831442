import React, { useState } from 'react';
import { SketchPicker } from 'react-color';
import { Button, Popover } from 'antd';

import { PALLETE } from '../../../config/constants';

import './ColorPicker.scss';

export const ColorPicker = ({ color, children, onChange }: { color?: string, children: JSX.Element, onChange: Function }) => {
  const [show, setShow] = useState(false);
  const [newColor, setNewColor] = useState<string>(color ? color : '#FFFFFF');
  const handlePick = () => {
    onChange(newColor);
    setShow(false);
  };
  return (
    <>
      <Popover
        visible={show}
        onVisibleChange={(visible) => setShow(prevState => (visible || prevState))}
        content={(
          <>
            <SketchPicker
              color={newColor}
              onChangeComplete={(colorResult) => setNewColor(colorResult.hex)}
              presetColors={[...PALLETE.CLAROS, ...PALLETE.VIVOS, ...PALLETE.AGRISADOS, ...PALLETE.OSCUROS]}
            />
            <Button
              onClick={handlePick}
              style={{ color: 'white', backgroundColor: newColor, borderColor: newColor }}
            >
              Pick
            </Button>
          </>
        )}
        trigger="click"
      >
        {children}
      </Popover>
    </>
  );
}
