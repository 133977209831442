import { ErrorCodes } from '../types';

export const ERROR_MESSAGES: { [key in ErrorCodes]: string } = {
  // General errors
  [ErrorCodes.ERR9900]: 'Internal Server Error',
  [ErrorCodes.ERR9901]: 'Unauthorized user',
  [ErrorCodes.ERR9902]: 'Invalid payload params',
  [ErrorCodes.ERR9903]: 'Invalid query params',
  [ErrorCodes.ERR9997]: 'Aborted response',
  [ErrorCodes.ERR9998]: 'Error on response',
  [ErrorCodes.ERR9999]: 'Service no found',
  // User errors
  [ErrorCodes.ERR0001]: 'Nickname not found',
  [ErrorCodes.ERR0002]: 'Incorrect password',
  [ErrorCodes.ERR0003]: 'User not active',
  [ErrorCodes.ERR0004]: 'Email is already in use',
  [ErrorCodes.ERR0005]: 'Nickname is already in use',
  [ErrorCodes.ERR0006]: 'Unable update nickname yet',
  [ErrorCodes.ERR0007]: 'Unauthorized to make this operation',
  [ErrorCodes.ERR0008]: 'User not found',
  [ErrorCodes.ERR0009]: 'Permission key not found',
  [ErrorCodes.ERR0010]: 'Invalid status',
  // View Errors
  [ErrorCodes.ERR0101]: 'View key is already in use',
  [ErrorCodes.ERR0102]: 'View not found',
  [ErrorCodes.ERR0103]: 'View not active'
};
