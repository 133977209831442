import React, { useState } from 'react';
import { Button, Form, Input, Modal } from 'antd';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { LoaderButton } from '~/components';
import { LoaderAction } from '~/types';
import { useProfileDispatch } from '~/hooks';
import { ProfileChangePasswordInput } from './types';

import './ChangePassword.scss';

const profileSettingsChangePasswordSchema = yup.object().shape({
  oldPassword: yup.string()
    .required('Required in order to update information'),
  newPassword: yup.string()
    .required('Required')
    .min(8, 'Password must be at least 8 characters')
    .matches(/^(?=.*\d)(?=.*[a-z])[\w~@#$%^&*+=`|{}:;!.?"()]{8,}$/,
      'Must have at least one uppercase, one lowercase letter and one number'),
  newPasswordConfirmation: yup.string()
    .required('Required')
    .oneOf([yup.ref('newPassword'), ''], 'Both passwords must match')
});

export const ChangePassword = () => {
  
  const { handleSubmit, control, errors, formState } = useForm<ProfileChangePasswordInput>({
    resolver: yupResolver(profileSettingsChangePasswordSchema),
    mode: 'onChange',
    reValidateMode: 'onChange'
  });
  const [modalChangePassword, setModalChangePassword] = useState(false);
  const { isValid } = formState;
  const { t } = useTranslation();
  const { updateUserPasswordProfile } = useProfileDispatch();
  
  const onSubmit = (data: ProfileChangePasswordInput, setLoader: LoaderAction) => {
    updateUserPasswordProfile(data.oldPassword, data.newPassword, setLoader)();
  };
  
  return (
    <>
      <Button onClick={() => setModalChangePassword(true)}>
        {t('change password')}
      </Button>
      <Modal
        visible={modalChangePassword}
        footer={null}
        className="modal-change-password"
        onCancel={() => setModalChangePassword(false)}
        centered
      >
        <Form layout="vertical">
          <Form.Item className="label" label={t('new password')}>
            <Controller
              control={control}
              as={<Input.Password className="input-border-large" />}
              name="newPassword"
              defaultValue=""
            />
            <p className="validator-error">{errors.newPassword?.message}</p>
          </Form.Item>
          <Form.Item className="label" label={t('confirm new password')}>
            <Controller
              control={control}
              as={<Input.Password className="input-border-large" />}
              name="newPasswordConfirmation"
              defaultValue=""
            />
            <p className="validator-error">  {errors.newPasswordConfirmation?.message}</p>
          </Form.Item>
          <Form.Item className="label" label={t('put your password to update')}>
            <Controller
              control={control}
              as={<Input.Password className="input-border-large" />}
              name="oldPassword"
              defaultValue=""
            />
            <p className="validator-error">{errors.oldPassword?.message}</p>
          </Form.Item>
          <Form.Item>
            <LoaderButton
              onClick={(setLoader) => handleSubmit((data) => onSubmit(data, setLoader))}
              type="primary"
              disabled={!isValid}
            >
              {t('update password')}
            </LoaderButton>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
