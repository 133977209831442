import { RakingActionTypes, RankingActions, RankingState } from '../types';

export const ranking = (state: RankingState = {}, action: RakingActionTypes) => {
  switch (action.type) {
    case RankingActions.REPLACE_RANKING:
      return { ...state, ...action.rankingList };
    default:
      return state;
  }
};
