import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Carousel, Col, Row } from 'antd';
import { UserOutlined } from '@ant-design/icons/lib';
import { useTranslation } from 'react-i18next';

import { ROUTES, LOADING, NONE, SEC_90 } from '~/config/constants';
import { ContestTab, ContestTimeStatus, LoaderState } from '~/types';
import { useAccountState, useContestDispatch, useContestsState, useInterval } from '~/hooks';
import { Card, JukiLayout, LoaderIcon, TimerClock, MdMathViewer } from '~/components';

import './styles.scss';

export const Home = () => {
  
  const [loading, setLoading] = useState<LoaderState>([new Date().getTime(), NONE]);
  const { loadAllContest } = useContestDispatch();
  const { nickname } = useAccountState();
  const contests = useContestsState();
  const { t } = useTranslation();
  useEffect(() => {
    loadAllContest(ContestTimeStatus.LIVE, setLoading)().then(() => {
      loadAllContest(ContestTimeStatus.UPCOMING, setLoading)().then(() => setLoading([new Date().getTime(), NONE]));
    });
  }, [loadAllContest, nickname]);
  useInterval(() => {
    loadAllContest(ContestTimeStatus.LIVE, setLoading)().then(() => {
      loadAllContest(ContestTimeStatus.UPCOMING, setLoading)().then(() => setLoading([new Date().getTime(), NONE]));
    });
  }, SEC_90);
  
  return (
    <>
      <JukiLayout type="title">
        <Row justify="space-between" align="middle" className="content">
          <Col lg={23} md={23} sm={35}>
            <h3>{t('competitive programmers home')}</h3>
            <p>
              <span className="text-m bold">Juki Judge </span>
              {t('is designed to make people improve their programming skills.')}
            </p>
          </Col>
          <Col lg={11} md={11} sm={0}>
            <img src="/images/juki-judge-person.svg" alt="judge" />
          </Col>
        </Row>
      </JukiLayout>
      <JukiLayout>
        <Row justify="space-between" align="top" className="content">
          <Col lg={23} md={23} sm={35}>
            <p className="title-content text-l bold">
              {t('news')}
            </p>
            <Carousel className="shadow">
              <div>
                <MdMathViewer source={`
![image alt](https://judge.juki.app/images/banner_div1_2020.jpg)
                `} />
              </div>
              <div>
                <MdMathViewer source={`
![image alt](https://judge.juki.app/images/banner_div2_2020.jpg)
                `} />
              </div>
              <div>
                <MdMathViewer source={`
### Campamento de programación 2020
![image alt](https://judge.juki.app/images/camp_icpc_2020.png)
                `} />
              </div>
            </Carousel>
          </Col>
          <Col lg={11} md={11} sm={35}>
            <div className="title-content">
              <p className="text-l">
                {t('__break1')}<span className="text-l bold">{t('live')}</span>&nbsp;{t('__break2')}
                {loading[1] !== LOADING && <LoaderIcon />}
              </p>
            </div>
            <div className="content-live-contests with-scroll">
              {(contests[ContestTimeStatus.LIVE].length || contests[ContestTimeStatus.UPCOMING].length) ?
                [...contests[ContestTimeStatus.LIVE], ...contests[ContestTimeStatus.UPCOMING]].map(contest => (
                  <Card
                    contentHead={
                      <div className="users-badge">
                        <UserOutlined /><span className="text-s bold">{contest.totalRegistered}</span>
                      </div>
                    }
                    contentTitle={<div className="text-l bold">{contest.name}</div>}
                    contentInfo={
                      <TimerClock
                        startDate={new Date(contest.settings.start)}
                        endDate={new Date(contest.settings.start + contest.timing.duration)}
                        currentDate={new Date()}
                      />
                    }
                    contentAction={
                      (contest.canRegister || contest.canUpdate || contest.registered) ? (
                        <Link to={ROUTES.CONTESTS.VIEW(contest.key, ContestTab.OVERVIEW)}>
                          <Button type="primary" className="sign-up-button color-secondary">
                            {t('enter')}
                          </Button>
                        </Link>
                      ) : (contest.canViewScoreBoard && (
                        <Link to={ROUTES.CONTESTS.VIEW(contest.key, ContestTab.SCOREBOARD)}>
                          <Button type="primary" className="sign-up-button color-secondary">
                            {t('view scoreboard')}
                          </Button>
                        </Link>
                      ))
                    }
                    key={contest.key}
                  />
                )) : <p className="text-l bold">{t('no contest running')}</p>}
            </div>
            <div className="content-more child-center">
              <Link to={ROUTES.CONTESTS.LIST_PAGE(ContestTimeStatus.LIVE)}>
                <Button type="ghost">{t('more')}</Button>
              </Link>
            </div>
          </Col>
        </Row>
      </JukiLayout>
    </>
  );
};
