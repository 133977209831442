import React, { useState } from 'react';
import { Link, Redirect, useParams } from 'react-router-dom';
import { CaretLeftOutlined, InfoCircleOutlined, SettingOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

import { TEMPLATE_PROBLEM_NEW } from '~/config/constants/defaultObjects';
import { ProblemNewState } from '~/store/types';
import { FieldEditable, JukiLayout, JukiSider, LoaderButton, MenuSideBar } from '../../shared';
import { PROBLEM_TAB, ROUTES } from '~/config/constants/routes';
import { useAccountState, useProblemsDispatch } from '../../../hooks';
import { ProblemCreateSetup } from './Setup';
import { ProblemCreateStatement } from './Statement';
import { ProblemTab } from '~/types';
import { can } from '~/helpers';

export const ProblemCreate = () => {
  
  const account = useAccountState();
  const { tab } = useParams<{ tab: ProblemTab }>();
  const { createNewProblem } = useProblemsDispatch();
  const [problemNew, setProblemNew] = useState<ProblemNewState>({
    ...TEMPLATE_PROBLEM_NEW
  });
  const { t } = useTranslation();
  
  if (!can.createProblem(account)) {
    return <Redirect to={'/'} />;
  }
  
  const menuSideBarItems = [{
    link: ROUTES.PROBLEMS.CREATE(ProblemTab.STATEMENT),
    selected: tab === ProblemTab.STATEMENT,
    icon: <InfoCircleOutlined />,
    label: PROBLEM_TAB[ProblemTab.STATEMENT].print
  }, {
    link: ROUTES.PROBLEMS.CREATE(ProblemTab.SETUP),
    selected: tab === ProblemTab.SETUP,
    icon: <SettingOutlined />,
    label: PROBLEM_TAB[ProblemTab.SETUP].print
  }, {
    link: ROUTES.PROBLEMS.CREATE(ProblemTab.TESTS),
    selected: tab === ProblemTab.TESTS,
    icon: <UnorderedListOutlined />,
    label: PROBLEM_TAB[ProblemTab.TESTS].print
  }];
  
  return (
    <JukiSider
      sideBar={(isClosed: boolean) => (
        <>
          <JukiLayout type="title">
            <Link to={ROUTES.PROBLEMS.LIST_PAGE()}>
              <Button icon={<CaretLeftOutlined />} type="text">
                {isClosed ? '' : t('cancel and back')}
              </Button>
            </Link>
          </JukiLayout>
          <JukiLayout>
            <MenuSideBar
              items={menuSideBarItems}
              isClosed={isClosed}
            />
          </JukiLayout>
        </>
      )}
      sideMain={() => (
        <>
          <JukiLayout type="title">
            <div className="text-xh bold">
              {t('title')}: {tab !== ProblemTab.STATEMENT && problemNew.name}
              {tab === ProblemTab.STATEMENT && (
                <FieldEditable
                  value={problemNew.name}
                  onChange={(value) => setProblemNew(prevState => ({ ...prevState, name: value }))}
                />
              )}
            </div>
            <LoaderButton
              type="primary"
              className="color-primary-light"
              onClick={(setLoader) => () => createNewProblem(problemNew, setLoader)()}
            >
              {t('create problem')}
            </LoaderButton>
          </JukiLayout>
          <JukiLayout>
            {tab === ProblemTab.STATEMENT && (
              <ProblemCreateStatement problem={problemNew} setProblem={setProblemNew} />)}
            {tab === ProblemTab.SETUP && (<ProblemCreateSetup problem={problemNew} setProblem={setProblemNew} />)}
            {tab === ProblemTab.TESTS && (
              <>
                <h5>{t('first create the problem')}</h5>
              </>
            )}
          </JukiLayout>
        </>
      )}
    />
    /*<>
     <LayoutSideBar className="page-problem-create" />
     <LayoutSideMain className="page-problem-create">
     <LayoutMain className={classNames('with-scroll', tab, { 'full-width': tab === ProblemTab.STATEMENT })}>
     
     </LayoutMain>
     </LayoutSideMain>
     </>*/
  );
};
