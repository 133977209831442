import { ProfilesActionTypes, ProfilesState, ProfileActions } from '../types';

export const profiles = (state: ProfilesState = {}, action: ProfilesActionTypes) => {
  const newState = { ...state };
  switch (action.type) {
    case ProfileActions.REPLACE_PROFILES:
      return { ...state, ...action.profiles };
    case ProfileActions.REPLACE_PROFILE:
      newState[action.profile.nickname] = { ...newState[action.profile.nickname], ...action.profile };
      return newState;
    default:
      return state;
  }
};
