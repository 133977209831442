import React, { useState } from 'react';
import { Select } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

import { Judge, MetaProblemSearcher, LoaderAction } from '~/types';
import { getProblemBasicList, codeforcesProblems } from '~/services';
import { ProblemSearcher } from './ProblemSearcher';
import { LoaderButton } from '~/components';
import { JUDGE, ERROR, LOADING, SUCCESS } from '~/commons/constants';

import './ProblemSelector.scss';

const { Option } = Select;

type problemsData = { tags: Array<string>, problems: Array<MetaProblemSearcher> };

export const ProblemSelector = ({ selectProblem }: { selectProblem: (problems: Array<MetaProblemSearcher>) => void }) => {
  const [judge, setJudge] = useState<Judge>(Judge.JUKI_JUDGE);
  const [codeforcesData, setCodeforcesData] = useState<problemsData>({
    tags: [],
    problems: []
  });
  const [jukiJudgeData, setJukiJudgeData] = useState<problemsData>({
    tags: [],
    problems: []
  });
  const loadCodeforcesData = async (setLoader: LoaderAction) => {
    const now = new Date().getTime();
    setLoader([now, LOADING]);
    const problems = await codeforcesProblems(['']);
    if (problems) {
      const allTags = new Set<string>();
      problems.problems.forEach((problem: { tags: Array<string> }) => {
        problem.tags.forEach((tag: string) => allTags.add(tag));
      });
      setCodeforcesData({
        tags: ['__ALL__', ...Array.from(allTags).sort((a, b) => a.localeCompare(b))],
        problems: problems.problems.map((problem: { contestId: number, index: string, name: string, type: string, rating: number, tags: Array<string> }) => ({
          name: problem.name,
          url: `https://codeforces.com/problemset/problem/${problem.contestId}/${problem.index}`,
          id: '' + problem.contestId + problem.index,
          rating: problem.rating,
          tag: problem.tags.join(','),
          judge: Judge.CODEFORCES
        })).sort((a: MetaProblemSearcher, b: MetaProblemSearcher) => (a.name.localeCompare(b.name)))
      });
      setLoader([now, SUCCESS]);
    } else {
      setLoader([now, ERROR]);
    }
  };
  const loadJukiJudgeData = async (setLoader: LoaderAction) => {
    const now = new Date().getTime();
    setLoader([now, LOADING]);
    const result = await getProblemBasicList()();
    if (result.success === SUCCESS) {
      const allTags = new Set<string>();
      result.list.forEach((problem) => {
        problem.tags.forEach((tag: string) => allTags.add(tag));
      });
      setJukiJudgeData({
        tags: ['__ALL__', ...Array.from(allTags).sort((a, b) => a.localeCompare(b))],
        problems: result.list.map(problem => ({
          name: problem.name,
          url: `https://jukijudge.com/problem/view/${problem.id}/statement`,
          id: '' + problem.id,
          rating: problem.successRate,
          tag: problem.tags.join(','),
          judge: Judge.JUKI_JUDGE
        })).sort((a: MetaProblemSearcher, b: MetaProblemSearcher) => (a.name.localeCompare(b.name)))
      });
      setLoader([now, SUCCESS]);
    } else {
      setLoader([now, ERROR]);
    }
  };
  
  return (
    <div className="content-problem-selector">
      <div className="problem-selector-title">
        Add problems from
        <Select
          style={{ minWidth: '16em' }}
          value={judge}
          onSelect={value => setJudge(value)}
        >
          <Option value={Judge.JUKI_JUDGE}>{JUDGE[Judge.JUKI_JUDGE].print}</Option>
          <Option value={Judge.CODEFORCES}>{JUDGE[Judge.CODEFORCES].print}</Option>
        </Select>
        <LoaderButton
          onClick={setLoader => () => judge === Judge.CODEFORCES ? loadCodeforcesData(setLoader) : judge === Judge.JUKI_JUDGE ? loadJukiJudgeData(setLoader) : null}
          icon={<DownloadOutlined />}
        >
          Download data
        </LoaderButton>
      </div>
      {judge === Judge.JUKI_JUDGE && (
        <ProblemSearcher
          judgeData={jukiJudgeData}
          selectProblem={selectProblem}
        />
      )}
      {judge === Judge.CODEFORCES && (
        <ProblemSearcher
          judgeData={codeforcesData}
          selectProblem={selectProblem}
        />
      )}
    </div>
  );
};
