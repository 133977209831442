import React, { useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { CopyOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { ImageUploader, LoaderButton } from '../../';
import { UploadImageProps } from './types';
import { Status } from '../../../types';

import './styles.scss';

export const UploadImage = ({ uploadImageRequest }: UploadImageProps) => {
  
  const [imagePublicUrl, setImagePublicUrl] = useState<string>('');
  const { t } = useTranslation();
  
  return (
    <div className="layout-upload-image">
      <ImageUploader
        action={
          croppedImage => (
            <LoaderButton
              onClick={(setLoader) => async () => {
                if (croppedImage.blob) {
                  const now = new Date().getTime();
                  setLoader([now, Status.LOADING]);
                  const [url, success] = await uploadImageRequest(croppedImage.blob);
                  if (success === Status.SUCCESS) {
                    setImagePublicUrl(url);
                    setLoader([now, Status.SUCCESS]);
                  } else {
                    setLoader([now, Status.ERROR]);
                  }
                }
              }}
              disabled={!croppedImage.blob}
            >
              {t('upload image')}
            </LoaderButton>
          )
        }
      />
      {imagePublicUrl && (
        <div className="result-box">
          <div className="public-url bold">
            Public url: <a href={imagePublicUrl} className="text-xs semi-bold" target="_blank"
                           rel="noreferrer">{imagePublicUrl}</a>
          </div>
          <div className="markdown-use bold">
            Markdown use:
            <span className="text-xs semi-bold">![image alt]({imagePublicUrl})</span>
            <CopyToClipboard text={`![image alt](${imagePublicUrl})`}>
              <CopyOutlined />
            </CopyToClipboard>
          </div>
          <div className="markdown-use bold">
            Markdown use with size:
            <span className="text-xs semi-bold">![image alt]({imagePublicUrl}=200x200)</span>
            <CopyToClipboard text={`![image alt](${imagePublicUrl}=200x200)`}>
              <CopyOutlined />
            </CopyToClipboard>
          </div>
        </div>
      )}
    </div>
  );
};

export * from './types';
