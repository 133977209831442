export enum Judge {
  JUKI_JUDGE = 'JUKI_JUDGE',
  CODEFORCES = 'CODEFORCES',
  UVA_ONLINE_JUDGE = 'UVA_ONLINE_JUDGE',
  AT_CODER = 'AT_CODER',
  CODECHEF = 'CODECHEF'
}

export enum ProblemVerdict {
  AC = 'AC',
  PA = 'PA',
  CE = 'CE',
  RTE = 'RE',
  TLE = 'TLE',
  MLE = 'MLE',
  WA = 'WA',
  PENDING = 'PENDING'
}
