import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Layout, Menu } from 'antd';
import { FacebookOutlined } from '@ant-design/icons';

import { ROUTES } from '../../config/constants/routes';
import { useAccountState, useFlagsDispatch } from '../../hooks';
import { ReactComponent as JukiJudge } from '../../graphics/juki-judge-logo.svg';
import { ContestTimeStatus } from '../../commons/types';

import './styles.scss';

const { Footer } = Layout;

export const FooterApp = () => {

  const account = useAccountState();
  const params: Array<string> = useLocation().pathname.split('/');
  const { updateFlags } = useFlagsDispatch();
  const { t } = useTranslation();
  useEffect(() => {
    if (account.isLogged) {
      updateFlags({ openSignUpModal: false, openLoginModal: false })();
    }
  }, [account, updateFlags]);

  const keyTab = (params[1] === ROUTES.PARAMS.CONTESTS || params[1] === ROUTES.PARAMS.CONTEST) ? ROUTES.PARAMS.CONTESTS :
    (params[1] === ROUTES.PARAMS.PROBLEM || params[1] === ROUTES.PARAMS.PROBLEM) ? ROUTES.PARAMS.PROBLEMS :
      params[1];
  const menu = (
    <Menu mode="horizontal" selectedKeys={[keyTab]}>
      <Menu.Item key={ROUTES.PARAMS.CONTESTS}>
        <Link to={ROUTES.CONTESTS.LIST_PAGE(ContestTimeStatus.LIVE)}>{t('contests')}</Link>
      </Menu.Item>
      <Menu.Item key={ROUTES.PARAMS.PROBLEMS}>
        <Link to={ROUTES.PROBLEMS.LIST_PAGE()}>{t('problems')}</Link>
      </Menu.Item>
      {/*<Menu.Item key={ROUTES.PARAMS.ABOUT}>*/}
      {/*  <Link to={ROUTES.ABOUT.PAGE}>{t('about us')}</Link>*/}
      {/*</Menu.Item>*/}
      {
        /*
         <Menu.Item key={ROUTES.PARAMS.RANKING}>
         <Link to={ROUTES.RANKING.PAGE()}>RANKING</Link>
         </Menu.Item>
         */
      }
    </Menu>
  );
  return (
    <Footer className="juki-layout-app-footer" translate="yes">
    {/*<Footer
      className={classNames('app-footer',
        { 'hidden': params[1] === ROUTES.PARAMS.CONTEST && params[2] === ROUTES.PARAMS.VIEW && params[4] === ROUTES.PARAMS.PRINT_SCORE })}>*/}
      <div className="content-app-footer">
        <div className="content-menu">
          {menu}
        </div>
        <div className="content-logo-menu-social-faq-box">
          <div className="logo-menu">
            <div className="logo-hor text-t child-center">
              <JukiJudge />
              v{process.env.REACT_APP_VERSION || '0.0.1'}
            </div>
            {menu}
          </div>
          <div className="social-faq">
            {/*<Link to={ROUTES.PROBLEMS.LIST_PAGE()}>FAQ</Link>*/}
            <div className="content-social">
              <a href="https://www.facebook.com/jukijudge" target="_blank" rel="noopener noreferrer">
                <FacebookOutlined />
              </a>
            </div>
          </div>
        </div>
      </div>
    </Footer>
  );
};
