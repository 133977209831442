import { useDispatch, useSelector } from 'react-redux';
import { createSelector, ParametricSelector } from 'reselect';

import { UserState } from '../store/types';
import { RootState } from '../store/reducers';
import { AccountStatus, LoaderAction } from '../commons/types';
import { changePermissionsUser, changeStatusUser, loadAllUsers } from '../store/actions';
import { useCallback } from 'react';

const selectUsersStates: ParametricSelector<RootState, undefined, { users: Array<UserState> }> =
  createSelector<RootState, Array<UserState>, { users: Array<UserState> }>(
    state => state.users,
    users => ({ users })
  );

export const useUsersState = () => {
  return useSelector((state: RootState) => selectUsersStates(state, undefined));
};

export const useUsersDispatch = () => {
  const dispatch = useDispatch();
  return {
    loadAllUsers: useCallback((search: string, setLoading: LoaderAction) => () => dispatch(loadAllUsers(search, setLoading)), [dispatch]),
    changeStatusUser: (nickname: string, status: AccountStatus, setLoading: LoaderAction) => () => dispatch(changeStatusUser(nickname, status, setLoading)),
    changePermissionsUser: (nickname: string, permissions: Array<{ key: string, value: string }>, setLoading: LoaderAction) => () => dispatch(changePermissionsUser(nickname, permissions, setLoading))
  };
};
