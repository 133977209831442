import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { useTranslation } from 'react-i18next';
import { Button, Col, Form, Input, Modal, Row } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import * as yup from 'yup';

import { DEFAULT_JUKI_PROFILE_IMAGE, LOADING, NONE, ROUTES } from '~/config/constants';
import { useAccountState, useProfileDispatch, useRouter } from '~/hooks';
import { AccountState } from '~/store/types';
import { Judge, LoaderState, ProfileSettingOptions, ProfileTab, Theme } from '~/types';
import { ImageUploader, LoaderButton } from '~/components';
import { ChangePassword } from './ChangePassword';
import ChangeTheme from './ChangeTheme';
import { ProfileSettingsInput } from './types';

import './styles.scss';

const profileSettingsSchema = yup.object().shape({
  institution: yup.string()
    .required('Required'),
  country: yup.string()
    .required('Required'),
  [ProfileSettingOptions.THEME]: yup.boolean(),
  [Judge.AT_CODER]: yup.string(),
  [Judge.CODEFORCES]: yup.string(),
  firstName: yup.string()
    .required('Required'),
  nickName: yup.string()
    .required('Required'),
  email: yup.string()
    .email()
    .required(''),
  lastName: yup.string()
    .required('Required'),
  city: yup.string()
    .required('Required')
});

export const resetProfileInput = (account: AccountState) => (): ProfileSettingsInput => ({
  firstName: account.givenName || '',
  lastName: account.familyName || '',
  nickName: account.nickname || '',
  country: account.country || '',
  city: account.city || '',
  institution: account.institution || '',
  email: account.email || '',
  [Judge.CODEFORCES]: account.handles[Judge.CODEFORCES],
  [Judge.AT_CODER]: account.handles[Judge.AT_CODER],
  [ProfileSettingOptions.THEME]: (account.mySettings[ProfileSettingOptions.THEME] + '' === Theme.DARK)
});

let render = 1;
export const Settings = () => {
  render++;
  const { params, push } = useRouter<{ params: { key: string } }>();
  const { key } = params;
  const account = useAccountState();
  const { updateUserSettings, updateUserImageProfile } = useProfileDispatch();
  const { handleSubmit, control, reset, errors, formState } = useForm<ProfileSettingsInput>({
    mode: 'onChange',
    defaultValues: resetProfileInput(account)(),
    resolver: yupResolver(profileSettingsSchema)
  });
  const [modalVisible, setModalVisible] = useState(false);
  const [loader, setLoader] = useState<LoaderState>([new Date().getTime(), NONE]);
  useEffect(() => {
    if (account.nickname !== key) {
      push(ROUTES.PROFILE.PAGE(key, ProfileTab.PROFILE));
    }
  }, [key, account.nickname, push]);
  useEffect(() => {
    if (account) {
      reset(resetProfileInput(account)());
    }
  }, [account, reset]);
  const { isValid } = formState;
  const { t } = useTranslation();
  
  return (
    <div className="layout-settings child-center">
      <Modal
        visible={modalVisible}
        onOk={() => null}
        onCancel={() => setModalVisible(false)}
        footer={null}
      >
        <div className="settings-update-image-modal">
          <ImageUploader
            defaultCrop={{
              aspect: 1,
              unit: '%',
              width: 100,
              x: 0,
              y: 0
            }}
            action={(croppedImage) => (
              <LoaderButton
                onClick={(setLoader) => async () => {
                  if (croppedImage.blob) {
                    updateUserImageProfile(croppedImage.blob, setLoader)();
                  }
                }}
                disabled={!croppedImage.blob}
              >
                {t('upload image')}
              </LoaderButton>
            )}
          />
        </div>
      </Modal>
      <Form layout="vertical">
        <Row>
          <Col>
            <Row>
              <Col className="col-edit-image child-center">
                <Row className="row-edit-image-profile">
                  <img
                    className="img-style"
                    src={account.imageUrl || DEFAULT_JUKI_PROFILE_IMAGE}
                    alt="user-logo"
                    key={render}
                  />
                  <Button shape="circle" icon={<EditOutlined />} onClick={() => setModalVisible(true)} />
                </Row>
                <p className="upload-text">{t('update your photo')}</p>
              </Col>
            </Row>
            <Row justify="space-between">
              <Col span={17}>
                <Form.Item className="label" label={t('first name')}>
                  <Controller
                    control={control}
                    as={<Input />}
                    name="firstName"
                  />
                </Form.Item>
              </Col>
              <Col span={17}>
                <Form.Item className="label" label={t('last name')}>
                  <Controller
                    control={control}
                    as={<Input />}
                    name="lastName"
                    defaultValue=""
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={35}>
                <Form.Item className="label" label={t('nickname')}>
                  <Controller
                    control={control}
                    as={<Input />}
                    name="nickName"
                    defaultValue=""
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row justify="space-between">
              <Col span={17}>
                <Form.Item className="label" label={t('country')}>
                  <Controller
                    control={control}
                    as={<Input />}
                    name="country"
                    defaultValue=""
                  />
                </Form.Item>
              </Col>
              <Col span={17}>
                <Form.Item className="label" label={t('city/state')}>
                  <Controller
                    control={control}
                    as={<Input />}
                    name="city"
                    defaultValue=""
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={35}>
                <Form.Item className="label" label={t('institution')}>
                  <Controller
                    control={control}
                    as={<Input />}
                    name="institution"
                    defaultValue=""
                  />
                </Form.Item>
              </Col>
            </Row>
            {/*
             <Row>
             <Form.Item>
             <Controller
             control={control}
             name="notification"
             defaultValue={false}
             render={({onChange, value}: any) => (
             <Checkbox
             onChange={e => onChange(e.target.checked)}
             checked={value}
             > Contest email notification </Checkbox>
             )}
             />
             </Form.Item>
             </Row>
             <Row>
             <Form.Item>
             <Controller
             control={control}
             name="mailTalk"
             defaultValue={false}
             render={({onChange, value}: any) => (
             <Checkbox
             onChange={e => onChange(e.target.checked)}
             checked={value}
             > Send Email on new use talk </Checkbox>
             )}
             />
             </Form.Item>
             </Row>
             <Row>
             <Form.Item>
             <Controller
             control={control}
             name="diagnosis"
             defaultValue={false}
             render={({onChange, value}: any) => (
             <Checkbox
             onChange={e => onChange(e.target.checked)}
             > Show Diagnostic </Checkbox>
             )}
             />
             </Form.Item>
             </Row>
             */
            }
          </Col>
          <Col>
            <Form.Item className="label" label={t('e-mail address')}>
              <Controller
                control={control}
                as={<Input />}
                name="email"
                defaultValue=""
              />
              <p className="validator-error">  {errors.email?.message}</p>
            </Form.Item>
            <Form.Item className="label" label="Codeforces ID">
              <Controller
                control={control}
                as={<Input />}
                name={Judge.CODEFORCES}
                defaultValue=""
              />
            </Form.Item>
            <Form.Item className="label" label="AtCoder ID">
              <Controller
                control={control}
                as={<Input />}
                name={Judge.AT_CODER}
                defaultValue=""
              />
            </Form.Item>
            {
              /*<Form.Item>
               <Controller
               control={control}
               name="hide"
               defaultValue={false}
               render={({onChange, value}: any) => (
               <Checkbox
               onChange={e => onChange(e.target.checked)}
               checked={value}
               > Hide low rated </Checkbox>
               )}
               />
               </Form.Item>
               
               <Form.Item>
               <Controller
               control={control}
               name="socialDis"
               defaultValue={false}
               render={({onChange, value}: any) => (
               <Checkbox
               onChange={e => onChange(e.target.checked)}
               checked={value}
               > Disable social network </Checkbox>
               )}
               />
               </Form.Item>
               */
            }
            <Form.Item>
              <LoaderButton
                onClick={(setLoader) => handleSubmit((data) => {
                  updateUserSettings({
                    ...account,
                    givenName: data.firstName,
                    familyName: data.lastName,
                    email: data.email,
                    nickname: data.nickName,
                    country: data.country,
                    city: data.city,
                    institution: data.institution,
                    handles: {
                      ...account.handles,
                      CODEFORCES: data[Judge.CODEFORCES],
                      AT_CODER: data[Judge.AT_CODER]
                    }
                  }, setLoader)();
                })}
                type="ghost"
                disabled={!isValid || loader[1] === LOADING}
              >
                {t('update information')}
              </LoaderButton>
            </Form.Item>
            {/*<Form.Item>*/}
            {/*  <Controller*/}
            {/*    control={control}*/}
            {/*    name="preferredLanguage"*/}
            {/*    defaultValue={account?.mySettings[ProfileSettingOptions.LANGUAGE] === Language.SPANISH}*/}
            {/*    render={({ onChange, value }: any) => (*/}
            {/*      <ChangeLanguage*/}
            {/*        value={value}*/}
            {/*        onChange={(e) => {*/}
            {/*          onChange(e);*/}
            {/*          onSubmit({ ...resetValues(), [ProfileSettingOptions.LANGUAGE]: e }, setLoader);*/}
            {/*        }}*/}
            {/*        loading={loader[1] === LOADING}*/}
            {/*      />*/}
            {/*    )}*/}
            {/*  />*/}
            {/*</Form.Item>*/}
            <Form.Item>
              <Controller
                control={control}
                name={ProfileSettingOptions.THEME}
                defaultValue={account?.mySettings[ProfileSettingOptions.THEME] === Theme.DARK}
                render={({ onChange, value }: any) => (
                  <ChangeTheme
                    value={value}
                    onChange={(e) => {
                      onChange(e);
                      updateUserSettings({
                        ...account,
                        settings: [
                          ...account.settings.filter(setting => setting.key !== ProfileSettingOptions.THEME),
                          { key: ProfileSettingOptions.THEME, value: e ? Theme.DARK : Theme.LIGHT }
                        ]
                      }, setLoader)();
                    }}
                    loading={loader[1] === LOADING}
                  />
                )}
              />
            </Form.Item>
            <Form.Item className="change-password-button">
              <ChangePassword />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
