import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector, ParametricSelector } from 'reselect';

import { RootState } from '../store/reducers';
import { FlagsState, FlagsStateOptions } from '../store/types';
import { updateFlags } from '../store/actions';

const selectFlagsState: ParametricSelector<RootState, undefined, FlagsState> =
  createSelector<RootState, FlagsState, FlagsState>(
    state => state.flags,
    (flags) => flags);

export const useFlagsState = () => {
  return useSelector((state: RootState) => selectFlagsState(state, undefined));
}

export const useFlagsDispatch = () => {
  const dispatch = useDispatch();
  return {
    updateFlags: useCallback((flags: FlagsStateOptions) => () => dispatch(updateFlags(flags)), [dispatch])
  }
}
