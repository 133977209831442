import FileSaver from 'file-saver';

import { ERROR_MESSAGES } from '~/config/constants';
import { AccountStatus, ContestStatus, ErrorCodes } from '~/types';

export const BASE_URL = process.env.REACT_APP_JUKI_JUDGE_BASE_URL;
export const JUDGE_BACKEND_BASE_URL = process.env.REACT_APP_JUKI_JUDGE_BACKEND_BASE_URL;
export const COUCH_BACKEND_BASE_URL = process.env.REACT_APP_JUKI_COUCH_BACKEND_BASE_URL;

// USER
export const API = {
  ACCOUNT: {
    PING: () => {
      return `${JUDGE_BACKEND_BASE_URL}/api/auth/ping`;
    },
    LOGOUT: () => {
      return `${JUDGE_BACKEND_BASE_URL}/api/auth/logout`;
    },
    USER: (nickname?: string) => {
      return `${JUDGE_BACKEND_BASE_URL}/api/user${nickname ? '/' + nickname : ''}`;
    },
    SIGNIN: () => {
      return `${JUDGE_BACKEND_BASE_URL}/api/auth/signin`;
    },
    GOOGLE_SIGNIN: () => {
      return `${JUDGE_BACKEND_BASE_URL}/api/auth/signin/google`;
    },
    SIGNUP: () => {
      return `${JUDGE_BACKEND_BASE_URL}/api/auth/signup`;
    },
    CHANGE_STATUS: (nickname: string, status: AccountStatus) => {
      return `${JUDGE_BACKEND_BASE_URL}/api/user/changeStatus/${nickname}?status=${status}`;
    },
    CHANGE_PERMISSIONS: (nickname: string) => {
      return `${JUDGE_BACKEND_BASE_URL}/api/user/permissions/${nickname}`;
    },
    UPDATE: () => {
      return `${JUDGE_BACKEND_BASE_URL}/api/user/update`;
    },
    MY_STATUS: () => {
      return `${JUDGE_BACKEND_BASE_URL}/api/user/myStatus`;
    },
    UPDATE_IMAGE: () => {
      return `${JUDGE_BACKEND_BASE_URL}/api/user/updateImage`;
    },
    CHANGE_PASSWORD: () => {
      return `${JUDGE_BACKEND_BASE_URL}/api/user/password/change`;
    }
  },
  PROBLEM: {
    PROBLEM: (id?: string) => {
      return `${JUDGE_BACKEND_BASE_URL}/api/problem${id ? '/' + id : ''}`;
    },
    TESTS: (id: string) => {
      return `${JUDGE_BACKEND_BASE_URL}/api/problem/${id}/testCases`;
    },
    TEST: (id: string, testId?: string) => {
      return `${JUDGE_BACKEND_BASE_URL}/api/problem/${id}/testCase${testId ? '/' + testId : ''}`;
    },
    BASIC_LIST: () => {
      return `${JUDGE_BACKEND_BASE_URL}/api/problem/basicList`;
    },
    SUBMIT: (id: string) => {
      return `${JUDGE_BACKEND_BASE_URL}/api/problem/${id}/submit`;
    },
    SUBMISSION_CODE: (key: string) => {
      return `${JUDGE_BACKEND_BASE_URL}/api/problem/submit/${key}`;
    },
    PROBLEM_STATUS: (id: string) => {
      return `${JUDGE_BACKEND_BASE_URL}/api/problem/${id}/submit/status`;
    }
  },
  CONTEST: {
    CONTEST: (key?: string) => {
      return `${JUDGE_BACKEND_BASE_URL}/api/contest${key ? '/' + key : ''}`;
    },
    CHANGE_STATUS: (key: string, status: ContestStatus) => {
      return `${JUDGE_BACKEND_BASE_URL}/api/contest/changeStatus/${key}?status=${status}`;
    },
    REGISTER: (key: string) => {
      return `${JUDGE_BACKEND_BASE_URL}/api/contest/${key}/register`;
    },
    UN_FROZEN: (key: string) => {
      return `${JUDGE_BACKEND_BASE_URL}/api/contest/${key}/scoreboard/unFrozen`;
    },
    MY_STATUS: (key: string) => {
      return `${JUDGE_BACKEND_BASE_URL}/api/contest/${key}/myStatus`;
    },
    STATUS: (key: string) => {
      return `${JUDGE_BACKEND_BASE_URL}/api/contest/${key}/status`;
    },
    PENDING_STATUS: (key: string) => {
      return `${JUDGE_BACKEND_BASE_URL}/api/contest/${key}/judge/list`;
    },
    SUBMIT: (key: string, problemIndex: string) => {
      return `${JUDGE_BACKEND_BASE_URL}/api/contest/${key}/problem/${problemIndex}/submit`;
    },
    SCOREBOARD: (key: string) => {
      return `${JUDGE_BACKEND_BASE_URL}/api/contest/${key}/scoreboard`;
    },
    REJUDGE_PROBLEM: (key: string, problemIndex: string) => {
      return `${JUDGE_BACKEND_BASE_URL}/api/contest/${key}/problem/${problemIndex}/rejudge`;
    },
    REJUDGE_SUBMISSION: (key: string, submissionMongoId: string) => {
      return `${JUDGE_BACKEND_BASE_URL}/api/contest/${key}/rejudge/${submissionMongoId}`;
    },
    JUDGE_SUBMISSION: (key: string,) => {
      return `${JUDGE_BACKEND_BASE_URL}/api/contest/${key}/judge/submit`;
    },
    VIEW_SOURCE_SUBMISSION: (key: string, submissionMongoId: string) => {
      return `${JUDGE_BACKEND_BASE_URL}/api/contest/${key}/submit/${submissionMongoId}/source`;
    },
    CLARIFICATIONS: (key: string) => {
      return `${JUDGE_BACKEND_BASE_URL}/api/contest/${key}/clarification`;
    },
    ANSWER_CLARIFICATION: (key: string, idClarification: string) => {
      return `${JUDGE_BACKEND_BASE_URL}/api/contest/${key}/clarification/${idClarification}/answer`;
    }
  },
  UPLOAD: () => {
    return `${JUDGE_BACKEND_BASE_URL}/api/problem/file/update`;
  },
};

export const API_COUCH = {
  ACCOUNT: {
    RESET_PASSWORD: () => {
      return `${COUCH_BACKEND_BASE_URL}/api-couch/account/reset-password`;
    },
  }
};
export const GET = 'GET';
export const POST = 'POST';
export const PUT = 'PUT';
export const DELETE = 'DELETE';

export const authorizedRequest = async (url: string, method?: typeof POST | typeof PUT | typeof DELETE | typeof GET, body?: string, signal?: AbortSignal) => {
  const requestHeaders: HeadersInit = new Headers();
  requestHeaders.set('Accept', 'application/json');
  requestHeaders.set('Content-Type', 'application/json');
  return await fetch(url, {
    method: method ? method : GET,
    headers: requestHeaders,
    credentials: 'include',
    ...(body ? { body } : {}),
    ...(signal ? { signal } : {})
  })
    .then((response: any) => {
      return response.text();
    })
    .catch((error) => {
      console.error('The Error:', error);
      if (signal?.aborted) {
        return JSON.stringify({
          success: false,
          message: ERROR_MESSAGES[ErrorCodes.ERR9997],
          errorCode: ErrorCodes.ERR9997
        });
      }
      return 'FETCH CATCH ERROR :' + JSON.stringify({ url, error });
    });
};

export const authorizedFormDataRequest = async (url: string, method?: typeof POST | typeof PUT | typeof DELETE | typeof GET, body?: FormData, signal?: AbortSignal) => {
  return await fetch(url, {
    method: method ? method : GET,
    credentials: 'include',
    ...(body ? { body } : {}),
    ...(signal ? { signal } : {})
  })
    .then((response: any) => {
      return response.text();
    })
    .catch((error) => {
      console.error('The Error:', error);
      return 'FETCH CATCH ERROR :' + JSON.stringify(error);
    });
};

export const downloadFile = (file: Blob, type: string, fileName: string) => {
  const blobFile = new Blob([file], { type: `text/${type}` });
  FileSaver.saveAs(blobFile, fileName);
};

export const authorizedFileRequest = async (url: string) => {
  let error = false;
  const file: any = await fetch(url, { method: GET, credentials: 'include' })
    .catch((error) => {
      error = true;
      console.error('The Error:', error);
      return 'FETCH CATCH ERROR :' + JSON.stringify(error);
    });
  if (file && !error && file.ok) {
    return await file.blob();
  }
  return null;
};
