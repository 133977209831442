import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useInterval } from '../../../hooks';
import { splitTime } from '../../../commons/helpers';

import './TimerClock.scss';

export enum Period {
  FUTURE = 'future',
  LIVE = 'live',
  PAST = 'past',
  CALC = 'calc'
}

type Props = {
  startDate: Date,
  endDate: Date,
  currentDate?: Date,
  onFinish?: () => void,
  labels?: { [key in Period]: string },
  laps?: number
}

const DEFAULT_LABELS: { [key in Period]: string } = {
  [Period.FUTURE]: 'stars in',
  [Period.LIVE]: 'ends in',
  [Period.PAST]: 'ended ago',
  [Period.CALC]: '...'
};

export const TimerClock = React.memo(({ startDate, endDate, currentDate, onFinish, labels, laps = 3 }: Props) => {
    const [time, setTime] = useState<{ counting: number, remaining: number, period: Period }>({
      counting: 0,
      remaining: 0,
      period: Period.CALC
    });
    const myLabels = { ...DEFAULT_LABELS, ...labels };
    const { t } = useTranslation();
    useEffect(() => {
      const now = new Date();
      const period = (currentDate || now) < startDate ? Period.FUTURE : ((currentDate || now) > endDate ? Period.PAST : Period.LIVE);
      const remaining = Math.abs(new Date(period === Period.FUTURE ? startDate : endDate).getTime() - new Date(currentDate || now).getTime());
      const slack = remaining % 1000;
      const startCounting = setTimeout(() => {
        setTime({
          counting: period === Period.PAST ? 1000 : -1000,
          remaining,
          period
        });
      }, slack);
      return () => {
        clearTimeout(startCounting);
      };
    }, [startDate, endDate, currentDate]);
    
    useEffect(() => {
      if (time.remaining < 0) {
        onFinish?.();
        setTime({
          counting: 0,
          remaining: 0,
          period: Period.CALC
        });
      }
    }, [onFinish, time.remaining]);
    
    useInterval(() => {
      setTime(prevState => ({ ...prevState, remaining: prevState.remaining + time.counting }));
    }, Math.abs(time.counting));
    
    return (
      <div className="layout-timer-clock">
        <div className="label-period text-s bold">
          {t(myLabels[time.period])}:
        </div>
        <div className="content-time">
          {splitTime(Math.max(time.remaining, 0)).slice(0, laps).map((remaining, index) => (
            <Fragment key={remaining.label}>
              <div className="content-stamp">
                <div className="content-number text-s semi-bold">{remaining.remaining}</div>
                <div className="content-label text-t semi-bold">{t(remaining.label)}</div>
              </div>
              {(index !== laps - 1) && <span className="content-dots text-l semi-bold">:</span>}
            </Fragment>
          ))}
        </div>
      </div>
    );
  },
  (prevProps, nextProps) => {
    return JSON.stringify(prevProps) === JSON.stringify(nextProps);
  }
);
