import React, { useEffect, useState } from 'react';

import { NONE, SEC_30 } from '~/config/constants';
import { useAccountState, useInterval, useProblemsDispatch, useProblemState, useRouter } from '~/hooks';
import { LoaderState, ProblemTab } from '~/types';
import { SubmissionsTable } from '../../../Contests/View/Status/SubmissionTable';
import { ContestSubmission } from '~/store/types';
import { Pagination } from '~/components';
import { DataIndexSubmission, RowDataProblemSubmission } from '../../../Contests/View/Status/SubmissionTable/types';

import './styles.scss';

export const ProblemSubmission = () => {
  
  const { params, search } = useRouter<{ params: { key: string, tab: ProblemTab } }>();
  const { key } = params;
  const { loadProblemStatus } = useProblemsDispatch();
  const { submissions } = useProblemState(key);
  const [loader, setLoader] = useState<LoaderState>([new Date().getTime(), NONE]);
  const { nickname } = useAccountState();
  useEffect(() => {
    loadProblemStatus(key, setLoader, search, true)();
  }, [loadProblemStatus, key, search]);
  useInterval(() => {
    loadProblemStatus(key, setLoader, search, false)();
  }, SEC_30);
  
  const data = submissions.list.map((submission): RowDataProblemSubmission => ({
    [DataIndexSubmission.KEY]: submission.submitId,
    [DataIndexSubmission.WHO]: { nickname: submission.nickname, imageUrl: submission.imageUrl },
    [DataIndexSubmission.WHEN]: submission.when,
    [DataIndexSubmission.TIME_USED]: submission.timeUsed,
    [DataIndexSubmission.MEMORY_USED]: submission.memoryUsed,
    [DataIndexSubmission.VERDICT]: { verdict: submission.answer, points: submission.submitPoints },
    [DataIndexSubmission.LANGUAGE]: submission.language,
    [DataIndexSubmission.CODE]: submission.nickname === nickname ? submission as ContestSubmission : null
  }));
  
  const head = {
    [DataIndexSubmission.WHO]: true,
    [DataIndexSubmission.WHEN]: true,
    [DataIndexSubmission.TIME_USED]: true,
    [DataIndexSubmission.MEMORY_USED]: true,
    [DataIndexSubmission.VERDICT]: true,
    [DataIndexSubmission.LANGUAGE]: true,
    [DataIndexSubmission.CODE]: true
  };
  
  return (
    <>
      <div className="content-main-problem-submissions">
        <SubmissionsTable data={data} loading={loader} head={head} />
      </div>
      {!!submissions.totalElements && <Pagination totalElements={submissions.totalElements} />}
    </>
  );
};
