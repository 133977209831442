import React, { useEffect, useState } from 'react';
import { Redirect, useLocation, useParams } from 'react-router';

import { useAccountDispatch, useAccountState, useInterval } from '~/hooks';
import { ContestSubmission } from '~/store/types';
import { ContestTab, LoaderState, ProblemTab, ProfileTab } from '~/types';
import { SubmissionsTable } from '../../Contests/View/Status/SubmissionTable';
import { Pagination } from '~/components';
import { NONE, ROUTES, SEC_30 } from '~/config/constants';
import { DataIndexSubmission, RowDataMySubmission } from '../../Contests/View/Status/SubmissionTable/types';

import './Submissions.scss';

export const Submissions = () => {
  
  const { key, tab } = useParams<{ key: string, tab: string }>();
  const { nickname, submissions } = useAccountState();
  const { loadMySubmissions } = useAccountDispatch();
  const [loader, setLoader] = useState<LoaderState>([new Date().getTime(), NONE]);
  const { search } = useLocation();
  useEffect(() => {
    if (tab === ProfileTab.SUBMISSIONS && nickname === key) {
      loadMySubmissions(search, setLoader, true)();
    }
  }, [key, loadMySubmissions, nickname, search, tab]);
  useInterval(() => {
    if (tab === ProfileTab.SUBMISSIONS && nickname === key) {
      loadMySubmissions(search, setLoader, false)();
    }
  }, SEC_30);
  
  if (nickname !== key) {
    return <Redirect to={ROUTES.PROFILE.PAGE(key, ProfileTab.PROFILE)} />;
  }
  
  const data = submissions.list.map((submission): RowDataMySubmission => ({
    [DataIndexSubmission.KEY]: submission.submitId,
    [DataIndexSubmission.WHEN]: submission.when,
    [DataIndexSubmission.TIME_USED]: submission.timeUsed,
    [DataIndexSubmission.MEMORY_USED]: submission.memoryUsed,
    [DataIndexSubmission.VERDICT]: { verdict: submission.answer, points: submission.submitPoints },
    [DataIndexSubmission.LANGUAGE]: submission.language,
    [DataIndexSubmission.CODE]: { ...submission, nickname } as unknown as ContestSubmission,
    [DataIndexSubmission.PROBLEM]: {
      link: (submission.indexProblem && submission.problemName && submission.contestKey) ?
        ROUTES.CONTESTS.VIEW(submission.contestKey, ContestTab.PROBLEM, submission.indexProblem, ProblemTab.STATEMENT) :
        ROUTES.PROBLEMS.VIEW(submission.problemId, ProblemTab.STATEMENT),
      index: (submission.indexProblem && submission.problemName && submission.contestKey) ? submission.indexProblem : '',
      name: submission.problemName
    }
  }));
  
  const head = {
    [DataIndexSubmission.WHEN]: true,
    [DataIndexSubmission.TIME_USED]: true,
    [DataIndexSubmission.MEMORY_USED]: true,
    [DataIndexSubmission.VERDICT]: true,
    [DataIndexSubmission.LANGUAGE]: true,
    [DataIndexSubmission.CODE]: true,
    [DataIndexSubmission.PROBLEM]: true
  };
  
  return (
    <>
      <div className="content-main-profile-submissions">
        <SubmissionsTable data={data} loading={loader} head={head}/>
      </div>
      {!!submissions.totalElements && <Pagination totalElements={submissions.totalElements} />}
    </>
  );
};
