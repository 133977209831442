import React from 'react';
import {useParams} from "react-router";
import { ProfileTab } from '../../../commons/types';

export const PersonalContest = () => {
  const {tab} = useParams<{tab: string}>();

  return (
    <>
      {
        tab === ProfileTab.CONTESTS && <>
          <div> hola contrest </div>
        </>
      }
      </>
  )
}
