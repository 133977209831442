import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { createSelector, ParametricSelector } from 'reselect';

import { ContestNewState, ContestsState, ContestState } from '../store/types';
import {
  archiveContest,
  createNewContest,
  editContest,
  loadAllContest,
  loadContest,
  loadContestClarifications,
  loadContestMyStatus,
  loadContestPendingStatus,
  loadContestScoreboard,
  loadContestStatus,
  registerContest,
  submitContestClarification,
  updateContestClarification
} from '../store/actions';
import { RootState } from '../store/reducers';
import { AnswerClarification, ContestTimeStatus, LoaderAction, NewClarification } from '../commons/types';

const selectContestsStates: ParametricSelector<RootState, undefined, { [key in ContestTimeStatus]: Array<ContestState> }> =
  createSelector<RootState, ContestsState, { [key in ContestTimeStatus]: Array<string> }, { [key in ContestTimeStatus]: Array<ContestState> }>(
    state => state.contests,
    state => state.contestsList,
    (contests, contestsList) => ({
      [ContestTimeStatus.UPCOMING]: contestsList[ContestTimeStatus.UPCOMING].map(key => contests[key]),
      [ContestTimeStatus.LIVE]: contestsList[ContestTimeStatus.LIVE].map(key => contests[key]),
      [ContestTimeStatus.PAST]: contestsList[ContestTimeStatus.PAST].map(key => contests[key])
    })
  );

export const useContestsState = () => {
  return useSelector((state: RootState) => selectContestsStates(state, undefined));
};

const selectContestStates: ParametricSelector<RootState, string, ContestState> =
  createSelector<RootState, string, ContestState, ContestState>(
    (state, key) => {
      return ({ ...state.contests[key] });
    },
    (contest) => {
      return contest || {};
    }
  );

export const useContestState = (key: string) => {
  return useSelector((state: RootState) => selectContestStates(state, key));
};

export const useContestDispatch = () => {
  const dispatch = useDispatch();
  return {
    loadScoreboard: useCallback((key: string, setLoading?: LoaderAction) => () => dispatch(loadContestScoreboard(key, setLoading)), [dispatch]),
    loadContest: useCallback((key: string, setLoading?: LoaderAction) => () => dispatch(loadContest(key, setLoading)), [dispatch]),
    loadContestStatus: useCallback((key: string, search: string, clean: boolean, setLoading?: LoaderAction) => () => dispatch(loadContestStatus(key, search, clean, setLoading)), [dispatch]),
    loadContestPendingStatus: useCallback((key: string, search: string, clean: boolean, setLoading?: LoaderAction) => () => dispatch(loadContestPendingStatus(key, search, clean, setLoading)), [dispatch]),
    loadContestMyStatus: useCallback((key: string, search: string, clean: boolean, setLoading?: LoaderAction) => () => dispatch(loadContestMyStatus(key, search, clean, setLoading)), [dispatch]),
    updateContestClarification: useCallback((key: string, idClarification: string, body: AnswerClarification, setLoading: LoaderAction) => async () => await dispatch(updateContestClarification(key, idClarification, body, setLoading)), [dispatch]),
    submitContestClarification: useCallback((key: string, body: NewClarification, setLoading: LoaderAction) => async () => await dispatch(submitContestClarification(key, body, setLoading)), [dispatch]),
    loadContestClarifications: useCallback((key: string, setLoading?: LoaderAction) => () => dispatch(loadContestClarifications(key, setLoading)), [dispatch]),
    loadAllContest: useCallback((type: ContestTimeStatus, setLoading: LoaderAction) => async () => await dispatch(loadAllContest(type, setLoading)), [dispatch]),
    createNewContest: (contest: ContestNewState, setLoading: LoaderAction) => () => dispatch(createNewContest(contest, setLoading)),
    editContest: (contest: ContestNewState, setLoading: LoaderAction) => () => dispatch(editContest(contest, setLoading)),
    archiveContest: (key: string, setLoading: LoaderAction) => () => dispatch(archiveContest(key, setLoading)),
    registerContest: (key: string, setLoading: LoaderAction) => () => dispatch(registerContest(key, setLoading))
  };
};
