import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector, ParametricSelector } from 'reselect';

import {
  ProblemState,
  ProblemsState,
  ProblemNewState
} from '../store/types';
import { loadAllProblems, createNewProblem, loadProblem, updateProblem, loadProblemStatus } from '../store/actions';
import { RootState } from '../store/reducers';
import { LoaderAction } from '../commons/types';

const selectProblemsStates: ParametricSelector<RootState, undefined, { problemsDataList: Array<ProblemState> }> =
  createSelector<RootState, ProblemsState, Array<string>, { problemsDataList: Array<ProblemState> }>(
    state => state.problems,
    state => state.problemsList,
    (problems, problemsList) => ({ problemsDataList: problemsList.map(id => problems[id]) })
  );

export const useProblemsState = () => {
  return useSelector((state: RootState) => selectProblemsStates(state, undefined));
};

const selectProblemState: ParametricSelector<RootState, string, ProblemState> =
  createSelector<RootState, string, ProblemState, ProblemState>(
    (state, key: any) => state.problems[key],
    (problem) => problem
  );

export const useProblemState = (key: string) => {
  return useSelector((state: RootState) => selectProblemState(state, key));
};

export const useProblemsDispatch = () => {
  const dispatch = useDispatch();
  return {
    loadProblems: useCallback((search: string, setLoading: LoaderAction) => async () => await dispatch(loadAllProblems(search, setLoading)), [dispatch]),
    loadProblem: useCallback((key: string, setLoading: LoaderAction) => async () => await dispatch(loadProblem(key, setLoading)), [dispatch]),
    createNewProblem: (problem: ProblemNewState, setLoading: LoaderAction) => () => dispatch(createNewProblem(problem, setLoading)),
    updateProblem: (problem: ProblemState, setLoading: LoaderAction) => () => dispatch(updateProblem(problem, setLoading)),
    loadProblemStatus: useCallback((id: string, setLoader: LoaderAction, search: string, clean: boolean) => () => {
      dispatch(loadProblemStatus(id, setLoader, search, clean));
    }, [dispatch])
  };
};
