import { UserActions, UsersActionTypes, UserState } from '../types';

export const users = (state: Array<UserState> = [], action: UsersActionTypes) => {
  switch (action.type) {
    case UserActions.REPLACE_USERS:
      action.users.forEach((user) => {
        const passPermissions: { [key: string]: string } = {};
        user.permissions?.forEach((permission) => {
          passPermissions[permission.key] = permission.value;
        });
        user.passPermissions = passPermissions;
      });
      return [...action.users];
    default:
      return state;
  }
};
