import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'antd';
import { ArrowDownOutlined, ArrowUpOutlined, FileDoneOutlined } from '@ant-design/icons';

import { ACCEPTED_PROGRAMMING_LANGUAGES, DEFAULT_JUKI_PROFILE_IMAGE, LOADING, PROGRAMMING_LANGUAGES, ROUTES, VERDICT } from '../../../../../config/constants';
import { LineLoader, ResizeTable } from '../../../../index';
import { ProblemVerdict, ProfileTab, ProgrammingLanguage } from '../../../../../types';
import { ViewSourceCodeModal } from './ViewSourceCodeModal';
import { useAccountState, useContestState } from '../../../../../hooks';
import { BASE_URL } from '../../../../../services';
import { classNames, dateToDDMMYYY, dateToHHMM } from '../../../../../helpers';
import { DataIndexSubmission, RowDataTypes, SubmissionModal, SubmissionsTableProps } from './types';

import './styles.scss';
import { ColumnsType } from 'antd/lib/table/interface';

const hasWho = (x: any): x is { [DataIndexSubmission.WHO]: { nickname: string } } => {
  return true;
};

const hasProblem = (x: any): x is { [DataIndexSubmission.PROBLEM]: { index: string } } => {
  return true;
};

export const SubmissionsTable = <T extends RowDataTypes, >({ data, loading, contestKey, head }: SubmissionsTableProps<T>) => {
    
    const [modal, setModal] = useState<SubmissionModal>({ type: 0 });
    const { isLogged } = useAccountState();
    const closeModal = () => setModal({ type: 0 });
    const { t } = useTranslation();
    const { problems } = useContestState(contestKey || '');
    
    const columns: ColumnsType<T> = [];
    if (head[DataIndexSubmission.WHO]) {
      columns.push({
        title: ({ sortOrder, sortColumn }: { sortOrder: string, sortColumn: { dataIndex: DataIndexSubmission } }) => (
          <div className="cell-head-who">
            {t('nickname')}
            {sortColumn?.dataIndex === DataIndexSubmission.WHO && (
              sortOrder === 'ascend' ? <ArrowUpOutlined /> : sortOrder === 'descend' ? <ArrowDownOutlined /> : null
            )}
          </div>
        ),
        dataIndex: DataIndexSubmission.WHO,
        width: 220,
        render: ({ nickname, imageUrl }: { nickname: string, imageUrl: string }) => (
          <div className="cell-body-who cell-body-left">
            <div className="user-photo text-l semi-bold"><img src={imageUrl || DEFAULT_JUKI_PROFILE_IMAGE} alt="user-profile" />
              <a
                href={[BASE_URL, ROUTES.PARAMS.PROFILE, nickname, ProfileTab.PROFILE].join('/')}
                target="_blank"
                rel="noopener noreferrer"
              >
                {nickname}
              </a>
            </div>
          </div>
        ),
        showSorterTooltip: false,
        sorter: (a: T, b: T) => hasWho(a) && hasWho(b) ? a[DataIndexSubmission.WHO].nickname.localeCompare(b[DataIndexSubmission.WHO].nickname) : 0
      });
    }
    if (head[DataIndexSubmission.PROBLEM]) {
      columns.push({
        title: ({ sortOrder, sortColumn }: { sortOrder: string, sortColumn: { dataIndex: DataIndexSubmission } }) => (
          <div className="cell-head-left">
            {t('problem name')}
            {sortColumn?.dataIndex === DataIndexSubmission.PROBLEM && (
              sortOrder === 'ascend' ? <ArrowUpOutlined /> : sortOrder === 'descend' ? <ArrowDownOutlined /> : null
            )}
          </div>
        ),
        dataIndex: DataIndexSubmission.PROBLEM,
        width: 210,
        render: ({ name, index, link }: { name: string, index: string, link: string }) => (
          <div className="cell-body-link cell-body-left bold">
            <Link to={link}>
              {index && <span className="cell-body-title-name-index">{index}</span>}
              {name}
            </Link>
          </div>
        ),
        showSorterTooltip: false,
        filters: Object.keys(problems || {}).map(problem => ({ text: problem, value: problem })),
        onFilter: (value: string | number | boolean, record: T) => hasProblem(record) && record[DataIndexSubmission.PROBLEM].index === value
      });
    }
    if (head[DataIndexSubmission.WHEN]) {
      columns.push({
        title: ({ sortOrder, sortColumn }: { sortOrder: string, sortColumn: { dataIndex: DataIndexSubmission } }) => (
          <div className="cell-head-when">
            {t('date')}
            {sortColumn?.dataIndex === DataIndexSubmission.WHEN && (
              sortOrder === 'ascend' ? <ArrowUpOutlined /> : sortOrder === 'descend' ? <ArrowDownOutlined /> : null
            )}
          </div>
        ),
        dataIndex: DataIndexSubmission.WHEN,
        render: (when: number) => {
          return (
            <div className="cell-body-when">
              <div className="date-style text-s bold">{dateToDDMMYYY(new Date(when))}</div>
              <div className="hour-style">{dateToHHMM(new Date(when))}</div>
            </div>
          );
        },
        width: 120,
        showSorterTooltip: false,
        sorter: (a: T, b: T) => a[DataIndexSubmission.WHEN] - b[DataIndexSubmission.WHEN]
      });
    }
    if (head[DataIndexSubmission.VERDICT]) {
      columns.push({
        title: () => <div className="cell-head-verdict">{t('verdict')}</div>,
        dataIndex: DataIndexSubmission.VERDICT,
        render: ({ verdict, points }: { verdict: ProblemVerdict, points: number }) => (
          <div className="cell-body-verdict">
            <Tooltip
              title={t(VERDICT[verdict]?.print || verdict) + (verdict === ProblemVerdict.PA ? ` (${points})` : '')}>
              <div className={classNames('verdict-tag-layout bold', VERDICT[verdict]?.value || verdict)}>
                {VERDICT[verdict]?.value || verdict}
                {verdict === ProblemVerdict.PA && <span className="verdict-tag-points text-s semi-bold">({points})</span>}
              </div>
            </Tooltip>
          </div>
        ),
        width: 120,
        showSorterTooltip: false,
        filters: Object.values(VERDICT).map(verdict => ({ text: t(verdict.print), value: verdict.value })),
        onFilter: (value: ProblemVerdict | string | number | boolean, record: T) => record[DataIndexSubmission.VERDICT].verdict === value
      });
    }
    if (head[DataIndexSubmission.LANGUAGE]) {
      columns.push({
        title: () => <div className="cell-head-language">{t('language')}</div>,
        dataIndex: DataIndexSubmission.LANGUAGE,
        render: (text: ProgrammingLanguage) => (
          <div
            className="cell-body-language">{PROGRAMMING_LANGUAGES[text] ? PROGRAMMING_LANGUAGES[text].name : `[${text}]`}</div>
        ),
        width: 120,
        showSorterTooltip: false,
        filters: ACCEPTED_PROGRAMMING_LANGUAGES.map(language => ({
          text: PROGRAMMING_LANGUAGES[language].name,
          value: language
        })),
        onFilter: (value: ProgrammingLanguage | string | number | boolean, record: T) => record[DataIndexSubmission.LANGUAGE] === value
      });
    }
    if (head[DataIndexSubmission.TIME_USED]) {
      columns.push({
        title: ({ sortOrder, sortColumn }: { sortOrder: string, sortColumn: { dataIndex: DataIndexSubmission } }) => (
          <div>
            {t('time used')}
            {sortColumn?.dataIndex === DataIndexSubmission.TIME_USED && (
              sortOrder === 'ascend' ? <ArrowUpOutlined /> : sortOrder === 'descend' ? <ArrowDownOutlined /> : null
            )}
          </div>
        ),
        dataIndex: DataIndexSubmission.TIME_USED,
        render: (text: number) => (
          <div className="cell-body-center text-l"><span className="constant-text text-m bold">{text}</span> ms</div>
        ),
        width: 140,
        showSorterTooltip: false,
        sorter: (a: T, b: T) => a[DataIndexSubmission.TIME_USED] - b[DataIndexSubmission.TIME_USED]
      });
    }
    if (head[DataIndexSubmission.MEMORY_USED]) {
      columns.push({
        title: ({ sortOrder, sortColumn }: { sortOrder: string, sortColumn: { dataIndex: DataIndexSubmission } }) => (
          <div>
            {t('memory used')}
            {sortColumn?.dataIndex === DataIndexSubmission.MEMORY_USED && (
              sortOrder === 'ascend' ? <ArrowUpOutlined /> : sortOrder === 'descend' ? <ArrowDownOutlined /> : null
            )}
          </div>
        ),
        dataIndex: DataIndexSubmission.MEMORY_USED,
        render: (text: number) => (
          <div className="cell-body-center text-l"><span className="constant-text text-m bold">{text}</span> KB</div>
        ),
        width: 140,
        showSorterTooltip: false,
        sorter: (a: T, b: T) => a[DataIndexSubmission.MEMORY_USED] - b[DataIndexSubmission.MEMORY_USED]
      });
    }
    if (isLogged && head[DataIndexSubmission.CODE]) {
      columns.push({
        title: <div className="cell-head-code">{t('code')}</div>,
        dataIndex: DataIndexSubmission.CODE,
        render: (submission) => (
          <div className="cell-body-link">
            {submission && (
              <img
                className="code-column"
                onClick={() => setModal({ type: 1, submission })}
                src="/images/code-24px.svg" alt="code"
              />
            )}
          </div>
        ),
        width: 56
      });
    }
    
    if (head[DataIndexSubmission.OPS]) {
      columns.push({
        title: <div className="cell-head-ops"> OPS </div>,
        dataIndex: DataIndexSubmission.OPS,
        render: (cell) => cell(),
        width: 80
      });
    }
    
    if (head[DataIndexSubmission.JUDGE]) {
      columns.push({
        title: <div className="cell-head-judge"> JUDGE </div>,
        dataIndex: DataIndexSubmission.JUDGE,
        render: ({ submission, canUpdate }) => (
          <div className="cell-body-center cell-body-link">
            {canUpdate || !submission.manualJudged ?
              <FileDoneOutlined onClick={() => setModal({ type: 2, submission })} /> :
              <img
                className="code-column"
                onClick={() => setModal({ type: 1, submission })}
                src="/images/code-24px.svg" alt="code"
              />}
          </div>
        ),
        width: 80
      });
    }
    
    return (
      <>
        {!!modal.type && (
          <ViewSourceCodeModal
            closeModal={closeModal}
            data={modal}
          />
        )}
        {loading[1] === LOADING && data.length > 0 && <LineLoader />}
        <ResizeTable
          className="submissions-layout"
          columns={columns}
          dataSource={data}
          loading={loading[1] === LOADING && data.length === 0}
        />
      </>
    );
  }
;
