import React from 'react';
import { DndProvider } from 'react-dnd';
import { Switch } from 'antd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { ProblemSelector } from '~/components';
import { ContestNewState, ContestProblem } from '~/store/types';
import { PALLETE } from '~/config/constants';
import { letterIndex, mex, numberIndex } from '~/helpers';
import { RowTableProblems } from './RowTableProblems';
import { ReactComponent as DragIndicator } from '~/graphics/drag-indicator.svg';
import { ReactComponent as Balloon } from '~/graphics/balloon.svg';
import { ContestCreateProblemsProps } from './types';
import { ContestSettingsParams, ProblemInput, ProblemMode, ProblemOutput } from '~/types';

import './styles.scss';

export const ContestCreateProblems = ({ contestNew, setContestNew }: ContestCreateProblemsProps) => {
  const isFutureContest = new Date().getTime() < contestNew.settings.start;
  return (
    <>
      <div className="add-contest-search-problems">
        <ProblemSelector
          selectProblem={(problems) => {
            setContestNew(prevState => {
              const newProblems: { [key: string]: ContestProblem } = { ...prevState.problems };
              for (const problem of problems) {
                const valuesNewProblems = Object.values(newProblems);
                if (!valuesNewProblems.find((_problem) => _problem.judge === problem.judge && _problem.id === problem.id)) {
                  let colors = PALLETE.VIVOS.filter(color => !valuesNewProblems.some(p => p.color === color.color));
                  if (!colors.length) {
                    colors = [
                      ...PALLETE.OSCUROS.filter(color => !valuesNewProblems.some(p => p.color === color.color)),
                      ...PALLETE.AGRISADOS.filter(color => !valuesNewProblems.some(p => p.color === color.color)),
                      ...PALLETE.CLAROS.filter(color => !valuesNewProblems.some(p => p.color === color.color))
                    ];
                  }
                  const newIndex = letterIndex(mex(valuesNewProblems.map(problem => numberIndex(problem.index))));
                  newProblems[newIndex] = {
                    index: newIndex,
                    judge: problem.judge,
                    myPoints: -1,
                    id: problem.id,
                    link: problem.url,
                    successRate: 0,
                    name: problem.name,
                    points: 1,
                    start: 0,
                    duration: contestNew.timing.duration,
                    color: colors.length ? colors[Math.floor(Math.random() * colors.length)].color : '#000000',
                    description: { general: '', input: '', output: '' },
                    samples: [],
                    settings: {
                      timeLimit: 0,
                      memoryLimit: 0,
                      languages: [],
                      mode: ProblemMode.TOTAL,
                      typeInput: ProblemInput.STANDARD,
                      typeOutput: ProblemOutput.STANDARD,
                      groupsPoint: {}
                    },
                    active: true
                  };
                }
              }
              return {
                ...prevState,
                problems: newProblems
              };
            });
          }}
        />
      </div>
      <div className="content-problem-time-restriction">
        <div>
          <div className="text-m semi-bold">
            {contestNew.settings[ContestSettingsParams.LIMIT_PROBLEM_TIME] ?
              <>Problems <span className="text-m bold color-primary">with</span> period time restriction</> :
              <>Problems <span className="text-m bold color-primary">without</span> period time restriction</>
            }
          </div>
          <Switch
            checked={contestNew.settings[ContestSettingsParams.LIMIT_PROBLEM_TIME]}
            onChange={(value) => setContestNew(prevState => {
              const newProblems = { ...prevState.problems };
              Object.keys(newProblems).forEach(index => {
                newProblems[index] = { ...newProblems[index], start: 0, duration: contestNew.timing.duration };
              });
              return ({
                ...prevState,
                settings: { ...prevState.settings, [ContestSettingsParams.LIMIT_PROBLEM_TIME]: value },
                problems: newProblems
              });
            })}
          />
        </div>
        {contestNew.settings[ContestSettingsParams.LIMIT_PROBLEM_TIME] ?
          <> Set a the time start relative to start of contest and the duration to resolve for each problem:</> : null}
      </div>
      <div className="content-create-contest-problems-table shadow">
        <div className="add-contest-table-problems-row-title text-s bold">
          {isFutureContest && <div className="cell-drag"><DragIndicator /></div>}
          <div className="cell-number">#</div>
          <div className="cell-name">Name</div>
          <div className="cell-color"><Balloon color="white" /></div>
          <div className="cell-points">Points</div>
          <div className="cell-judge">Judge</div>
          {contestNew.settings[ContestSettingsParams.LIMIT_PROBLEM_TIME] && (
            <div className="cell-start">Start (mn)</div>
          )}
          {contestNew.settings[ContestSettingsParams.LIMIT_PROBLEM_TIME] && (
            <div className="cell-duration">Duration (mn)</div>
          )}
          <div className="cell-delete" />
        </div>
        <DndProvider backend={HTML5Backend}>
          {Object.values(contestNew.problems).map((problem, index) => {
            return (
              <RowTableProblems
                isFutureContest={isFutureContest}
                contest={contestNew}
                problem={problem}
                index={index}
                setContest={setContestNew}
                key={problem.link}
                moveProblems={(dragIndex, hoverIndex) => {
                  setContestNew((prevState: ContestNewState) => {
                    const newProblems = { ...prevState.problems };
                    const problemBackup = { ...prevState.problems[letterIndex(dragIndex + 1)] };
                    newProblems[letterIndex(dragIndex + 1)] = { ...prevState.problems[letterIndex(hoverIndex + 1)] };
                    newProblems[letterIndex(hoverIndex + 1)] = { ...problemBackup };
                    const newDraggedProblems: { [key: string]: ContestProblem } = {};
                    Object.values(newProblems).forEach((problem, index) => {
                      newDraggedProblems[letterIndex(index + 1)] = { ...problem, index: letterIndex(index + 1) };
                    });
                    return { ...prevState, problems: newDraggedProblems };
                  });
                }}
              />
            );
          })}
        </DndProvider>
      </div>
    </>
  );
};
