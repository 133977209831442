
import { API, authorizedFormDataRequest, PUT } from '../server.config';
import { ErrorResponse, OkObjectResponse } from '../../commons/types';
import { clean } from './index';

export const apiUploadFile = (name: string, tags: Array<string>, blob: Blob) => async (): Promise<ErrorResponse | OkObjectResponse<{fileUrl: string}>> => {

  const formData = new FormData();
  formData.append('name', name);
  formData.append('tags', tags.join(','));
  formData.append('file', blob);

  return clean(await authorizedFormDataRequest(API.UPLOAD(), PUT, formData));
}
