import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { Button, Input, Modal, Select } from 'antd';
import { EditOutlined, SaveOutlined } from '@ant-design/icons';

import { JukiLayout, Loader, ResizeTable } from '~/components/shared';
import { LOADING, NONE, USER_STATUS } from '~/config/constants';
import { useAccountState, useUsersDispatch, useUsersState } from '~/hooks';
import { AccountStatus, LoaderState } from '~/types';
import { can } from '~/helpers';

const { Option } = Select;

const Permissions = ({ permissions, nickname }: { permissions: Array<{ key: string, value: string }>, nickname: string }) => {
  
  const account = useAccountState();
  const { changePermissionsUser } = useUsersDispatch();
  const [editing, setEditing] = useState(false);
  const [newPermissions, setNewPermissions] = useState<Array<{ key: string, value: string }>>(permissions);
  const [loader, setLoader] = useState<LoaderState>([new Date().getTime(), NONE]);
  
  return (
    <div>
      <div className="permissions-cell">
        <div className="permissions-box">
          {newPermissions.map((permission, index) => (
            <div className="permission" key={permission.key}>
              <div className="label-permission">{permission.key}:</div>
              {editing ?
                permission.value.split('').map((value, indexV) => (
                  <Input
                    className="value-i"
                    value={value}
                    onChange={({ target }) => {
                      setNewPermissions(prevState => {
                        const newState = [...prevState];
                        const newValue = permission.value.split('');
                        newValue[indexV] = !Number.isNaN(+target.value) && +target.value > 0 && +target.value < 10 ? +target.value + '' : '0';
                        newState[index] = { key: permission.key, value: newValue.join('') };
                        return newState;
                      });
                    }}
                    width={1}
                  />
                ))
                : <div className="value-permission">{permission.value}</div>}
            </div>
          ))}
        </div>
        {can.updatePermissionsUser(account) && (
          <div className="editing-actions-box">
            {editing ? (
              <Button
                loading={loader[1] === LOADING}
                type="text"
                icon={<SaveOutlined />}
                onClick={() => {
                  changePermissionsUser(nickname, newPermissions, setLoader)();
                  setEditing(false);
                }}
              />
            ) : <Button type="text" icon={<EditOutlined />} onClick={() => setEditing(true)} />}
          </div>
        )}
      </div>
    </div>
  );
};

export const Users = () => {
  
  const account = useAccountState();
  const { loadAllUsers, changeStatusUser } = useUsersDispatch();
  const { users } = useUsersState();
  const [loading, setLoading] = useState<LoaderState>([new Date().getTime(), NONE]);
  const { search } = useLocation();
  useEffect(() => {
    loadAllUsers(search, setLoading)();
  }, [loadAllUsers, search]);
  
  const columns = [
    {
      title: () => <div>Name</div>,
      dataIndex: 'name',
      render: ({ givenName, familyName }: { givenName: string, familyName: string }) => {
        return (
          <div>{givenName + ' ' + familyName}</div>
        );
      }
    },
    {
      title: () => <div>email</div>,
      dataIndex: 'email',
      render: (email: string) => (
        <div>{email}</div>
      )
    },
    {
      title: () => <div>permissions</div>,
      dataIndex: 'permissions',
      render: ({ permissions, nickname }: { permissions: [{ key: string, value: string }], nickname: string }) => (
        <Permissions permissions={permissions} nickname={nickname} />
      ),
      width: '220px'
    },
    {
      title: <div>nickname</div>,
      dataIndex: 'nickname',
      render: (nickname: string) => (
        <div className="memory-used-column">{nickname}</div>
      )
    },
    {
      title: <div>status</div>,
      key: 'status',
      dataIndex: 'status',
      render: ({ nickname, status }: { nickname: string, status: AccountStatus }) => (
        can.updateStatusUser(account) ?
          <div className="cell-delete">
            <Select
              value={status}
              style={{ width: '110px' }}
              onSelect={value => {
                Modal.confirm({
                  onOk: changeStatusUser(nickname, value, setLoading),
                  content: (
                    <>
                      Are you sure to
                      {value === AccountStatus.ACTIVE ? ' active ' : value === AccountStatus.REPORTED ? ' report ' : ' archive '}
                      the user?
                    </>
                  ),
                  okText: 'Yes',
                  cancelText: 'Cancel'
                });
              }}
            >
              <Option value={AccountStatus.ACTIVE}>{USER_STATUS[AccountStatus.ACTIVE]}</Option>
              <Option value={AccountStatus.REPORTED}>{USER_STATUS[AccountStatus.REPORTED]}</Option>
              <Option value={AccountStatus.ARCHIVED}>{USER_STATUS[AccountStatus.ARCHIVED]}</Option>
            </Select>
          </div> :
          <div>
            {status}
          </div>
      )
    }
  ];
  
  const data = users.map(user => (
    {
      key: `nickname-(${user.nickname + ')-' + user.status}`,
      name: { givenName: user.givenName, familyName: user.familyName },
      email: user.email,
      permissions: { permissions: user.permissions, nickname: user.nickname },
      nickname: user.nickname,
      status: { nickname: user.nickname, status: user.status }
    }
  ));
  return (
    <Loader
      loading={loading[1] === LOADING}
      component={() => {
        return (
          <>
            <JukiLayout type="title">
              <h3>Users</h3>
            </JukiLayout>
            <JukiLayout/* className="full-width"*/>
              <ResizeTable className="users-table-layout" columns={columns} dataSource={data} />
            </JukiLayout>
          </>
        );
      }}
    />
  );
};
