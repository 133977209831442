import React, { ReactNode } from 'react';

import './Card.scss';

export const Card = ({ contentHead, contentTitle, contentInfo, contentAction, style }:
                       { contentHead?: ReactNode, contentTitle?: ReactNode, contentInfo?: ReactNode, contentAction?: ReactNode, style?: Object }) => {
  return (
    <div className="layout-card shadow juki-card" style={style}>
      <div className="content-head child-center">
        {contentHead}
      </div>
      <div className="content-title child-center">
        {contentTitle}
      </div>
      <div className="content-info">
        {contentInfo}
      </div>
      <div className="content-action child-center">
        {contentAction}
      </div>
    </div>
  );
}
