import { ReactNode } from 'react';

import { LoaderState, ProblemVerdict, ProgrammingLanguage } from '~/types';
import { ContestPendingSubmission, ContestSubmission, SubmissionProfile } from '~/store/types';

export enum DataIndexSubmission {
  KEY = 'key',
  WHO = 'WHO',
  PROBLEM = 'PROBLEM',
  WHEN = 'WHEN',
  VERDICT = 'VERDICT',
  LANGUAGE = 'LANGUAGE',
  TIME_USED = 'TIME_USED',
  MEMORY_USED = 'MEMORY_USED',
  CODE = 'CODE',
  OPS = 'OPS',
  JUDGE = 'JUDGE',
}

export type RowDataSubmission = {
  [DataIndexSubmission.KEY]: string,
  [DataIndexSubmission.WHO]: { nickname: string, imageUrl: string },
  [DataIndexSubmission.PROBLEM]: { index: string, name: string, link: string },
  [DataIndexSubmission.WHEN]: number,
  [DataIndexSubmission.VERDICT]: { verdict: ProblemVerdict, points: number },
  [DataIndexSubmission.LANGUAGE]: ProgrammingLanguage,
  [DataIndexSubmission.TIME_USED]: number,
  [DataIndexSubmission.MEMORY_USED]: number,
  [DataIndexSubmission.CODE]: ContestSubmission | null,
  [DataIndexSubmission.OPS]: () => ReactNode
}

export type RowDataMySubmission = {
  [DataIndexSubmission.KEY]: string,
  [DataIndexSubmission.PROBLEM]: { index: string, name: string, link: string },
  [DataIndexSubmission.WHEN]: number,
  [DataIndexSubmission.VERDICT]: { verdict: ProblemVerdict, points: number },
  [DataIndexSubmission.LANGUAGE]: ProgrammingLanguage,
  [DataIndexSubmission.TIME_USED]: number,
  [DataIndexSubmission.MEMORY_USED]: number,
  [DataIndexSubmission.CODE]: ContestSubmission
}

export type RowDataPendingSubmission = {
  [DataIndexSubmission.KEY]: string,
  [DataIndexSubmission.PROBLEM]: { index: string, name: string, link: string },
  [DataIndexSubmission.WHEN]: number,
  [DataIndexSubmission.VERDICT]: { verdict: ProblemVerdict, points: number },
  [DataIndexSubmission.LANGUAGE]: ProgrammingLanguage,
  [DataIndexSubmission.TIME_USED]: number,
  [DataIndexSubmission.MEMORY_USED]: number,
  [DataIndexSubmission.JUDGE]: { submission: ContestPendingSubmission, canUpdate: boolean }
}

export type RowDataProblemSubmission = {
  [DataIndexSubmission.KEY]: string,
  [DataIndexSubmission.WHO]: { nickname: string, imageUrl: string },
  [DataIndexSubmission.WHEN]: number,
  [DataIndexSubmission.VERDICT]: { verdict: ProblemVerdict, points: number },
  [DataIndexSubmission.LANGUAGE]: ProgrammingLanguage,
  [DataIndexSubmission.TIME_USED]: number,
  [DataIndexSubmission.MEMORY_USED]: number,
  [DataIndexSubmission.CODE]: ContestSubmission | null
}
export type RowDataTypes = RowDataProblemSubmission | RowDataMySubmission | RowDataPendingSubmission | RowDataSubmission;

export interface SubmissionsTableProps<T extends RowDataTypes> {
  data: Array<T>,
  loading: LoaderState,
  contestKey?: string,
  noWho?: boolean,
  noProblem?: boolean,
  noCode?: boolean,
  head: { [key: string]: boolean }
}

type ModalA = {
  type: 0
}

export type SubmissionModalView = {
  type: 1,
  submission: SubmissionProfile
}

export type SubmissionModalJudge = {
  type: 2,
  submission: SubmissionProfile
}

export type SubmissionModal = ModalA | SubmissionModalView | SubmissionModalJudge;
