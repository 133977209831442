import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import React from 'react';

import { LoaderProps } from './types';

import './styles.scss';

export const LoaderIcon = () => {
  return (
    <div className="juki-loader child-center">
      <Spin className="juki-spin child-center" indicator={<LoadingOutlined spin />} />
    </div>
  );
};

export const Loader = ({ loading, component }: LoaderProps): JSX.Element => {
  return loading ? <LoaderIcon /> : component();
};

export * from './types';
