import { AccountActions, AccountState, AccountStateFetch, SubmissionProfile } from '../types';
import {
  actionLoaderWrapper,
  apiGoogleLoginUser,
  apiLoginUser,
  apiPing,
  apiRecoverPassword,
  apiSignUpUser,
  apiUserMySubmissions,
  apiUserUpdate,
  apiUserUpdateImageUser,
  apiUserUpdatePasswordUser
} from '~/services';
import {
  LoaderAction,
  NotificationType,
  PagedArray,
  ProfileSettingOptions, ScopeData
} from '~/types';
import {
  ErrorNotification,
  InfoNotification,
  SuccessNotification
} from '~/components';
import { API, authorizedRequest, POST } from '~/services';
import {
  PAGED_ARRAY_EMPTY,
  DEFAULT_PERMISSIONS,
  DEFAULT_SETTINGS,
  USER_GUEST,
  LOADING,
  SUCCESS,
  ERROR
} from '~/config/constants';
import { RootState } from '../reducers';
import { fillPageParams, isJson, objectOfSearch } from '~/helpers';
import { clearRedux, updateFlags } from './index';

export const replaceAccount = (account: AccountState) => {
  return {
    type: AccountActions.UPDATE_ACCOUNT,
    account
  };
};

const convert = (object: AccountStateFetch) => {
  const permissions: { [key in ScopeData]: string } = { ...DEFAULT_PERMISSIONS };
  object?.permissions?.forEach((permission) => {
    permissions[permission.key] = permission.value;
  });
  const mySettings: { [key in ProfileSettingOptions]: string } = { ...DEFAULT_SETTINGS };
  object?.settings?.forEach(setting => {
    if (setting.value) {
      mySettings[setting.key] = setting.value;
    }
  });
  return { permissions, mySettings };
};

export const ping = () => {
  return async (dispatch: Function, getState: () => RootState) => {
    const result = await apiPing();
    if (result.success === SUCCESS) {
      dispatch(replaceAccount({ ...result.object, ...convert(result.object), isLogged: true }));
    } else {
      const language = localStorage.getItem(ProfileSettingOptions.LANGUAGE);
      if (language) {
        const account = getState().account;
        dispatch(replaceAccount({
          ...account,
          mySettings: { ...account.mySettings, [ProfileSettingOptions.LANGUAGE]: language }
        }));
      }
    }
  };
};

export const signIn = (nickname: string, password: string, setLoader: LoaderAction) => {
  return async (dispatch: Function) => {
    await actionLoaderWrapper(
      apiLoginUser(nickname, password),
      dispatch,
      (result) => {
        dispatch(replaceAccount({ ...result.object, ...convert(result.object), isLogged: true }));
      },
      setLoader
    );
  };
};

export const googleSignIn = (tokenId: string, setLoader?: LoaderAction) => {
  return async (dispatch: Function) => {
    await actionLoaderWrapper(
      apiGoogleLoginUser(tokenId),
      dispatch,
      (result) => {
        dispatch(replaceAccount({ ...result.object, ...convert(result.object), isLogged: true }));
      },
      setLoader
    );
  };
};

export const recoverAccount = (email: string, setLoader?: LoaderAction) => {
  return async (dispatch: Function) => {
    await actionLoaderWrapper(
      apiRecoverPassword(email),
      dispatch,
      (result) => {
        if (result.object.success) {
          dispatch(updateFlags({
            lastNotification: {
              type: NotificationType.SUCCESS,
              description: result.object.message,
              duration: 7
            },
            openLoginModal: false,
            openSignUpModal: false
          }));
        } else {
          dispatch(updateFlags({
            lastNotification: {
              type: NotificationType.WARNING,
              description: result.object.message,
              duration: 7
            }
          }));
        }
      },
      setLoader
    );
  };
};

export const signUp = (givenName: string, familyName: string, nickname: string, email: string, password: string, setLoader: LoaderAction) => {
  return async (dispatch: Function) => {
    await actionLoaderWrapper(
      apiSignUpUser(givenName, familyName, nickname, email, password),
      dispatch,
      (result) => {
        dispatch(replaceAccount({ ...result.object, ...convert(result.object), isLogged: true }));
        dispatch(updateFlags({ openWelcomeModal: true }));
      },
      setLoader
    );
  };
};

export const logout = (setLoader: LoaderAction) => {
  return async (dispatch: Function) => {
    setLoader([1, LOADING]);
    const response = await authorizedRequest(API.ACCOUNT.LOGOUT(), POST);
    if (isJson(response)) {
      const result = JSON.parse(response); // special endpoint that only return success
      if (result.success === true) {
        setLoader([1, SUCCESS]);
        InfoNotification({ description: 'See you' });
      } else {
        setLoader([1, ERROR]);
        ErrorNotification({ description: 'Force Logout [' + result.message + ']' });
      }
    } else {
      setLoader([1, ERROR]);
      ErrorNotification({ description: 'Force Logout' });
    }
    dispatch(clearRedux());
  };
};

export const updateAccountImageProfile = (blob: Blob, setLoader: LoaderAction) => {
  return async (dispatch: Function) => {
    await actionLoaderWrapper(
      apiUserUpdateImageUser(blob),
      dispatch,
      (result) => {
        dispatch(replaceAccount({ ...result.object, ...convert(result.object), isLogged: true }));
        SuccessNotification({ description: 'Image update successfully' });
      },
      setLoader
    );
  };
};

export const updateAccountPasswordProfile = (oldPassword: string, newPassword: string, setLoader: LoaderAction) => {
  return async (dispatch: Function) => {
    await actionLoaderWrapper(
      apiUserUpdatePasswordUser(oldPassword, newPassword),
      dispatch,
      (result) => {
        SuccessNotification({ description: 'password updated successfully' });
      },
      setLoader
    );
  };
};

export const loadUserMySubmissions = (search: string, setLoader: LoaderAction, clean: boolean) => {
  return async (dispatch: Function, getState: () => RootState) => {
    if (clean) {
      dispatch(replaceAccount({ submissions: { ...PAGED_ARRAY_EMPTY } as PagedArray<SubmissionProfile> } as AccountState));
    }
    await actionLoaderWrapper(
      async () => {
        if (!getState().flags.requestingApiProfileSubmissions || clean) {
          const now = new Date().getTime();
          dispatch(updateFlags({ requestingApiProfileSubmissions: now }));
          const result = await apiUserMySubmissions(fillPageParams(objectOfSearch(search)))();
          if (getState().flags.requestingApiProfileSubmissions === now) {
            dispatch(updateFlags({ requestingApiProfileSubmissions: 0 }));
          }
          return result;
        }
      },
      dispatch,
      (result) => {
        if (result) {
          dispatch(replaceAccount({
            submissions: {
              list: result.object.content,
              pageNumber: result.object.number,
              pageSize: result.object.size,
              totalPages: result.object.totalPages,
              totalElements: result.object.totalElements
            }
          } as AccountState));
        }
      },
      setLoader
    );
  };
};

export const updateAccountSettings = (account: AccountState, setLoader: LoaderAction) => {
  return async (dispatch: Function) => {
    const accountBody: AccountState = JSON.parse(JSON.stringify(account));
    // @ts-ignore
    delete accountBody.submissions;
    // @ts-ignore
    delete accountBody.mySettings;
    // @ts-ignore
    delete accountBody.permissions;
    return await actionLoaderWrapper(
      apiUserUpdate(accountBody),
      dispatch,
      (result) => {
        SuccessNotification({ title: 'Updated', description: 'Your personal information has been updated' });
        dispatch(replaceAccount({ ...USER_GUEST, ...result.object, ...convert(result.object), isLogged: true }));
      },
      setLoader,
      (result) => {
        ErrorNotification({ description: result.message });
      }
    );
  };
};
