import React from 'react';
import { Link, Redirect, useParams } from 'react-router-dom';
import { InfoCircleOutlined } from '@ant-design/icons';

import { LayoutMain, LayoutSideBar, LayoutSideMain, LayoutTitle } from '../shared';
import { ADMIN_TAB, ROUTES } from '../../config/constants/routes';
import { useAccountState } from '../../hooks';
import { Users } from './Users';
import { AdminTab } from '../../commons/types';

import './styles.scss';
import { can } from '~/helpers';

export const Admin = () => {
  
  const { tab } = useParams<{ tab: AdminTab }>();
  const account = useAccountState();
  
  const canUpdateSomethingFromUsers = (can.createUser(account) || can.updateStatusUser(account) || can.updatePermissionsUser(account) ||
    can.viewAllUsers(account) || can.viewActiveUsers(account));
  
  if (tab === AdminTab.USERS && !canUpdateSomethingFromUsers) {
    return <Redirect to={'/'} />;
  }
  
  return (
    <>
      <LayoutSideBar>
        <LayoutMain>
          <div className="juki-menu-side-bar">
            {canUpdateSomethingFromUsers && (
              <Link to={ROUTES.ADMIN.PAGE(AdminTab.USERS)}>
                <div className={'item-sub-menu' + (tab === AdminTab.USERS ? ' item-selected' : '')}>
                  <InfoCircleOutlined /> {ADMIN_TAB[AdminTab.USERS].print}
                </div>
              </Link>
            )}
            <Link to={ROUTES.ADMIN.PAGE(AdminTab.ATTEMPTS)}>
              <div className={'item-sub-menu' + (tab === AdminTab.ATTEMPTS ? ' item-selected' : '')}>
                <InfoCircleOutlined /> {ADMIN_TAB[AdminTab.ATTEMPTS].print}
              </div>
            </Link>
            <Link to={ROUTES.ADMIN.PAGE(AdminTab.REJUDGE)}>
              <div className={'item-sub-menu' + (tab === AdminTab.REJUDGE ? ' item-selected' : '')}>
                <InfoCircleOutlined /> {ADMIN_TAB[AdminTab.REJUDGE].print}
              </div>
            </Link>
          </div>
        </LayoutMain>
      </LayoutSideBar>
      <LayoutSideMain className="admin-layout">
        {tab === AdminTab.USERS && <Users />}
        {tab === AdminTab.ATTEMPTS && (
          <>
            <LayoutTitle>
              <h3>Submissions</h3>
            </LayoutTitle>
          </>
        )}
        {tab === AdminTab.REJUDGE && (
          <>
            <LayoutTitle>
              <h3>Rejudging</h3>
            </LayoutTitle>
          </>
        )}
        {tab === AdminTab.RESET_PASSWORD && (
          <>
            <LayoutTitle>
              <h3>Reset</h3>
            </LayoutTitle>
          </>
        )}
      </LayoutSideMain>
    </>
  );
};
