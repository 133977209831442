// https://medium.com/@MatDrinksTea/rendering-markdown-and-latex-in-react-dec355e74119
import React, { CSSProperties, memo, ReactNode } from 'react';
import ReactMarkdown from 'react-markdown';
import MathJax from 'react-mathjax';
import RemarkMathPlugin from 'remark-math';
import gfm from 'remark-gfm';

import { MonacoEditor } from '../MonacoEditor';
import { ProgrammingLanguage } from '../../types';
import { PROGRAMMING_LANGUAGES } from '../../constants';

import './styles.scss';

const commands: { key: string, style: CSSProperties }[] = [
  { key: '\\textAlign=center', style: { textAlign: 'center' } },
  { key: '\\textAlign=left', style: { textAlign: 'left' } },
  { key: '\\textAlign=right', style: { textAlign: 'right' } },
  { key: '\\textAlign=justify', style: { textAlign: 'justify' } }
];

const hxRender = (level: number, children: ReactNode, style: CSSProperties) => {
  switch (level) {
    case 1:
      return <h1 style={style}>{children}</h1>;
    case 2:
      return <h2 style={style}>{children}</h2>;
    case 3:
      return <h3 style={style}>{children}</h3>;
    case 4:
      return <h4 style={style}>{children}</h4>;
    case 5:
      return <h5 style={style}>{children}</h5>;
    default:
      return <h6 style={style}>{children}</h6>;
  }
};

export const MdMathViewer = memo(({ dark, source }: { source: string, dark?: boolean }) => {
  const newProps = {
    source,
    plugins: [
      RemarkMathPlugin,
      gfm
    ],
    renderers: {
      image: (props: { src: string, alt?: string }) => {
        const image = props.src.split('=');
        const size = image[1] ? image[1].split('x') : [];
        const center = image[1] === 'center' || image[2] === 'center';
        return <img
          alt={props.alt} src={image[0]}
          style={{
            maxWidth: '100%',
            width: (size[0] ? size[0] + 'px' : 'unset'),
            height: (size[1] ? size[1] + 'px' : 'unset'),
            ...(center ? { display: 'block', margin: '0 auto' } : {})
          }}
        />;
      },
      paragraph: (props: { children: { props: any }[] }) => {
        for (const command of commands) {
          if (props && props.children[0] && props.children[0].props && props.children[0].props.value && props.children[0].props.value.indexOf(command.key) === 0) {
            const text = props.children[0].props.value.replace(command.key, '');
            const children = [...props.children];
            children[0] = text;
            return <p style={command.style}>{children}</p>;
          }
        }
        return <p>{props.children}</p>;
      },
      heading: (props: { children: { props: any }[], level: number; }) => {
        for (const command of commands) {
          if (props && props.children[0] && props.children[0].props && props.children[0].props.value && props.children[0].props.value.indexOf(command.key) === 0) {
            const text = props.children[0].props.value.replace(command.key, '');
            const children = [...props.children];
            children[0] = text;
            return hxRender(props.level, children, command.style);
          }
        }
        return hxRender(props.level, props.children, {});
      },
      math: (props: { value: string; }) => <MathJax.Node formula={props.value} />,
      inlineMath: (props: { value: string; }) => <MathJax.Node inline formula={props.value} />,
      code: (props: { language?: string, value?: string }) => {
        const settings = ((props && props.language) || '').split(',');
        const language = settings[0] || PROGRAMMING_LANGUAGES[ProgrammingLanguage.TEXT].monaco;
        const height = settings[1] || 'auto';
        const isDark = (settings[2] === 'dark') || dark;
        return (
          <MonacoEditor
            language={language}
            source={(props && props.value) || ''}
            dark={isDark}
            height={height}
            forceEditor
          />
        );
      }
    }
  };
  
  return (
    <div className="math-markdown-viewer">
      <MathJax.Provider>
        <ReactMarkdown {...newProps} />
      </MathJax.Provider>
    </div>
  );
});
