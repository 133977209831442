import React, { useEffect, useState } from 'react';

import { classNames } from '../../helpers';
import { FieldEditableProps } from './types';

import '../../styles/index.scss';

export const FieldEditable = <T, >({ value, onChange, hotChange, type = 'text' }: FieldEditableProps<T>) => {
  const clean = (myValue: T): T => {
    if (typeof value === 'number') {
      return ((isNaN(+myValue) || !isFinite(+myValue)) ? 0 : myValue) as T;
    }
    return myValue;
  };
  const [editValue, setEditValue] = useState<T>(clean(value));
  useEffect(() => setEditValue(value), [value]);
  const [editable, setEditable] = useState<boolean>(false);
  
  return (
    <div
      className={classNames('juki-field-editable', { editable })}
      onBlur={() => {
        setEditable(false);
        setEditValue(clean(value));
        onChange(editValue);
      }}
      onKeyDown={editable ? event => {
        if (event.key === 'Enter') {
          setEditable(false);
          setEditValue(clean(value));
          onChange(editValue as typeof value);
        }
      } : undefined}
      onClick={() => setEditable(true)}
    >
      <input
        size={Math.max(('' + editValue).length, 3)}
        className="label"
        value={'' + editValue}
        onChange={({ target }) => {
          const newValue = clean(target.value as unknown as T);
          setEditValue(newValue);
          if (hotChange) {
            onChange(newValue);
          }
        }}
        type={type}
      />
    </div>
  );
};

export * from './types';
