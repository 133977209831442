import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useFlagsDispatch, useFlagsState } from '../../../hooks';
import { NotificationType } from '../../../commons/types';
import { ErrorNotification, InfoNotification, SuccessNotification, WarningNotification } from './Notifications';

export const NotificationTrigger = () => {
  
  const { lastNotification } = useFlagsState();
  const { updateFlags } = useFlagsDispatch();
  const { t } = useTranslation();
  
  useEffect(() => {
    switch (lastNotification?.type) {
      case NotificationType.SUCCESS:
        SuccessNotification({
          title: t(lastNotification?.title || ''),
          description: t(lastNotification.description),
          duration: lastNotification?.duration
        });
        break;
      case NotificationType.INFO:
        InfoNotification({
          title: t(lastNotification?.title || ''),
          description: t(lastNotification.description),
          duration: lastNotification?.duration
        });
        break;
      case NotificationType.WARNING:
        WarningNotification({
          title: t(lastNotification?.title || ''),
          description: t(lastNotification.description),
          duration: lastNotification?.duration
        });
        break;
      case NotificationType.ERROR:
        ErrorNotification({
          title: t(lastNotification?.title || ''),
          description: t(lastNotification.description),
          duration: lastNotification?.duration
        });
    }
    if (lastNotification) {
      updateFlags({ lastNotification: null })();
    }
  }, [lastNotification, t, updateFlags]);
  
  return <></>;
};

export * from './Notifications';
