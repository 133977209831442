import { FlagActionTypes, FlagsActions, FlagsState } from '../types';
import { DEFAULT_FLAGS_STATE } from '~/config/constants';

export const flags = (state: FlagsState = { ...DEFAULT_FLAGS_STATE }, action: FlagActionTypes) => {
  switch (action.type) {
    case FlagsActions.UPDATE_FLAGS:
      return { ...state, ...action.flags };
    default:
      return state;
  }
};
