import React, { useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Breadcrumb, Button } from 'antd';
import { FileDoneOutlined, HomeOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { CONTEST_TAB, LOADING, ROUTES, SEC_60 } from '~/config/constants';
import { Overview } from './Overview';
import { ContestProblems } from './Problems';
import { ContestProblemView } from './Problems/ContestProblemView';
import { Scoreboard } from './Scoreboard';
import { ContestJudge } from './Judge';
import { useAccountState, useContestDispatch, useContestState, useInterval } from '~/hooks';
import { Clarifications, JukiLayout, JukiSider, Loader, LoaderIcon, MenuSideBar, MySubmissions, TimerClock } from '~/components';
import { Status } from './Status';
import { classNames } from '~/helpers';
import { ContestParamsType, ContestSettingsParams, ContestTab, ContestTimeStatus, LoaderState, ProblemTab } from '~/types';
import { ReactComponent as DownArrow } from '~/graphics/down-arrow.svg';
import { ReactComponent as OverviewIcon } from '~/graphics/overview.svg';
import { ReactComponent as ProblemIcon } from '~/graphics/problem.svg';
import { ReactComponent as ScoreboardIcon } from '~/graphics/scoreboard.svg';
import { ReactComponent as MySubmissionsIcon } from '~/graphics/submissions.svg';
import { ReactComponent as StatusIcon } from '~/graphics/status.svg';
import { ReactComponent as ClarificationIcon } from '~/graphics/clarification.svg';

import './styles.scss';

export const ContestView = () => {
  
  const { key, tab, subTab, subSubTab } = useParams<ContestParamsType>();
  const {
    settings,
    problems,
    canViewScoreBoard,
    registered,
    canViewProblems,
    timing,
    name,
    canUpdate,
    canRejudge
  } = useContestState(key);
  const { isLogged, nickname } = useAccountState();
  const { loadContest, loadContestClarifications } = useContestDispatch();
  const [loading, setLoading] = useState<LoaderState>([new Date().getTime(), LOADING]);
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const { t } = useTranslation();
  
  const canViewClarifications = settings && (canUpdate || (!canUpdate && settings.start < new Date().getTime() && registered));
  useEffect(() => {
    loadContest(key, setLoading)();
    if (isLogged && canViewClarifications) {
      loadContestClarifications(key)();
    }
  }, [isLogged, key, loadContest, loadContestClarifications, nickname, canViewClarifications]);
  useInterval(() => {
    loadContest(key)();
    if (isLogged && canViewClarifications) {
      loadContestClarifications(key)();
    }
  }, SEC_60);
  
  useEffect(() => {
    const now = new Date().getTime();
    if (settings?.start) {
      if (settings.start >= now) {
        timeoutRef.current = setTimeout(loadContest(key, setLoading), settings.start - now);
      }
    }
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [key, loadContest, settings]);
  
  const canViewStatus = settings && (canRejudge || canUpdate || (!canUpdate && settings.start < new Date().getTime()));
  
  const menuSideBarItems = [
    {
      link: ROUTES.CONTESTS.VIEW(key, ContestTab.OVERVIEW),
      selected: tab === CONTEST_TAB[ContestTab.OVERVIEW].value,
      icon: <OverviewIcon />,
      label: CONTEST_TAB[ContestTab.OVERVIEW].print
    }
  ];
  if (canViewProblems) {
    menuSideBarItems.push({
      link: ROUTES.CONTESTS.VIEW(key, ContestTab.PROBLEMS),
      selected: (tab === ContestTab.PROBLEMS || tab === ContestTab.PROBLEM),
      icon: <ProblemIcon />,
      label: CONTEST_TAB[ContestTab.PROBLEMS].print
    });
  }
  if (canViewScoreBoard && canViewStatus) {
    menuSideBarItems.push({
      link: ROUTES.CONTESTS.VIEW(key, ContestTab.SCOREBOARD),
      selected: tab === ContestTab.SCOREBOARD,
      icon: <ScoreboardIcon />,
      label: CONTEST_TAB[ContestTab.SCOREBOARD].print
    });
  }
  if (isLogged && registered && canViewStatus) {
    menuSideBarItems.push({
      link: ROUTES.CONTESTS.VIEW(key, ContestTab.SUBMISSIONS),
      selected: tab === ContestTab.SUBMISSIONS,
      icon: <MySubmissionsIcon />,
      label: CONTEST_TAB[ContestTab.SUBMISSIONS].print
    });
  }
  if ((canViewScoreBoard || canRejudge) && canViewStatus) {
    menuSideBarItems.push({
      link: ROUTES.CONTESTS.VIEW(key, ContestTab.STATUS),
      selected: tab === ContestTab.STATUS,
      icon: <StatusIcon />,
      label: CONTEST_TAB[ContestTab.STATUS].print
    });
  }
  if (canRejudge && settings && settings[ContestSettingsParams.MANUAL_JUDGE]) {
    menuSideBarItems.push({
      link: ROUTES.CONTESTS.VIEW(key, ContestTab.JUDGE),
      selected: tab === ContestTab.JUDGE,
      icon: <FileDoneOutlined />,
      label: CONTEST_TAB[ContestTab.JUDGE].print
    });
  }
  if (isLogged && settings?.clarifications && canViewClarifications) {
    menuSideBarItems.push({
      link: ROUTES.CONTESTS.VIEW(key, ContestTab.CLARIFICATIONS),
      selected: tab === ContestTab.CLARIFICATIONS,
      icon: <ClarificationIcon />,
      label: CONTEST_TAB[ContestTab.CLARIFICATIONS].print
    });
  }
  
  return (
    <Loader
      loading={!settings || !problems}
      component={() => {
        const now = new Date().getTime();
        const label = (settings.start <= now && now <= settings.start + timing.duration) ?
          'live' : (settings?.start > now) ? 'upcoming' : 'past';
        return (
          <JukiSider
            sideBar={(isClosed: boolean) => (
              <>
                <JukiLayout type="title">
                  {loading[1] === LOADING ? <LoaderIcon /> : (
                    <>
                      <div className="content-status-badge">
                        <div className={'text-s bold status-badge ' + label}>{t(label)}</div>
                      </div>
                      <TimerClock
                        startDate={new Date(settings.start)}
                        endDate={new Date(settings.start + timing.duration)}
                        onFinish={loadContest(key, setLoading)}
                        laps={isClosed ? 1 : 3}
                      />
                    </>
                  )}
                </JukiLayout>
                <JukiLayout>
                  <MenuSideBar
                    items={menuSideBarItems}
                    isClosed={isClosed}
                  />
                </JukiLayout>
              </>
            )}
            sideMain={() => (
              <>
                <JukiLayout type="title">
                  <div className="content-breadcrumb">
                    <Link to={ROUTES.ROOT}><HomeOutlined /></Link>
                    <Breadcrumb separator={<DownArrow />}>
                      <Breadcrumb.Item>
                        <Link to={ROUTES.CONTESTS.LIST_PAGE(ContestTimeStatus.LIVE)}>{t('contests')}</Link>
                      </Breadcrumb.Item>
                      <Breadcrumb.Item>
                        <Link to={ROUTES.CONTESTS.VIEW(key, ContestTab.OVERVIEW)}>{name}</Link>
                      </Breadcrumb.Item>
                      <Breadcrumb.Item>
                        <Link to={ROUTES.CONTESTS.VIEW(key, tab)}>{t(CONTEST_TAB[tab].print)}</Link>
                      </Breadcrumb.Item>
                      {subTab && (
                        <Breadcrumb.Item>
                          <Link to={ROUTES.CONTESTS.VIEW(key, tab, subTab, ProblemTab.STATEMENT)}>{t(subTab)}</Link>
                        </Breadcrumb.Item>
                      )}
                      {subSubTab && <Breadcrumb.Item>{subSubTab}</Breadcrumb.Item>}
                    </Breadcrumb>
                  </div>
                  <div className="content-title">
                    <div className="text-xh bold">{name}</div>
                    {canUpdate && (
                      <Link to={ROUTES.CONTESTS.EDIT(key, ContestTab.OVERVIEW)} className="button-edit-contest">
                        <Button type="ghost">{t('edit') + ' ' + t('contest')}</Button>
                      </Link>
                    )}
                  </div>
                </JukiLayout>
                <JukiLayout
                  className={classNames(tab, {
                    'with-pagination': tab === ContestTab.SCOREBOARD ||
                      tab === ContestTab.SUBMISSIONS ||
                      tab === ContestTab.STATUS
                  })}>
                  {tab === ContestTab.OVERVIEW && <Overview />}
                  {tab === ContestTab.PROBLEMS && <ContestProblems />}
                  {tab === ContestTab.PROBLEM && <ContestProblemView />}
                  {tab === ContestTab.SCOREBOARD && <Scoreboard />}
                  {tab === ContestTab.SUBMISSIONS && <MySubmissions />}
                  {tab === ContestTab.STATUS && <Status />}
                  {tab === ContestTab.JUDGE && <ContestJudge />}
                  {tab === ContestTab.CLARIFICATIONS && <Clarifications />}
                </JukiLayout>
              </>
            )}
          />
        );
      }}
    />
  );
};

export * from './Clarifications';
export * from './MySubmissions';
export * from './Overview';
export * from './Problems';
export * from './Scoreboard';
export * from './Status';
