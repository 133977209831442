import React from 'react';
import { Switch } from 'antd';

import { classNames } from '../../../../commons/helpers';
import { useAccountDispatch, useAccountState } from '../../../../hooks';
import { ProfileSettingOptions, Theme} from '../../../../commons/types';

import './ChangeTheme.scss';
import { THEME } from '~/commons/constants';

const ChangeTheme = ({ value, onChange, loading }: { value: boolean, onChange: (e: boolean) => void, loading: boolean }) => {

  const account = useAccountState();
  const { replaceAccount } = useAccountDispatch();

  return (
    <Switch
      checkedChildren={THEME[Theme.LIGHT].print}
      unCheckedChildren={THEME[Theme.DARK].print}
      checked={value}
      defaultChecked={value}
      onChange={(e) => {
        onChange(e);
        replaceAccount({
          ...account, mySettings: {
            ...account.mySettings,
            [ProfileSettingOptions.THEME]: e ? Theme.DARK : Theme.LIGHT
          }
        })();
      }}
      loading={loading}
      className={classNames('switch-theme-selector',
        { 'light': account?.mySettings[ProfileSettingOptions.THEME] === Theme.LIGHT },
        { 'dark': account?.mySettings[ProfileSettingOptions.THEME] === Theme.DARK }
      )}
    />
  );
}

export default ChangeTheme;