import React from 'react';
import { Select, Input } from 'antd';

import {
  ACCEPTED_PROGRAMMING_LANGUAGES,
  PROBLEM_INPUT,
  PROBLEM_MODE,
  PROBLEM_OUTPUT,
  PROBLEM_STATUS,
  PROGRAMMING_LANGUAGES
} from '~/config/constants';
import { FieldEditable } from '~/components';
import { ProblemInput, ProblemMode, ProblemOutput, ProblemStatus, ProgrammingLanguage } from '~/types';
import { ProblemCreateSetupProps } from './types';

import './styles.scss';

const { Option } = Select;
const { TextArea } = Input;

export const ProblemCreateSetup = ({ problem, setProblem }: ProblemCreateSetupProps) => {
  return (
    <>
      <div>
        Languages:
        <Select
          mode="multiple"
          onChange={(languages: Array<ProgrammingLanguage>) => setProblem(prevState => ({
            ...prevState,
            settings: { ...prevState.settings, languages: languages }
          }))}
          value={problem.settings.languages}
          style={{ width: '200px' }}
        >
          {ACCEPTED_PROGRAMMING_LANGUAGES.map(language => (
            <Option value={language} key={language}> {PROGRAMMING_LANGUAGES[language].name} </Option>
          ))}
        </Select>
      </div>
      <div>
        Input:
        <Select
          onChange={(typeInput: ProblemInput) => setProblem(prevState => ({
            ...prevState,
            settings: { ...prevState.settings, typeInput }
          }))}
          value={problem.settings.typeInput}
          style={{ width: '110px' }}
        >
          {[ProblemInput.STANDARD, ProblemInput.INTERACTIVE].map(input => (
            <Option value={input} key={input}> {PROBLEM_INPUT[input].print} </Option>
          ))}
        </Select>
      </div>
      <div>
        Output:
        <Select
          onChange={(typeOutput: ProblemOutput) => setProblem(prevState => ({
            ...prevState,
            settings: { ...prevState.settings, typeOutput }
          }))}
          value={problem.settings.typeOutput}
          style={{ width: '110px' }}
        >
          {[ProblemOutput.STANDARD, ProblemOutput.DYNAMIC, ProblemOutput.INTERACTIVE].map(output => (
            <Option value={output} key={output}> {PROBLEM_OUTPUT[output].print} </Option>
          ))}
        </Select>
      </div>
      <div>
        Time limit per test:
        <FieldEditable<number>
          value={problem.settings.timeLimit / 1000}
          onChange={(value) => setProblem(prevState => ({
            ...prevState,
            settings: { ...prevState.settings, timeLimit: Math.max(1, Math.min(10, value)) * 1000 }
          }))}
        />
        second{(problem.settings.timeLimit > 1000) && 's'}
      </div>
      <div>
        Memory limit per test:
        <FieldEditable<number>
          value={problem.settings.memoryLimit}
          onChange={(value) => setProblem(prevState => ({
            ...prevState,
            settings: { ...prevState.settings, memoryLimit: Math.max(1, Math.min(1024 * 1024, value)) }
          }))}
        />
        kb
      </div>
      <div>
        Visibility:
        <Select
          onChange={(status: ProblemStatus) => setProblem(prevState => ({
            ...prevState,
            status
          }))}
          value={problem.status}
          style={{ width: '100px' }}
        >
          {[ProblemStatus.PUBLIC, ProblemStatus.PRIVATE].map(status => (
            <Option value={status} key={status}> {PROBLEM_STATUS[status].print} </Option>
          ))}
        </Select>
      </div>
      <div>
        Author:
        <FieldEditable
          value={problem.author}
          onChange={(value) => setProblem(prevState => ({ ...prevState, author: value }))}
        />
      </div>
      <div>
        Tags: <br />
        <span className="text-s semi-bold"> write the tags separated by commas </span>
        <TextArea
          autoSize={{ minRows: 2, maxRows: 5 }}
          value={problem.tags.join(',')}
          onChange={({ target }) => setProblem(prevState => ({ ...prevState, tags: target.value.split(',') }))}
        />
      </div>
      <div>
        Problem Mode: {PROBLEM_MODE[problem.settings.mode].print} <span className="observation">To change this, go Test Cases section</span>
      </div>
      {problem.settings.mode === ProblemMode.POINTS && (
        <div className="settings-groups">
          <div>
            <div>
              <div>Group :</div>
              <div>Points :</div>
            </div>
            {Object.keys(problem.settings.groupsPoint).map((key) => (
              <div>
                <div>{key}</div>
                <div>{problem.settings.groupsPoint[key]}</div>
              </div>
            ))}
          </div>
          <span className="observation">To change this, go Test Cases section</span>
        </div>
      )}
    </>
  );
};
