import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector, ParametricSelector } from 'reselect';

import { RootState } from '../store/reducers';
import { RankingState } from '../store/types';
import { loadRanking } from '../store/actions';

const selectRankingState: ParametricSelector<RootState, undefined, { ranking: RankingState }> =
  createSelector<RootState, RankingState, { ranking: RankingState }>(
    state => state.ranking,
    (ranking) => ({ ranking })
  );

export const useRankingState = () => {
  return useSelector((state: RootState) => selectRankingState(state, undefined));
}

export const useRankingDispatch = () => {
  const dispatch = useDispatch();
  return {
    loadRanking: useCallback(() => () => dispatch(loadRanking()), [dispatch])
  }
}
