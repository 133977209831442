import { AccountActionsTypes, AccountActions, AccountState } from '../types';
import { USER_GUEST, PAGED_ARRAY_EMPTY } from '~/config/constants';

export const account = (state: AccountState = USER_GUEST, action: AccountActionsTypes): AccountState => {
  switch (action.type) {
    case AccountActions.UPDATE_ACCOUNT:
      return {
        ...USER_GUEST,
        ...state,
        ...action.account,
        submissions: { ...PAGED_ARRAY_EMPTY, ...state.submissions, ...action?.account?.submissions },
        permissions: { ...state.permissions, ...action?.account?.permissions },
        mySettings: { ...USER_GUEST.mySettings, ...state.mySettings, ...action?.account?.mySettings }
      };
    default:
      return state;
  }
};
