import { ProblemMetaState, ProblemNewState, ProblemState, SubmissionProblem } from '~/store/types';
import { clean, SubmitShortResponse } from './index';
import { API, authorizedFileRequest, authorizedFormDataRequest, authorizedRequest, GET, POST, PUT } from '../server.config';
import { Code, ErrorResponse, OkListResponse, OkObjectResponse, OkPagedListResponse, ProgrammingLanguage, SearchParams, TestCase } from '~/types';
import { searchBuilder } from '~/helpers';

const getProblemListSTACK: Array<AbortController> = [];
export const getProblemList = (params: SearchParams, key?: string) => async (): Promise<ErrorResponse | OkListResponse<ProblemState>> => {
  while (getProblemListSTACK.length) {
    getProblemListSTACK.pop()?.abort();
  }
  const controller = new AbortController();
  const signal = controller.signal;
  getProblemListSTACK.push(controller);
  return clean(await authorizedRequest(API.PROBLEM.PROBLEM(key) + searchBuilder(params), GET, '', signal));
};

const getProblemSTACK: Array<AbortController> = [];
export const getProblem = (id?: string) => async (): Promise<ErrorResponse | OkObjectResponse<ProblemState>> => {
  while (getProblemSTACK.length) {
    getProblemSTACK.pop()?.abort();
  }
  const controller = new AbortController();
  const signal = controller.signal;
  getProblemSTACK.push(controller);
  return clean(await authorizedRequest(API.PROBLEM.PROBLEM(id), GET, '', signal));
};

export const getProblemTestCases = (id: string) => async (): Promise<ErrorResponse | OkListResponse<TestCase>> => {
  while (getProblemSTACK.length) {
    getProblemSTACK.pop()?.abort();
  }
  const controller = new AbortController();
  const signal = controller.signal;
  getProblemSTACK.push(controller);
  return clean(await authorizedRequest(API.PROBLEM.TESTS(id), GET, '', signal));
};

export const getProblemBasicList = () => async (): Promise<ErrorResponse | OkListResponse<ProblemMetaState>> => {
  while (getProblemSTACK.length) {
    getProblemSTACK.pop()?.abort();
  }
  const controller = new AbortController();
  const signal = controller.signal;
  getProblemSTACK.push(controller);
  return clean(await authorizedRequest(API.PROBLEM.BASIC_LIST(), GET, '', signal));
};

export const apiProblemTestCases = (id: string) => async (): Promise<ErrorResponse | OkObjectResponse<any>> => {
  return clean(await authorizedRequest(API.PROBLEM.TESTS(id), GET));
};

export const apiProblemUploadTestCase = (id: string, typeFile: 'INPUT' | 'OUTPUT', group: number, file: any, testCaseId?: string) => async (): Promise<ErrorResponse | OkObjectResponse<TestCase>> => {
  const formData = new FormData();
  if (testCaseId) {
    formData.append('id', testCaseId);
  }
  formData.append('group', '' + group);
  formData.append('file', file);
  const params: SearchParams = { type: [typeFile] };
  return clean(await authorizedFormDataRequest(API.PROBLEM.TEST(id) + searchBuilder(params), PUT, formData));
};

export const apiProblemTestCase = (id: string, typeFile: 'INPUT' | 'OUTPUT', testCaseId: string) => async () => {
  const params: SearchParams = { type: [typeFile] };
  return await authorizedFileRequest(API.PROBLEM.TEST(id, testCaseId) + searchBuilder(params));
};

export const apiSubmitProblem = (id: string, source: string, language: ProgrammingLanguage) => async (): Promise<ErrorResponse | OkObjectResponse<SubmitShortResponse>> => {
  return clean(await authorizedRequest(API.PROBLEM.SUBMIT(id), POST, JSON.stringify({ source, language })));
};

export const apiProblemCreate = (problem: ProblemNewState) => async (): Promise<ErrorResponse | OkObjectResponse<ProblemState>> => {
  return clean(await authorizedRequest(API.PROBLEM.PROBLEM(), POST, JSON.stringify(problem)));
};

export const apiProblemUpdate = (problem: ProblemState) => async (): Promise<ErrorResponse | OkObjectResponse<ProblemState>> => {
  return clean(await authorizedRequest(API.PROBLEM.PROBLEM(problem.id), PUT, JSON.stringify(problem)));
};

export const apiProblemCodeSubmissions = (mongoId: string) => async (): Promise<ErrorResponse | OkObjectResponse<Code>> => {
  return clean(await authorizedRequest(API.PROBLEM.SUBMISSION_CODE(mongoId)));
};

const getProblemStatusStack: Array<AbortController> = [];
export const apiProblemStatus = (id: string, params: SearchParams) => async (): Promise<OkPagedListResponse<SubmissionProblem>> => {
  while (getProblemStatusStack.length) {
    getProblemStatusStack.pop()?.abort();
  }
  const controller = new AbortController();
  const signal = controller.signal;
  getProblemStatusStack.push(controller);
  return clean(await authorizedRequest(API.PROBLEM.PROBLEM_STATUS(id) + searchBuilder(params), GET, '', signal));
};
