import { consoleWarn, isJson } from '~/helpers';
import {
  ErrorCodes,
  ErrorResponse,
  LoaderAction,
  NotificationType
} from '~/types';
import { clearRedux, updateFlags } from '~/store/actions';
import { ERROR, ERROR_MESSAGES, LOADING, NONE, SUCCESS } from '~/config/constants';

export const clean = (responseText: string): any => {
  if (!isJson(responseText)) {
    // this occurs when the endpoint don't exits or server is down
    consoleWarn({ responseText });
    return { success: ERROR, message: ERROR_MESSAGES[ErrorCodes.ERR9999], errorCode: ErrorCodes.ERR9999 };
  }
  const responseJson = JSON.parse(responseText);
  if (responseJson.success) {
    if (Object.keys(responseJson).length === 3 && typeof responseJson.total === 'number' && Array.isArray(responseJson.list)) {
      return { ...responseJson, success: SUCCESS };
    }
    if (Object.keys(responseJson).length === 2 && typeof responseJson.object === 'object') {
      return { ...responseJson, success: SUCCESS };
    }
    if (Object.keys(responseJson).length === 1 && responseJson.success === true) {
      return { success: SUCCESS };
    }
    consoleWarn({ responseText });
    return { success: ERROR, message: ERROR_MESSAGES[ErrorCodes.ERR9998], errorCode: ErrorCodes.ERR9998 };
  } else {
    consoleWarn({ responseText });
    if (Object.keys(responseJson).length === 3 && typeof responseJson.message === 'string' && typeof responseJson.errorCode === 'string') {
      return { ...responseJson, success: ERROR };
    }
    return { success: ERROR, message: ERROR_MESSAGES[ErrorCodes.ERR9998], errorCode: ErrorCodes.ERR9998 };
  }
};

export const actionLoaderWrapper = async <T>(request: () => Promise<ErrorResponse | T>, dispatch: Function, onSuccess: (result: T) => void, setLoader?: LoaderAction, onFailure?: (result: ErrorResponse) => void) => {
  const timeStamp = new Date().getTime();
  setLoader?.([timeStamp, LOADING]);
  const result: any = await request();
  if (result) {
    if (result.success === SUCCESS) {
      setLoader?.(prevState => prevState[0] === timeStamp ? [timeStamp, SUCCESS] : prevState);
      onSuccess(result);
    } else {
      let setError = true;
      if (setLoader) {
        setError = false;
        setLoader((prevState: any) => {
          if (!setError && prevState[0] === timeStamp) {
            setError = true;
            if (result.errorCode !== ErrorCodes.ERR9997) {
              // dispatch(updateFlags({
              //   lastNotification: {
              //     type: NotificationType.ERROR,
              //     description: result.message
              //   }
              // }));
              return [timeStamp, ERROR];
            } else {
              return [timeStamp, NONE];
            }
          }
          return prevState;
        });
      }
      if (result.errorCode !== ErrorCodes.ERR9997) {
        dispatch(updateFlags({
          lastNotification: {
            type: NotificationType.ERROR,
            description: result.message
          }
        }));
      }
      if (setError) {
        if (result.errorCode === ErrorCodes.ERR9901) {
          dispatch(clearRedux());
        }
        onFailure?.(result);
      }
    }
  } else {
    consoleWarn({ result, message: 'actionLoaderWrapper error' })
  }
};

export * from './api.account';
export * from './api.contest';
export * from './api.problem';
export * from './api.upload';
export * from './api.user';
