import { Dispatch } from 'redux';
import { ProblemActions, ProblemNewState, ProblemsListActions, ProblemState } from '../types';
import {
  actionLoaderWrapper,
  apiProblemCreate,
  apiProblemStatus,
  apiProblemUpdate,
  getProblem,
  getProblemList
} from '../../services/juki-judge-back';
import { LoaderAction, ProblemTab } from '~/types';
import { SuccessNotification } from '~/components';
import { ROUTES, PAGE, PAGE_SIZE, PAGE_SIZES, PAGED_ARRAY_EMPTY } from '~/config/constants';
import { history, RootState } from '../reducers';
import { updateFlags } from './flags';
import { fillPageParams, objectOfSearch } from '~/helpers';

const updateProblems = (problems: Array<ProblemState>) => {
  return {
    type: ProblemActions.UPDATE_PROBLEMS,
    problems
  };
};

const replaceProblemsList = (problems: Array<string>) => {
  return {
    type: ProblemsListActions.REPLACE_PROBLEMS_LIST,
    problems
  };
};

export const loadAllProblems = (search: string, setLoader: LoaderAction) => {
  return async (dispatch: Function) => {
    const params = objectOfSearch(search);
    if (!params[PAGE]?.length) {
      params[PAGE] = [1];
    }
    if (!params[PAGE_SIZE]?.length) {
      params[PAGE_SIZE] = [PAGE_SIZES[0]];
    }
    await actionLoaderWrapper(
      getProblemList(params),
      dispatch,
      (result) => {
        dispatch(updateProblems(result.list));
        dispatch(replaceProblemsList(result.list.map(problem => problem.id)));
      },
      setLoader
    );
  };
};

export const loadProblem = (key: string, setLoader: LoaderAction) => {
  return async (dispatch: Function) => {
    await actionLoaderWrapper(
      getProblem(key),
      dispatch,
      (result) => {
        dispatch(updateProblems([result.object]));
      },
      setLoader,
      () => {
        history.push(ROUTES.PROBLEMS.LIST_PAGE());
      }
    );
  };
};

export const createNewProblem = (problem: ProblemNewState, setLoader: LoaderAction) => {
  return async (dispatch: Function) => {
    await actionLoaderWrapper(
      apiProblemCreate(problem),
      dispatch,
      (result) => {
        SuccessNotification({ description: 'Problem successfully created...' });
        dispatch(updateProblems([result.object]));
        history.push(ROUTES.PROBLEMS.EDIT(result.object.id, ProblemTab.TESTS));
      },
      setLoader
    );
  };
};

export const updateProblem = (problem: ProblemState, setLoader: LoaderAction) => {
  return async (dispatch: Function) => {
    await actionLoaderWrapper(
      apiProblemUpdate(problem),
      dispatch,
      (result) => {
        SuccessNotification({ description: 'Problem successfully updated...' });
        dispatch(updateProblems([result.object]));
      },
      setLoader
    );
  };
};

export const loadProblemStatus = (id: string, setLoader: LoaderAction, search: string, clean: boolean) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    if (clean) {
      dispatch(updateProblems([{ id, submissions: { ...PAGED_ARRAY_EMPTY } } as ProblemState]));
    }
    await actionLoaderWrapper(
      async () => {
        if (!getState().flags.requestingApiProblemStatus || clean) {
          const now = new Date().getTime();
          dispatch(updateFlags({ requestingApiProblemStatus: now }));
          const result = await apiProblemStatus(id, fillPageParams(objectOfSearch(search)))();
          if (getState().flags.requestingApiProblemStatus === now) {
            dispatch(updateFlags({ requestingApiProblemStatus: 0 }));
          }
          return result;
        }
      },
      dispatch,
      (result) => {
        if (result) {
          dispatch(updateProblems([{
            id, submissions: {
              list: result.object.content,
              pageNumber: result.object.number,
              pageSize: result.object.size,
              totalPages: result.object.totalPages,
              totalElements: result.object.totalElements
            }
          } as ProblemState]));
        }
      },
      setLoader
    );
  };
};
