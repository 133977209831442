import React from 'react';
import { PlusSquareOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { ErrorNotification, MdMathEditor, SuccessNotification } from '~/components';
import { SampleTest } from './SampleTest';
import { apiUploadFile } from '~/services';
import { ERROR, SUCCESS } from '~/commons/constants';
import { ProblemCreateStatementProps } from './types';

export const ProblemCreateStatement = ({ problem, setProblem }: ProblemCreateStatementProps) => {
  
  const { t } = useTranslation();
  const uploadImageRequest = async (imageBlob: Blob): Promise<[string, typeof SUCCESS | typeof ERROR]> => {
    const result = await apiUploadFile('image', ['problem', 'test'], imageBlob)();
    if (result.success === SUCCESS) {
      SuccessNotification({ description: 'Image upload successfully' });
      return [result.object.fileUrl, result.success];
    }
    ErrorNotification({ description: result.message });
    return ['', ERROR];
  };
  
  return (
    <div className="content-problem-statement">
      <div className="problem-description-box">
        <h6>{t('description')}</h6>
        <MdMathEditor
          value={problem.description.general}
          onChange={(value) => setProblem(prevState => ({
            ...prevState,
            description: { ...prevState.description, general: value }
          }))}
          uploadImageRequest={uploadImageRequest}
        />
      </div>
      <div className="problem-input-description-box">
        <h6>{t('input description')}</h6>
        <MdMathEditor
          value={problem.description.input}
          onChange={(value) => setProblem(prevState => ({
            ...prevState,
            description: { ...prevState.description, input: value }
          }))}
          uploadImageRequest={uploadImageRequest}
        />
      </div>
      <div className="problem-output-description-box">
        <h6>{t('output description')}</h6>
        <MdMathEditor
          value={problem.description.output}
          onChange={(value) => setProblem(prevState => ({
            ...prevState,
            description: { ...prevState.description, output: value }
          }))}
          uploadImageRequest={uploadImageRequest}
        />
      </div>
      <div className="problem-samples-box">
        <div className="problem-sample-box">
          <h6 className="problem-sample-input-box">Input Sample</h6>
          <h6 className="problem-sample-output-box">Output Sample</h6>
          <div className="problem-sample-actions-box child-center">
            <PlusSquareOutlined
              onClick={() => setProblem(prevState => {
                const newSamples = [...prevState.samples];
                newSamples.push({ input: '\n', output: '\n' });
                return { ...prevState, samples: newSamples };
              })}
            />
          </div>
        </div>
        {problem.samples.map((sample, index) => (
          <SampleTest index={index} problem={problem} setProblem={setProblem} />
        ))}
      </div>
    </div>
  );
};
