import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector, ParametricSelector } from 'reselect';

import { RootState } from '../store/reducers';
import { AccountState, UserState } from '../store/types';
import {
  loadUserProfile,
  updateAccountSettings,
  updateAccountImageProfile,
  updateAccountPasswordProfile
} from '../store/actions';
import { LoaderAction } from '../commons/types';

const selectProfileStates: ParametricSelector<RootState, string, { profile: UserState }> =
  createSelector<RootState, string, UserState, { profile: UserState }>(
    (state, key) => state.profiles[key],
    (profile) => ({ profile })
  );

export const useProfileState = (key: string) => {
  return useSelector((state: RootState) => selectProfileStates(state, key));
};

export const useProfileDispatch = () => {
  const dispatch = useDispatch();
  return {
    loadUserProfile: useCallback((nickname: string, setLoader?: LoaderAction) => async () => dispatch(loadUserProfile(nickname, setLoader)), [dispatch]),
    updateUserSettings: (data: AccountState, setLoader: LoaderAction) => () => dispatch(updateAccountSettings(data, setLoader)),
    updateUserImageProfile: (blob: Blob, setLoader: LoaderAction) => () => dispatch(updateAccountImageProfile(blob, setLoader)),
    updateUserPasswordProfile: (oldPassword: string, newPassword: string, setLoader: LoaderAction) => () => dispatch(updateAccountPasswordProfile(oldPassword, newPassword, setLoader))
  };
};
