import { RankingState, RankingActions } from '../types';

export const replaceRanking = (ranking: RankingState) => {
  return {
    type: RankingActions.REPLACE_RANKING,
    rankingList: ranking
  };
};

export const loadRanking = () => {
  return async (dispatch: Function) => {
    let data: RankingState;
    data = await require('../../dummy/ranking.json');
    dispatch(replaceRanking(data));
  };
};
