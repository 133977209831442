import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Dropdown, Layout, Menu, Modal } from 'antd';
import { useTranslation } from 'react-i18next';

import { ROUTES } from '~/config/constants/routes';
import SignUp from './SignUp';
import Login from './Login';
import { useAccountDispatch, useAccountState, useFlagsDispatch, useFlagsState, useRouter } from '../../hooks';
import { can, classNames } from '~/helpers';
import { DownArrowIcon, JukiJudgeLogo } from '../../graphics';
import { LoaderIcon } from '../shared';
import { ChangeLanguage } from './ChangeLanguage';
import { DEFAULT_JUKI_PROFILE_IMAGE, LOADING, NONE } from '../../config/constants';
import { AdminTab, ContestTimeStatus, LoaderState, ProfileTab } from '../../types';

import './styles.scss';

const { Header } = Layout;

export const NavigationBar = () => {
  
  const account = useAccountState();
  const { logout } = useAccountDispatch();
  const { pathnameParams, push } = useRouter();
  const { openSignUpModal, openLoginModal, openWelcomeModal } = useFlagsState();
  const { updateFlags } = useFlagsDispatch();
  const [loader, setLoader] = useState<LoaderState>([new Date().getTime(), NONE]);
  const { t } = useTranslation();
  useEffect(() => {
    if (account.isLogged) {
      updateFlags({ openSignUpModal: false, openLoginModal: false })();
    }
  }, [account, updateFlags]);
  
  const handleMenu = ({ key }: any) => {
    if (key === 'logout') {
      logout(setLoader)();
    }
  };
  const keyTab = (pathnameParams[1] === ROUTES.PARAMS.CONTESTS || pathnameParams[1] === ROUTES.PARAMS.CONTEST) ? ROUTES.PARAMS.CONTESTS :
    (pathnameParams[1] === ROUTES.PARAMS.PROBLEM || pathnameParams[1] === ROUTES.PARAMS.PROBLEM) ? ROUTES.PARAMS.PROBLEMS :
      pathnameParams[1];
  const { givenName, nickname } = account;
  
  return (
    <>
      {openSignUpModal && <SignUp />}
      {openLoginModal && <Login />}
      {openWelcomeModal && (
        <Modal
          visible={openWelcomeModal}
          footer={null}
          onCancel={updateFlags({ openWelcomeModal: false })}
          className="modal-welcome"
          centered
        >
          <div>
            <h5>{t('hi')} <span className="given-name">{givenName}</span>!</h5>
            <h3>{t('Welcome to the Online Juki Judge')}</h3>
            <p>
              {t('participe in coding contests ranging from beginner level to week-long coding marathons')}
            </p>
            <div>
              <Button type="ghost" onClick={() => {
                push(ROUTES.PROFILE.PAGE(nickname, ProfileTab.PROFILE))();
                updateFlags({ openWelcomeModal: false })();
              }}>{t('see my profile')}</Button>
              <Button onClick={updateFlags({ openWelcomeModal: false })}>{t('continue')}</Button>
            </div>
          </div>
          <div>
            <img src="/images/juki-sitting-laptop.svg" alt="judge welcome" />
          </div>
        </Modal>
      )}
      <Header className="juki-layout-app-header">
        <div className="logo">
          <Link to={'/'}>
            <JukiJudgeLogo />
          </Link>
        </div>
        <Menu mode="horizontal" selectedKeys={[keyTab]} collapsedWidth={100} className="juki-header-menu">
          <Menu.Item key={ROUTES.PARAMS.CONTESTS}>
            <Link to={ROUTES.CONTESTS.LIST_PAGE(ContestTimeStatus.LIVE)}>{t('contests')}</Link>
          </Menu.Item>
          <Menu.Item key={ROUTES.PARAMS.PROBLEMS}>
            <Link to={ROUTES.PROBLEMS.LIST_PAGE()}>{t('problems')}</Link>
          </Menu.Item>
          {/*<Menu.Item key={ROUTES.PARAMS.RANKING}>
           <Link to={ROUTES.RANKING.PAGE()}>RANKING</Link>
           </Menu.Item>*/}
          {/*<Menu.Item key={ROUTES.PARAMS.ABOUT}>*/}
          {/*  <Link to={ROUTES.ABOUT.PAGE}>{t('about')}</Link>*/}
          {/*</Menu.Item>*/}
          {(can.createUser(account) || can.updateStatusUser(account) || can.updatePermissionsUser(account) ||
            can.viewAllUsers(account) || can.viewActiveUsers(account)) && (
            <Menu.Item key={ROUTES.PARAMS.ADMIN}>
              <Link to={ROUTES.ADMIN.PAGE(AdminTab.USERS)}>{t('admin')}</Link>
            </Menu.Item>
          )}
        </Menu>
        <ChangeLanguage />
        <div className={classNames('content-user', { logged: account.isLogged })}>
          {account.isLogged ? (loader[1] === LOADING ? (<LoaderIcon />) : (
            <>
              <div className="profile-image">
                <img alt={account.nickname} src={account.imageUrl || DEFAULT_JUKI_PROFILE_IMAGE} />
              </div>
              <Dropdown
                trigger={['click']}
                overlay={
                  <Menu className="layout-dropdown-body" onClick={handleMenu}>
                    {/*<Menu.Item key="rating" className="rating-label" style={{ color: 'green' }}>*/}
                    {/*  Rating (100)*/}
                    {/*</Menu.Item>*/}
                    <Menu.Item key="settings">
                      <Link to={ROUTES.PROFILE.PAGE(account.nickname, ProfileTab.PROFILE)}>{t('my profile')}</Link>
                    </Menu.Item>
                    <Menu.Item key="logout">{t('logout')}</Menu.Item>
                  </Menu>
                }
                placement="bottomRight"
                arrow
                overlayClassName="dropdown-user-profile"
              >
                <div
                  className={classNames('text-m semi-bold', { 'on-my-profile': pathnameParams[2] === account.nickname })}>
                  {account.nickname} <DownArrowIcon />
                </div>
              </Dropdown>
            </>
          )) : (
            <>
              <Button
                type="text"
                className="sign-up-button"
                onClick={updateFlags({ openSignUpModal: true })}
              >
                {t('sign up')}
              </Button>
              <Button type="primary" onClick={updateFlags({ openLoginModal: true })}>
                {t('login')}
              </Button>
            </>
          )}
        </div>
      </Header>
    </>
  );
};
