import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { CopyOutlined, DeleteOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons';
import { Input } from 'antd';

import { ProblemNewState, ProblemOverview } from '../../../../store/types';

const { TextArea } = Input;

export const SampleTest = ({ index, problem, setProblem }: { index: number, problem: ProblemOverview, setProblem?: Dispatch<SetStateAction<ProblemNewState>> }) => {
  
  const [sample, setSample] = useState(problem.samples?.[index] || { input: '', output: '' });
  const [editable, setEditable] = useState(false);
  useEffect(() => {
    setSample(problem.samples?.[index] || { input: '', output: '' });
    setEditable(false);
  }, [index, problem.samples]);
  
  const onSave = setProblem ? () => {
    setProblem(prevState => {
      const newSamples = [...prevState.samples];
      newSamples[index] = sample;
      return { ...prevState, samples: newSamples };
    });
    setEditable(false);
  } : () => null;
  
  return (
    <div className="problem-sample-box">
      <div className="problem-sample-input-box">
        {editable ? <TextArea
          autoSize
          value={sample.input}
          onChange={({ target }) => setSample(prevState => ({ ...prevState, input: target.value }))}
        /> : (
          <>
            <div>
              <span>{sample.input}</span>
            </div>
            <CopyToClipboard text={sample.input}>
              <CopyOutlined />
            </CopyToClipboard>
          </>
        )}
      </div>
      <div className="problem-sample-output-box">
        {editable ? <TextArea
          autoSize
          value={sample.output}
          onChange={({ target }) => setSample(prevState => ({ ...prevState, output: target.value }))}
        /> : (
          <>
            <div>
              <span>{sample.output}</span>
            </div>
            <CopyToClipboard text={sample.output}>
              <CopyOutlined />
            </CopyToClipboard>
          </>
        )}
      </div>
      {setProblem && (
        <div className="problem-sample-actions-box child-center">
          {editable ? (
            <>
              <DeleteOutlined
                onClick={() => setProblem(prevState => {
                  const newSamples = [...prevState.samples].filter((sample, sIndex) => sIndex !== index);
                  return { ...prevState, samples: newSamples };
                })}
              />
              <SaveOutlined onClick={onSave} />
            </>
          ) : <EditOutlined onClick={() => setEditable(true)} />}
        </div>
      )}
    </div>
  );
}