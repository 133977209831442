import React from 'react';
import { Pagination as AntPagination } from 'antd';
import { useLocation } from 'react-router';

import { history } from '../../../store/reducers';

import './Pagination.scss';
import { searchBuilder } from '../../../commons/helpers';
import { PAGE, PAGE_SIZE, PAGE_SIZES } from '~/commons/constants';

export const Pagination = ({ totalElements }: { totalElements: number }) => {

  const { search } = useLocation();

  const searchParams = new URLSearchParams(search);
  const current = Number.isNaN(+searchParams.getAll(PAGE)[0]) ? 1 : +searchParams.getAll(PAGE)[0];
  const pageSize = Number.isNaN(+searchParams.getAll(PAGE_SIZE)[0]) ? +PAGE_SIZES[0] : +searchParams.getAll(PAGE_SIZE)[0];

  return (
    <div className="layout-pagination child-center">
      <div className="content-pagination-main">
        <AntPagination
          // showQuickJumper
          showSizeChanger
          total={totalElements}
          onChange={(page, pageSize) => {
            if (!pageSize) {
              pageSize = +PAGE_SIZES[0];
            }
            history.push({ search: searchBuilder({ [PAGE]: [page], [PAGE_SIZE]: [pageSize] }) });
          }}
          pageSizeOptions={PAGE_SIZES}
          current={current}
          pageSize={pageSize}
        />
      </div>
    </div>
  );
};
