import React from 'react';
import { notification } from 'antd';
import { CheckCircleFilled, InfoCircleFilled, CloseCircleFilled } from '@ant-design/icons';

import './styles.scss';

export const SuccessNotification = ({ title, description, duration = 5 }: { title?: string, description: string, duration?: number }) => {
  notification.success({
    message: title || 'Success',
    description: description,
    duration: duration,
    className: 'success-notification',
    icon: <CheckCircleFilled />
  });
};

export const InfoNotification = ({ title, description, duration = 3.5 }: { title?: string, description: string, duration?: number }) => {
  notification.info({
    message: title || 'Info',
    description,
    duration: duration,
    className: 'info-notification',
    icon: <InfoCircleFilled />
  });
};

export const WarningNotification = ({ title, description, duration = 3.5 }: { title?: string, description: string, duration?: number }) => {
  notification.warning({
    message: title || 'Warning',
    description,
    duration: duration,
    className: 'warning-notification',
    icon: <InfoCircleFilled />
  });
};

export const ErrorNotification = ({ title, description, duration = 5 }: { title?: string, description: string, duration?: number }) => {
  notification.error({
    message: title || 'Error',
    description,
    duration: duration,
    className: 'error-notification',
    icon: <CloseCircleFilled />
  });
};
