import React, { useRef, useState } from 'react';
import ReactCrop from 'react-image-crop';

import { CroppedImage, ImageUploaderProps } from './types';

import 'react-image-crop/dist/ReactCrop.css';
import './styles.scss';

export const ImageUploader = ({ defaultCrop, action }: ImageUploaderProps) => {
  
  const [crop, setCrop] = useState<ReactCrop.Crop>(defaultCrop || {
    unit: '%',
    width: 100,
    height: 100,
    x: 0,
    y: 0
  });
  
  const [src, setSrc] = useState<string | ArrayBuffer | null>(null);
  const [croppedImage, setCroppedImage] = useState<CroppedImage>({ url: '', blob: null });
  const imageRef = useRef();
  
  const getCroppedImg = (image: any, crop: any, fileName: any) => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');
    
    ctx && ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
    
    return new Promise<{ url: string, blob: Blob }>((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error('Canvas is empty');
          return;
        }
        // blob.name = fileName;
        // window.URL.revokeObjectURL(blob.fileUrl);
        const fileUrl = window.URL.createObjectURL(blob);
        resolve({ url: fileUrl, blob });
      }, 'image/jpeg');
    });
  };
  const makeClientCrop = async (crop: any) => {
    if (imageRef.current && crop.width && crop.height) {
      setCroppedImage(await getCroppedImg(
        imageRef.current,
        crop,
        'newFile.jpeg'
      ));
    }
  };
  const onCropChange = (crop: any, percentCrop: any) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    setCrop(crop);
  };
  const onSelectFile = ({ target }: { target: HTMLInputElement }) => {
    if (target.files && target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => setSrc(reader.result)
      );
      reader.readAsDataURL(target.files[0]);
    }
  };
  
  return (
    <div className="load-crop-image">
      <div className="input-box">
        <input type="file" accept="image/*" onChange={onSelectFile} className="juki-btn" />
      </div>
      <div className="crop-preview-box">
        <div className="crop-box">
          <div>
            {src && (
              <ReactCrop
                src={src as string}
                crop={crop}
                ruleOfThirds
                onImageLoaded={(image: any) => imageRef.current = image}
                onComplete={(crop) => makeClientCrop(crop)}
                onChange={onCropChange}
              />
            )}
          </div>
        </div>
        <div className="preview-box child-center">
          {croppedImage.url && (
            <img alt="Crop" style={{ maxWidth: '100%' }} src={croppedImage.url} />
          )}
        </div>
      </div>
      <div className="actions-box child-center">
        {action(croppedImage)}
      </div>
    </div>
  );
};

export * from './types';
