import React, { useEffect, useState } from 'react';
import { Col, Row, Upload } from 'antd';
import { CloudUploadOutlined, ReloadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { InfoNotification, LoaderButton, MdMathViewer } from '~/components';
import { PROBLEM_INPUT, PROBLEM_OUTPUT, PROBLEM_STATUS, PROGRAMMING_LANGUAGES } from '~/config/constants';
import { useAccountState, useFlagsDispatch, useRouter } from '~/hooks';
import { classNames } from '~/helpers';
import { ContestParamsType, NotificationType, ProblemInput, ProblemMode, ProblemOutput, ProblemTab, ProgrammingLanguage, Status } from '~/types';
import { apiContestRejudgeProblem } from '~/services';
import { SampleTest } from '../../Create/Statement/SampleTest';
import { StatementProps } from './types';

import './styles.scss';

const { Dragger } = Upload;

export const Statement = ({ problem, setSource, canSubmit, canRegister, canRejudge, languages }: StatementProps) => {
  
  const { params, push } = useRouter<{ params: ContestParamsType }>();
  const { isLogged } = useAccountState();
  const [files, updateFiles] = useState<Array<any>>([]);
  const { updateFlags } = useFlagsDispatch();
  const { t } = useTranslation();
  const { key, subTab } = params;
  useEffect(() => {
    if (files.length) {
      let reader = new FileReader();
      reader.readAsText(files[0]);
      reader.onload = ({ target }: any) => {
        setSource(prevState => ({ ...prevState, plainText: target.result.toString() }));
        InfoNotification({ description: `${files[0].name} file LOADED successfully.` });
        push(ProblemTab.EDITOR);
      };
    }
  }, [files, push, setSource]);
  
  const props = {
    name: 'file',
    fileList: files,
    multiple: false,
    removeIcon: false,
    beforeUpload: (file: any) => false,
    onChange(info: any) {
      const { name, type, status } = info.file;
      if (status === 'removed') {
        updateFiles([]);
      } else {
        const nameSplited = name.split('.');
        const language = (languages || [] as Array<ProgrammingLanguage>)
          .find((lang) => (
            type === PROGRAMMING_LANGUAGES[lang].mime || (PROGRAMMING_LANGUAGES[lang].fileExtension.find(fExt => nameSplited[nameSplited.length - 1] === fExt))
          ));
        if (!language) {
          updateFlags({
            lastNotification: {
              type: NotificationType.ERROR,
              description: name + ' ' + t('is not a valid file')
            }
          })();
          updateFiles([]);
        } else {
          setSource(prevState => ({ ...prevState, language }));
          updateFiles([info.file]);
        }
      }
    }
  };
  
  return (
    <>
      <Row className="content" justify="space-between">
        <Col lg={24} md={26} sm={35} className="juki-card">
          <div className="problem-head-box text-xh bold child-center">
            {subTab && <div className="index shadow">{subTab}</div>}
            {subTab && <h4 className="title">{problem.name}</h4>}
          </div>
          <div className="problem-description-box">
            <h6>{t('description')}</h6>
            <MdMathViewer source={problem?.description?.general} />
          </div>
          <div className="problem-input-description-box">
            <h6>{t('input')}</h6>
            <MdMathViewer source={problem?.description?.input} />
          </div>
          <div className="problem-output-description-box">
            <h6>{t('output')}</h6>
            <MdMathViewer source={problem?.description?.output} />
          </div>
          <div className="problem-samples-box">
            <div className="problem-sample-box">
              <div className="problem-sample-input-box text-h bold">{t('input sample')}</div>
              <div className="problem-sample-output-box text-h bold">{t('output sample')}</div>
            </div>
            {(problem.samples || [{ input: '', output: '' }]).map((sample, index) => (
              <SampleTest index={index} problem={problem} key={index} />
            ))}
          </div>
        </Col>
        <Col lg={10} md={8} sm={35}>
          <div className="problem-limits juki-card">
            <p>
              <span className="text-l bold">{t('time') + ' ' + t('limit')}:</span>
              {(problem?.settings?.timeLimit / 1000).toFixed(1)} s
            </p>
            <p>
              <span className="text-l bold">{t('memory') + ' ' + t('limit')}:</span>
              {(problem?.settings?.memoryLimit / 1000).toFixed(1)} MB
            </p>
            <p>
              <span className="text-l bold">{t('input')}:</span>
              {t(PROBLEM_INPUT[problem?.settings?.typeInput || ProblemInput.STANDARD].print)}
            </p>
            <p>
              <span className="text-l bold">{t('output')}:</span>
              {t(PROBLEM_OUTPUT[problem?.settings?.typeOutput || ProblemOutput.STANDARD].print)}
            </p>
            <p>
              <span className="text-l bold">{t('mode')}:</span>
              {problem.settings.mode === ProblemMode.POINTS ? <div>
                  <ul>
                    {Object.keys(problem?.settings?.groupsPoint || {}).map(key => (
                      <li>
                        <span className="text-m semi-bold">{t('subtask') + ' ' + key}</span>:
                        {problem?.settings?.groupsPoint[key]}
                      </li>
                    ))}
                  </ul>
                  <span className="text-m semi-bold">{t('total')}:</span>
                  {Object.values(problem?.settings?.groupsPoint || {}).reduce((sum, value) => sum + value, 0)
                  + ' ' + t('points')}
                </div>
                : t('all or nothing')}
            </p>
            {problem?.tags && (<p><span className="text-l bold">{t('tags')}:</span>{problem.tags.join(', ')}</p>)}
            {problem?.author && (<p><span className="text-l bold">{t('author')}:</span>{problem.author}</p>)}
            {problem?.status && (
              <p><span className="text-l bold">{t('visibility')}:</span>{t(PROBLEM_STATUS[problem.status].print)}</p>)}
            {canRejudge && (
              <div className="problem-rejudge">
                <LoaderButton
                  onClick={setLoader => async () => {
                    if (subTab) {
                      setLoader([1, Status.LOADING]);
                      const result = await apiContestRejudgeProblem(key, subTab)();
                      if (result.success === Status.SUCCESS) {
                        setLoader([1, Status.SUCCESS]);
                        updateFlags({
                          lastNotification: {
                            type: NotificationType.INFO,
                            description: t('successfully') + ' ' + result.total + t('submissions rejudged')
                          }
                        });
                      } else {
                        setLoader([1, Status.ERROR]);
                        updateFlags({
                          lastNotification: {
                            type: NotificationType.ERROR,
                            description: result.message
                          }
                        });
                      }
                    }
                  }}
                  icon={<ReloadOutlined />}
                  block
                >
                  {t('rejudge') + ' ' + t('problem')}
                </LoaderButton>
              </div>
            )}
          </div>
          {canSubmit && isLogged ? (
            <div className="problem-submit juki-card">
              <div className={classNames('drag-file', { 'child-center': !isLogged })}>
                <Dragger {...props}>
                  <p>{t('add code document')}</p>
                  <CloudUploadOutlined />
                  <p className="text-s bold">
                    {problem.settings.languages.map(lang => (
                      PROGRAMMING_LANGUAGES[lang] ? PROGRAMMING_LANGUAGES[lang].fileExtension.map(fe => '.' + fe).join(' ') : ''
                    ))
                      .reduce((result, lan) => (result.indexOf(lan) === -1 ? [...result, lan] : result), [] as Array<string>)
                      .join((' '))}
                  </p>
                  <p>{t('drop file to upload or browse file')}</p>
                </Dragger>
              </div>
            </div>
          ) : !isLogged ? (
            <LoaderButton
              onClick={() => updateFlags({ openLoginModal: true })}
              type="primary"
              className="color-secondary"
              block
            >
              {t('to submit, first login')}
            </LoaderButton>
          ) : null}
        </Col>
      </Row>
    </>
  );
};
