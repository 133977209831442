import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { Button, Col, Row, Tabs, Tooltip } from 'antd';
import { CheckOutlined, LoadingOutlined, UserOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { Card, JukiLayout, TimerClock } from '~/components';
import { history } from '~/store/reducers';
import { ROUTES, NONE, LOADING, SEC_90 } from '~/config/constants';
import { useAccountState, useContestDispatch, useContestsState, useInterval } from '~/hooks';
import { ContestTab, ContestTimeStatus, LoaderState } from '~/types';
import { PastContests } from './PastContests';
import { can } from '~/helpers';

import './styles.scss';

const { TabPane } = Tabs;

export const Contests = () => {
  
  const { type } = useParams<{ type: ContestTimeStatus }>();
  const { loadAllContest } = useContestDispatch();
  const contests = useContestsState();
  const account = useAccountState();
  const [loading1, setLoading1] = useState<LoaderState>([new Date().getTime(), NONE]);
  const [loading2, setLoading2] = useState<LoaderState>([new Date().getTime(), NONE]);
  const [loading3, setLoading3] = useState<LoaderState>([new Date().getTime(), NONE]);
  const { t } = useTranslation();
  const firstRender = useRef(true);
  
  useEffect(() => {
    const fun = async () => {
      if (firstRender.current) {
        const order = [ContestTimeStatus.LIVE, ContestTimeStatus.UPCOMING, ContestTimeStatus.PAST]
          .filter(t => t !== type);
        order.unshift(type);
        firstRender.current = false;
        for (const t of order) {
          await loadAllContest(t, t === ContestTimeStatus.LIVE ?
            setLoading1 : t === ContestTimeStatus.UPCOMING ?
              setLoading2 : setLoading3)();
        }
      }
    };
    fun().then();
  }, [type, loadAllContest, account]);
  useInterval(() => {
    loadAllContest(type, type === ContestTimeStatus.LIVE ?
      setLoading1 : type === ContestTimeStatus.UPCOMING ?
        setLoading2 : setLoading3)();
  }, SEC_90);
  
  const callback = (key: ContestTimeStatus) => {
    loadAllContest(key, key === ContestTimeStatus.LIVE ?
      setLoading1 : key === ContestTimeStatus.UPCOMING ?
        setLoading2 : setLoading3)();
    history.push(ROUTES.CONTESTS.LIST_PAGE(key));
  };
  
  return (
    <>
      <JukiLayout type="title" className="title-with-nav-tab">
        <div className="content-title ">
          <h3>{t('contests')}</h3>
          {can.createContest(account) && (
            <Link to={ROUTES.CONTESTS.CREATE(ContestTab.OVERVIEW)} className="button-create-problem">
              <Button type="ghost">{t('create')}</Button>
            </Link>
          )}
        </div>
        <div className="juki-nav-tab-title">
          <Tabs activeKey={type} onChange={(key) => callback(key as ContestTimeStatus)}>
            {[ContestTimeStatus.LIVE, ContestTimeStatus.UPCOMING, ContestTimeStatus.PAST].map(contestType => (
              <TabPane
                tab={
                  <div className="tab-label">
                    {t(contestType + 's')}
                    {(contestType === ContestTimeStatus.LIVE ?
                      loading1[1] : contestType === ContestTimeStatus.UPCOMING ?
                        loading2[1] : loading3[1]) === LOADING ? <LoadingOutlined /> :
                      <div className="tab-badge">
                        {contests[contestType].length > 99 ?
                          <span className="text-xs">+99</span> : contests[contestType].length}
                      </div>}
                  </div>
                }
                key={contestType}
              />
            ))}
          </Tabs>
        </div>
      </JukiLayout>
      <JukiLayout>
        {type === ContestTimeStatus.PAST ? (
          <PastContests contests={contests[type]} />
        ) : (
          <Row gutter={[0, 32]} className="content">
            {contests[type].map((contest, index) => (
              <Col lg={8} md={11} sm={17} offset={1}>
                <Card
                  contentHead={
                    <div className="users-badge child-center">
                      <UserOutlined />
                      <span className="text-s bold">{contest.totalRegistered || '0'}</span>
                      {account.isLogged && contest.registered && (
                        <Tooltip title={t('registered')}>
                          <CheckOutlined />
                        </Tooltip>
                      )}
                    </div>
                  }
                  contentTitle={
                    <div className="text-l bold">{contest.name}</div>
                  }
                  contentInfo={
                    <TimerClock
                      startDate={new Date(contest.settings.start)}
                      endDate={new Date(contest.settings.start + contest.timing.duration)}
                      currentDate={new Date()}
                    />
                  }
                  contentAction={type === ContestTimeStatus.UPCOMING ? (
                    <>
                      {contest.canUpdate ? (
                        <Link to={ROUTES.CONTESTS.VIEW(contest.key, ContestTab.OVERVIEW)}>
                          <Button className="sign-up-button">{t('enter')}</Button>
                        </Link>
                      ) : (
                        <Link to={ROUTES.CONTESTS.VIEW(contest.key, ContestTab.OVERVIEW)}>
                          <Button className="sign-up-button">{t('overview')}</Button>
                        </Link>
                      )}
                    </>
                  ) : (
                    <>
                      {contest.canRegister || contest.canUpdate || contest.registered ? (
                        <Link to={ROUTES.CONTESTS.VIEW(contest.key, ContestTab.OVERVIEW)}>
                          <Button type="primary" className="sign-up-button">{t('enter')}</Button>
                        </Link>
                      ) : contest.canViewScoreBoard ? (
                        <Link to={ROUTES.CONTESTS.VIEW(contest.key, ContestTab.SCOREBOARD)}>
                          <Button type="primary" className="sign-up-button">{t('view scoreboard')}</Button>
                        </Link>
                      ) : null}
                    </>
                  )}
                  key={contest.key}
                />
              </Col>
            ))}
          </Row>
        )}
      </JukiLayout>
    </>
  );
};
