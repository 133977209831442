import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Select, Tag } from 'antd';

import { Loader, MonacoEditor } from '~/components';
import { apiContestJudgeSubmission, apiContestSourceSubmission, apiProblemCodeSubmissions } from '~/services';
import { Code, ContestTab, NotificationType, ProblemVerdict, ProfileSettingOptions, Theme } from '~/types';
import { useAccountState, useFlagsDispatch, useRouter } from '~/hooks';
import { dateToDDMMYYY, dateToHHMM } from '~/helpers';
import { DEFAULT_CODE, PROBLEM_VERDICTS, PROGRAMMING_LANGUAGES, ROUTES, SUCCESS, VERDICT } from '~/config/constants';
import { ViewSourceCodeModalProps } from './types';

import './styles.scss';

const { Option } = Select;
const { useModal } = Modal;

export const ViewSourceCodeModal = ({ data, closeModal }: ViewSourceCodeModalProps) => {
  
  const { push } = useRouter();
  const { mySettings } = useAccountState();
  const [loader, setLoader] = useState(true);
  const [code, setCode] = useState<Code>(DEFAULT_CODE);
  const [modal, contextHolder] = useModal();
  const { updateFlags } = useFlagsDispatch();
  const { t } = useTranslation();
  const { submission } = data;
  
  useEffect(() => {
    const load = async () => {
      setLoader(true);
      if (submission.contestKey) {
        const result = await apiContestSourceSubmission(submission.contestKey, submission.submitId)();
        if (result.success === SUCCESS) {
          setCode(result.object);
        } else {
          updateFlags({
            lastNotification: {
              type: NotificationType.ERROR,
              description: result.message
            }
          })();
        }
      } else {
        const result = await apiProblemCodeSubmissions(submission.submitId)();
        if (result.success === SUCCESS) {
          setCode(result.object);
        } else {
          updateFlags({
            lastNotification: {
              type: NotificationType.ERROR,
              description: result.message
            }
          })();
        }
      }
      setLoader(false);
    };
    load().then();
  }, [submission.contestKey, submission.submitId, updateFlags]);
  
  return (
    <Modal visible footer={null} className="modal-code" onCancel={closeModal} centered>
      {contextHolder}
      <Loader
        loading={loader} component={() => (
        <>
          <div className="content-description">
            <div>
              <div className="display-label">
                {submission.problemName}
                <label className="text-xs semi-bold">{t('problem name')}</label>
              </div>
              <div className="display-label">
                <div className="text-m bold">
                  {data.type === 1 ? (
                    <Tag color={VERDICT[submission.answer]?.color || 'gray'}>
                      {t(VERDICT[submission.answer as ProblemVerdict] ? VERDICT[submission.answer as ProblemVerdict].print : '[' + submission.answer + ']') + (submission.answer === ProblemVerdict.PA ? ` (${submission.submitPoints})` : '')}
                    </Tag>
                  ) : (
                    <Select
                      onChange={(verdict: ProblemVerdict) => {
                        modal.confirm({
                          title: t('are you sure?'),
                          content: (
                            <>{t('a judge cannot change his verdict')}</>
                          ),
                          centered: true,
                          onOk: async () => {
                            if (submission.contestKey) {
                              const result = await apiContestJudgeSubmission(submission.contestKey, submission.submitId, verdict)();
                              if (result.success === SUCCESS) {
                                updateFlags({
                                  lastNotification: {
                                    type: NotificationType.SUCCESS,
                                    title: t('success'),
                                    description: t('success')
                                  }
                                })();
                                closeModal();
                                push(ROUTES.CONTESTS.VIEW(submission.contestKey, ContestTab.STATUS))();
                                push(ROUTES.CONTESTS.VIEW(submission.contestKey, ContestTab.JUDGE))();
                              } else {
                                updateFlags({
                                  lastNotification: {
                                    type: NotificationType.ERROR,
                                    title: t('error'),
                                    description: result.message
                                  }
                                })();
                              }
                            }
                          }
                        });
                      }}
                      value={submission.answer}
                      style={{ width: 120 }}
                      className="programming-language-selector"
                    >
                      {PROBLEM_VERDICTS.map((verdict) => <Option value={verdict}>{VERDICT[verdict].print}</Option>)}
                    </Select>
                  )}
                </div>
                <label className="text-xs semi-bold">{t('verdict')}</label>
              </div>
            </div>
            <div>
              <div className="display-label">
                {dateToDDMMYYY(new Date(submission.when)) + dateToHHMM(new Date(submission.when))}
                <label className="text-xs semi-bold">{t('date')}</label>
              </div>
              <div>
                <div className="display-label">
                  {submission.timeUsed} ms
                  <label className="text-xs semi-bold">{t('time used')}</label>
                </div>
                <div className="display-label">
                  {submission.memoryUsed} KB
                  <label className="text-xs semi-bold">{t('memory used')}</label>
                </div>
              </div>
            </div>
          </div>
          <div className="content-monaco-editor">
            <MonacoEditor
              language={PROGRAMMING_LANGUAGES[submission.language].monaco}
              source={code.source}
              dark={mySettings[ProfileSettingOptions.THEME] === Theme.DARK}
              height="100%"
            />
          </div>
        </>
      )}
      />
    </Modal>
  );
};
