import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Link, Redirect, useParams } from 'react-router-dom';
import { CaretLeftOutlined, InfoCircleOutlined, SettingOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

import { ProblemNewState, ProblemState } from '~/store/types';
import {
  FieldEditable,
  JukiLayout,
  JukiSider,
  Loader,
  LoaderButton, MenuSideBar
} from '~/components';
import { LOADING, PROBLEM_TAB, ROUTES } from '~/config/constants';
import { can } from '~/helpers';
import { useAccountState, useProblemsDispatch, useProblemState } from '~/hooks';
import { ProblemCreateSetup } from '../Create/Setup';
import { ContestCreateTests } from './Tests';
import { LoaderState, ProblemTab } from '~/types';
import { ProblemCreateStatement } from '../Create/Statement';

export const ProblemEdit = () => {
  
  const account = useAccountState();
  const { key, tab } = useParams<{ key: string, tab: ProblemTab }>();
  const { loadProblem, updateProblem } = useProblemsDispatch();
  const problem = useProblemState(key);
  const [problemNew, setProblemNew] = useState<ProblemState>(problem);
  const [loading, setLoading] = useState<LoaderState>([new Date().getTime(), LOADING]);
  const { t } = useTranslation();
  useEffect(() => {
    loadProblem(key, setLoading)();
  }, [key, loadProblem]);
  useEffect(() => setProblemNew(problem), [problem]);
  
  if (!can.createProblem(account)) {
    return <Redirect to={'/'} />;
  }
  const menuSideBarItems = [{
    link: ROUTES.PROBLEMS.EDIT(key, ProblemTab.STATEMENT),
    selected: tab === ProblemTab.STATEMENT,
    icon: <InfoCircleOutlined />,
    label: PROBLEM_TAB[ProblemTab.STATEMENT].print
  }, {
    link: ROUTES.PROBLEMS.EDIT(key, ProblemTab.SETUP),
    selected: tab === ProblemTab.SETUP,
    icon: <SettingOutlined />,
    label: PROBLEM_TAB[ProblemTab.SETUP].print
  }, {
    link: ROUTES.PROBLEMS.EDIT(key, ProblemTab.TESTS),
    selected: tab === ProblemTab.TESTS,
    icon: <UnorderedListOutlined />,
    label: PROBLEM_TAB[ProblemTab.TESTS].print
  }];
  
  return (
    <Loader
      loading={!(problemNew && (loading[1] !== LOADING))}
      component={() => (
        <JukiSider
          sideBar={(isClosed: boolean) => (
            <>
              <JukiLayout type="title">
                <Link to={ROUTES.PROBLEMS.VIEW(key, ProblemTab.STATEMENT)}>
                  <Button icon={<CaretLeftOutlined />} type="text">
                    {isClosed ? '' : t('cancel and back')}
                  </Button>
                </Link>
              </JukiLayout>
              <JukiLayout>
                <MenuSideBar items={menuSideBarItems} isClosed={isClosed} />
              </JukiLayout>
            </>
          )}
          sideMain={() => (
            <>
              <JukiLayout type="title">
                <div className="text-xh bold">
                  Title: {tab !== ProblemTab.STATEMENT && problemNew.name}
                  {tab === ProblemTab.STATEMENT && (
                    <FieldEditable
                      value={problemNew.name}
                      onChange={(value) => setProblemNew(prevState => ({ ...prevState, name: value }))}
                    />
                  )}
                </div>
                {tab !== ProblemTab.TESTS && (
                  <LoaderButton
                    type="primary"
                    className="color-primary-light"
                    onClick={(setLoader) => () => updateProblem(problemNew, setLoader)()}
                  >
                    UPDATE PROBLEM
                  </LoaderButton>
                )}
              </JukiLayout>
              <JukiLayout>
                {tab === ProblemTab.STATEMENT && (
                  <ProblemCreateStatement
                    problem={problemNew}
                    setProblem={setProblemNew as Dispatch<SetStateAction<ProblemNewState>>}
                  />
                )}
                {tab === ProblemTab.SETUP && (
                  <ProblemCreateSetup
                    problem={problemNew}
                    setProblem={setProblemNew as Dispatch<SetStateAction<ProblemNewState>>}
                  />
                )}
                {tab === ProblemTab.TESTS && (<ContestCreateTests />)}
              </JukiLayout>
            </>
          )}
        />
      )}
    />
  );
};
