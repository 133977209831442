import { useEffect, useMemo, useRef } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { useLocation } from 'react-router';

//https://overreacted.io/making-setinterval-declarative-with-react-hooks/
export function useInterval(callback: Function, delay: number) {
  const savedCallback = useRef<undefined | Function>();
  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);
  
  // Set up the interval.
  useEffect(() => {
    function tick() {
      if (savedCallback.current) {
        savedCallback.current();
      }
    }
    
    if (delay) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

interface Router {
  match?: {};
  location?: {};
  params?: {};
  history?: {};
}

// https://usehooks.com/useRouter/ + Juani
export const useRouter = <T extends Router>() => {
  const params = useParams<T['params'] & {}>();
  const location = useLocation<T['location']>();
  const history = useHistory<T['history']>();
  const match = useRouteMatch<T['match'] & {}>();
  
  // Return our custom router object
  // Memoize so that a new object is only returned if something changes
  return useMemo(() => {
    return {
      // For convenience add push(), replace(), pathname at top level
      push: (pathname: string/*, search: any*/) => () => history.push({ pathname }),
      replace: history.replace,
      pathname: location.pathname,
      search: location.search,
      // Include match, location, history objects so we have
      // access to extra React Router functionality if needed.
      match,
      location,
      history,
      params,
      pathnameParams: location.pathname.split('/')
    };
  }, [params, match, location, history]);
};