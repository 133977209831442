import React, { useEffect, useState } from 'react';
import { Link, Redirect, useParams } from 'react-router-dom';
import ResizeObserver from 'rc-resize-observer';
import { Table, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import { useAccountState, useContestDispatch, useContestState, useInterval } from '~/hooks';
import { ReactComponent as Balloon } from '~/graphics/balloon.svg';
import { DEFAULT_JUKI_PROFILE_IMAGE, LOADING, NONE, ROUTES, SEC_30 } from '~/config/constants';
import { ContestParamsType, ContestSettingsParams, ContestTab, LoaderState, ProblemTab, ProfileTab } from '~/types';
import { LineLoader, LoaderIcon } from '~/components/shared';
import { classNames } from '~/helpers';
import { BASE_URL } from '~/services';

import './styles.scss';

export const Scoreboard = () => {
  
  const { key } = useParams<ContestParamsType>();
  const { nickname } = useAccountState();
  const { problems, canViewScoreBoard, settings, scoreboard, timing } = useContestState(key);
  const { loadScoreboard } = useContestDispatch();
  const [loading, setLoading] = useState<LoaderState>([new Date().getTime(), NONE]);
  const { t } = useTranslation();
  const [size, setSize] = useState({ width: 0, height: 0 });
  useEffect(() => {
    if (canViewScoreBoard && settings.start < new Date().getTime()) {
      loadScoreboard(key, setLoading)();
    }
  }, [canViewScoreBoard, key, loadScoreboard, settings.start]);
  const isFrozen = settings[ContestSettingsParams.FROZEN] && new Date().getTime() - settings.start > timing.frozen;
  useInterval(() => {
    loadScoreboard(key, setLoading)();
  }, (canViewScoreBoard &&
    settings.start < new Date().getTime() &&
    new Date().getTime() - (3 * SEC_30) <= settings.start + timing.duration &&
    !isFrozen
  ) ? SEC_30 : 0);
  
  if (!canViewScoreBoard) {
    return <Redirect to={ROUTES.CONTESTS.VIEW(key, ContestTab.OVERVIEW)} />;
  }
  
  const columns = [
    {
      title: () => <div>#</div>,
      dataIndex: '#',
      width: 54, // 30 + 8 + 16
      fixed: true,
      render: (position: number) => (
        <div className={classNames('cell-body-user-position', { 'no-points': position <= 0 })}>
          <div className="text-m semi-bold"> {position <= 0 ? '-' : position} </div>
        </div>
      )
    },
    {
      title: () => <div />,
      dataIndex: 'userPhoto',
      width: 58, // 42 + 16
      render: (imageUrl: string) => (
        <div className="cell-body-user-photo text-l semi-bold"><img src={imageUrl || DEFAULT_JUKI_PROFILE_IMAGE}
                                                                    alt="user-profile" /></div>
      ),
      fixed: true
    },
    {
      title: () => <div>{t('nickname')}</div>,
      dataIndex: 'userNickname',
      width: 144, // 128 + 16
      fixed: true,
      render: (name: string) => (
        <div className="cell-body-user-name">
          <a
            href={[BASE_URL, ROUTES.PARAMS.PROFILE, name, ProfileTab.PROFILE].join('/')}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="text-l semi-bold child-center">{name}</div>
          </a>
        </div>
      )
    },
    {
      title: () => <div>PTS</div>,
      dataIndex: 'points',
      width: 124, // 100 + 8 + 16
      render: ({ points, penalty }: { points: number, penalty: number }) => (
        <div className="cell-body-user-points child-center">
          <div className="text-l semi-bold">{points}</div>
          <div className="text-s">{penalty}</div>
        </div>),
      fixed: true
    },
    ...Object.values(problems ? problems : {}).map((problem => (
      {
        title: () => (
          <div className="head-cell-problem-index">
            <Tooltip title={problem.name}>
              <Link to={ROUTES.CONTESTS.VIEW(key, ContestTab.PROBLEM, problem.index, ProblemTab.STATEMENT)}>
                {problem.index}
              </Link>
            </Tooltip>
          </div>
        ),
        dataIndex: 'problem-' + problem.index,
        width: 100,
        render: ({
          attempts,
          time,
          color,
          isAccepted
        }: { attempts: number, time: number, color: string, isAccepted: boolean }) => (
          <div className="cell-body-user-attempt cell-body-center">
            <div className="balloon" style={!isAccepted ? { opacity: 0 } : undefined}><Balloon color={color} /></div>
            <div
              className="text-xs semi-bold child-center"> {attempts === -1 ? ' - ' : attempts} / {time === -1 ? ' - ' : time} </div>
          </div>
        )
      }
    )))
  ];
  
  const data = ((scoreboard && scoreboard.contestants) ? scoreboard.contestants : []).map(((row, index) => (
    {
      key: `place(${index + 1})${nickname === row.nickname ? '-row-sticky' : ''}`,
      '#': row.totalPoints <= 0 ? -1 : index + 1,
      userPhoto: row.imageUrl,
      userNickname: row.nickname,
      points: { points: row.totalPoints, penalty: row.totalPenalty },
      ...Object.values(problems).reduce((result, problem) =>
        ({
            ...result, ['problem-' + problem.index]: {
              attempts: (row.problems[problem.index] && row.problems[problem.index].attempts) || ' - ',
              time: (row.problems[problem.index] && row.problems[problem.index].time) || ' - ',
              color: problem.color,
              isAccepted: row.problems[problem.index] && row.problems[problem.index].success
            }
          }
        ), {})
    }
  )));
  // WITH VIRTUAL TABLE
  // const renderVirtualList = (rawData: Array<any>, { onScroll }: any) => {
  //   return (
  //     <Grid
  //       className="virtual-grid"
  //       columnCount={columns.length}
  //       columnWidth={(index: number) => {
  //         const { width } = columns[index];
  //         return width;
  //       }}
  //       height={size.height - 47}
  //       rowCount={rawData.length}
  //       rowHeight={() => 80}
  //       width={size.width}
  //       onScroll={({ scrollLeft }: any) => {
  //         onScroll({ scrollLeft });
  //       }}
  //     >
  //       {({ columnIndex, rowIndex, style }: any) => (
  //         <div
  //           className={classNames('virtual-table-cell', {
  //             'virtual-table-cell-last': columnIndex === columns.length - 1
  //           })}
  //           style={style}
  //         >
  //           <div className={'virtual-table-cell-' + columns[columnIndex].dataIndex}>
  //             {columns[columnIndex].render(rawData[rowIndex][columns[columnIndex].dataIndex])}
  //           </div>
  //         </div>
  //       )}
  //     </Grid>
  //   );
  // };
  
  return (
    <ResizeObserver onResize={({ width, height }) => setSize({ width, height })}>
      <div className="juki-table-layout scoreboard-layout">
        {isFrozen && <div className="frozen-label text-m bold">FROZEN</div>}
        {loading[1] === LOADING && data.length > 0 && <LineLoader />}
        <Table
          // components={{ body: renderVirtualList }}
          columns={columns}
          dataSource={data}
          //pagination={{size: (size.height - 48) /64}}
          scroll={{ x: size.width, y: size.height - 48 - 64 }} //47: head height // 64: paginate height
          loading={{ indicator: <LoaderIcon />, spinning: loading[1] === LOADING && data.length === 0 }}
          pagination={{ position: ['bottomCenter'] }}
        />
      </div>
    </ResizeObserver>
  );
};
