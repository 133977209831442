import React from 'react';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ResizeTable } from '../../../shared';
import { ContestState } from '../../../../store/types';
import { ROUTES } from '../../../../config/constants/routes';
import { ContestTab } from '../../../../commons/types';
import { dateToDDMMYYY, dateToHHMM } from '../../../../commons/helpers';

export const PastContests = ({ contests }: { contests: Array<ContestState> }) => {

  const { t } = useTranslation();

  enum DataIndex {
    KEY = 'key',
    CONTEST_TOTAL_REGISTERED = 'CONTEST_TOTAL_REGISTERED',
    CONTEST_NAME = 'CONTEST_NAME',
    CONTEST_TIME_START = 'CONTEST_TIME_START',
  }

  type RowData = {
    [DataIndex.KEY]: string,
    [DataIndex.CONTEST_TOTAL_REGISTERED]: number,
    [DataIndex.CONTEST_NAME]: { name: string, key: string },
    [DataIndex.CONTEST_TIME_START]: number,
  }

  const columns = [
    {
      title: ({ sortOrder, sortColumn }: { sortOrder: string, sortColumn: { dataIndex: DataIndex } }) => (
        <div className="head-cell-past-contest-date">
          {t('date')}
          {sortColumn?.dataIndex === DataIndex.CONTEST_TIME_START && (
            sortOrder === 'ascend' ? <ArrowUpOutlined /> : sortOrder === 'descend' ? <ArrowDownOutlined /> : null
          )}
        </div>
      ),
      dataIndex: DataIndex.CONTEST_TIME_START,
      render: (when: number) => (
        <div className="cell-body-when">
          <div className="date-style text-s bold">{dateToDDMMYYY(new Date(when))}</div>
          <div className="hour-style">{dateToHHMM(new Date(when))}</div>
        </div>
      ),
      width: '120px',
      sorter: (a: RowData, b: RowData) => a[DataIndex.CONTEST_TIME_START] - (b[DataIndex.CONTEST_TIME_START]),
      showSorterTooltip: false
    },
    {
      title: ({ sortOrder, sortColumn }: { sortOrder: string, sortColumn: { dataIndex: DataIndex } }) => (
        <div className="head-cell-past-contest-name">
          {t('contest name')}
          {sortColumn?.dataIndex === DataIndex.CONTEST_NAME && (
            sortOrder === 'ascend' ? <ArrowUpOutlined /> : sortOrder === 'descend' ? <ArrowDownOutlined /> : null
          )}
        </div>
      ),
      width: '300px',
      dataIndex: DataIndex.CONTEST_NAME,
      render: ({ name, key }: { name: string, key: string }) => (
        <div className="cell-body-link cell-body-left bold">
          <Link to={ROUTES.CONTESTS.VIEW(key, ContestTab.OVERVIEW)}>{name}</Link>
        </div>
      ),
      sorter: (a: RowData, b: RowData) => a[DataIndex.CONTEST_NAME].name.localeCompare(b[DataIndex.CONTEST_NAME].name),
      showSorterTooltip: false
    },
    {
      title: ({ sortOrder, sortColumn }: { sortOrder: string, sortColumn: { dataIndex: DataIndex } }) => (
        <div className="head-cell-past-contest-registered">
          {t('registereds')}
          {sortColumn?.dataIndex === DataIndex.CONTEST_TOTAL_REGISTERED && (
            sortOrder === 'ascend' ? <ArrowUpOutlined /> : sortOrder === 'descend' ? <ArrowDownOutlined /> : null
          )}
        </div>
      ),
      dataIndex: DataIndex.CONTEST_TOTAL_REGISTERED,
      render: (registered: number) => <div className="cell-contest-registered">{registered}</div>,
      width: '180px',
      sorter: (a: RowData, b: RowData) => a[DataIndex.CONTEST_TOTAL_REGISTERED] - b[DataIndex.CONTEST_TOTAL_REGISTERED],
      showSorterTooltip: false
    }
  ];
  const data: Array<RowData> = contests.map(contest => (
    {
      [DataIndex.KEY]: contest.key,
      [DataIndex.CONTEST_TIME_START]: contest.settings.start,
      [DataIndex.CONTEST_NAME]: { name: contest.name, key: contest.key },
      [DataIndex.CONTEST_TOTAL_REGISTERED]: contest.totalRegistered || 0
    }
  ));

  return (
    <>
      <ResizeTable
        className="content-past-contests"
        columns={columns}
        dataSource={data}
      />
    </>
  );
};
