import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Input, Modal, Radio, Select } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import { LoaderButton, LoaderIcon } from '~/components';
import { useAccountState, useContestDispatch, useContestState } from '~/hooks';
import {
  AnswerClarification,
  ContestParamsType,
  LoaderState,
  NewClarification
} from '~/types';
import { ReactComponent as QuestionIcon } from '~/graphics/question.svg';
import { LOADING, NONE, DEFAULT_JUKI_PROFILE_IMAGE } from '~/config/constants';
import { dateToHHMM, dateToMMMMMDDYYYY } from '~/helpers';

import './styles.scss';

const { Option } = Select;
const { TextArea } = Input;

type ModalType = { new: true, clarification: NewClarification } | { new: false, clarification: AnswerClarification };

export const Clarifications = () => {
  
  const { key } = useParams<ContestParamsType>();
  const { registered, canRejudge, clarifications, problems } = useContestState(key);
  const { isLogged } = useAccountState();
  const { submitContestClarification, loadContestClarifications, updateContestClarification } = useContestDispatch();
  const [loader, setLoader] = useState<LoaderState>([new Date().getTime(), NONE]);
  const [editModal, setEditModal] = useState<null | ModalType>(null);
  const { t } = useTranslation();
  useEffect(() => {
    loadContestClarifications(key, setLoader)();
  }, [key, loadContestClarifications]);
  
  return (
    <>
      <Modal
        visible={!!editModal}
        footer={null}
        className="modal-edit-clarification"
        onCancel={() => setEditModal(null)}
        centered
      >
        {editModal && (
          <>
            <div className="modal-clarification-box">
              <div className="text-xl semi-bold"> {t('ask question')} </div>
              <div className="modal-clarification-item">
                <div className="label-item text-s bold"> {t('problem index')} </div>
                {(canRejudge || editModal.new) ? (
                  <Select
                    onChange={problemIndex => setEditModal(prevState => (prevState && {
                      ...prevState,
                      clarification: { ...prevState.clarification, problem: problemIndex } as AnswerClarification
                    }))}
                    value={editModal.clarification.problem}
                    style={{ width: '100%' }}
                  >
                    <Option value={''}>{t('general')}</Option>
                    {Object.keys(problems).map(problemIndex => (
                      <Option value={problemIndex} key={problemIndex}> {problemIndex} </Option>
                    ))}
                  </Select>
                ) : (editModal.clarification.problem)}
              </div>
              <div className="modal-clarification-item">
                <div className="label-item text-s bold"> {t('question')} </div>
                {(canRejudge || editModal.new) ? (
                  <TextArea
                    autoSize={{ minRows: 1 }}
                    value={editModal.clarification.question}
                    onChange={({ target }) => setEditModal(prevState => (prevState && {
                      ...prevState,
                      clarification: { ...prevState.clarification, question: target.value } as AnswerClarification
                    }))}
                    placeholder={t('your question')}
                  />
                ) : (editModal.clarification.question)}
              </div>
              {canRejudge && (
                <>
                  <div className="modal-clarification-item">
                    <div className="label-item text-s bold">{t('answer')}</div>
                    <TextArea
                      autoSize={{ minRows: 3 }}
                      value={editModal.clarification.answer}
                      onChange={({ target }) => setEditModal(prevState => (prevState && {
                        ...prevState,
                        clarification: { ...prevState.clarification, answer: target.value } as AnswerClarification
                      }))}
                    />
                  </div>
                  <div className="modal-clarification-item">
                    <div className="label-item text-s bold">{t('select scope')}  </div>
                    <Radio.Group
                      onChange={({ target }) => {
                        setEditModal(prevState => (prevState && {
                          ...prevState,
                          clarification: {
                            ...prevState.clarification,
                            publicVisible: target.value === 'public'
                          } as AnswerClarification
                        }));
                      }}
                      value={editModal.clarification.publicVisible ? 'public' : 'private'}
                    >
                      <Radio value="public"> {t('all contestants')} </Radio>
                      <Radio value="private" disabled={editModal.new}> {t('contestant who asked')} </Radio>
                    </Radio.Group>
                  </div>
                </>
              )}
            </div>
            <div className="modal-clarification-buttons">
              <Button type="text" onClick={() => setEditModal(null)}>{t('cancel')}</Button>
              {editModal.new ? (
                <LoaderButton
                  onClick={(setLoader) => async () => {
                    await submitContestClarification(key, editModal.clarification, setLoader)();
                    loadContestClarifications(key, setLoader)();
                  }}
                >
                  {t(canRejudge ? 'announce' : 'ask')}
                </LoaderButton>
              ) : (
                <LoaderButton
                  onClick={(setLoader) => async () => {
                    await updateContestClarification(key, editModal.clarification.id, editModal.clarification, setLoader)();
                    loadContestClarifications(key, setLoader)();
                  }}
                >
                  {t('submit')}
                </LoaderButton>
              )}
            </div>
          </>
        )}
      </Modal>
      {(registered || canRejudge) && isLogged && (
        <div
          onClick={() => {
            setEditModal({
              new: true,
              clarification: {
                problem: 'A',
                question: '',
                answer: '',
                publicVisible: canRejudge
              }
            });
          }}
          className="ask-question"
        >
          <QuestionIcon /> {t('ask question')}
        </div>
      )}
      {loader[1] === LOADING && !clarifications.length ? <LoaderIcon /> : !clarifications.length ? (
        <div className="no-clarification-yet">{t('no clarifications yet')}</div>
      ) : (
        clarifications.sort((a, b) => b.id.localeCompare(a.id)).map(clarification => (
          <div className="clarification-card juki-card shadow">
            <div className="clarification-head">
              <div className="logo"><img src={DEFAULT_JUKI_PROFILE_IMAGE} alt="user-profile" /></div>
              <div className="data">
                <div className="user-section text-s">
                  <div className="user">{clarification.user}</div>
                </div>
                <div className="scope-date-section text-s">
                  <div className="scope">{clarification.publicVisible ? t('public') : canRejudge ?
                    t('only for the contestant') : t('only for you')} </div>
                  <div className="date">
                    {clarification.answered ?
                      dateToHHMM(new Date(clarification.answeredTime)) + ' ' + dateToMMMMMDDYYYY(new Date(clarification.answeredTime)) :
                      t('not answered')}
                  </div>
                </div>
              </div>
              <div className="problem-index">
                {clarification.problem ? (
                  <>
                    <span className="label text-s">{t('problem')}</span>
                    <div className="index text-xh bold">{clarification.problem}</div>
                  </>
                ) : <span className="label text-s">{t('general')}</span>}
              </div>
            </div>
            <div className="clarification-body">
              <div className="question text-xl semi-bold">{clarification.question || '-'}</div>
              {clarification.answered && (
                <div className="answer"><span className="label bold">{t('answer')}:</span>{clarification.answer}</div>
              )}
              {canRejudge && (
                <div className="edit">
                  <EditOutlined onClick={() => setEditModal({ new: false, clarification: { ...clarification } })} />
                </div>
              )}
            </div>
          </div>
        ))
      )}
    </>
  );
};
