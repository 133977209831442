import { ContestNewState, ContestsActions, ContestsListActions, ContestState } from '../types';
import {
  actionLoaderWrapper,
  apiContestChangeStatus,
  apiContestCreate,
  apiContestEdit,
  apiContestRegister,
  apiContestScoreboard,
  apiPostContestClarifications,
  apiUpdateContestClarification,
  getContest,
  getContestClarifications,
  getContestList,
  getContestMyStatus,
  getContestPendingStatus,
  getContestStatus
} from '../../services/juki-judge-back';
import { AnswerClarification, ContestStatus, ContestTab, ContestTimeStatus, LoaderAction, NewClarification, SearchParams } from '~/types';
import { SuccessNotification } from '~/components';
import { history, RootState } from '../reducers';
import { PAGED_ARRAY_EMPTY, ROUTES } from '~/config/constants';
import { updateFlags } from './flags';
import { fillPageParams, objectOfSearch } from '~/helpers';

const updateContests = (contests: Array<ContestState>) => {
  return {
    type: ContestsActions.UPDATE_CONTESTS,
    contests
  };
};

const replaceContestsList = (contests: Array<string>, timeStatus: ContestTimeStatus) => {
  return {
    type: ContestsListActions.REPLACE_CONTESTS_LIST,
    contests,
    timeStatus
  };
};

export const loadContestScoreboard = (key: string, setLoader?: LoaderAction) => {
  return async (dispatch: Function, getState: () => RootState) => {
    await actionLoaderWrapper(
      async () => {
        if (!getState().flags.requestingApiContestScoreboard) {
          dispatch(updateFlags({ requestingApiContestScoreboard: true }));
          const result = await apiContestScoreboard(key)();
          dispatch(updateFlags({ requestingApiContestScoreboard: false }));
          return result;
        }
      },
      dispatch,
      (result) => {
        if (result) {
          dispatch(updateContests([
            {
              scoreboard: {
                contestants: result.list,
                meta: { timeUpdated: new Date().getTime() }
              }, key
            } as ContestState
          ]));
        }
      },
      setLoader
    );
  };
};

export const loadContest = (key: string, setLoader?: LoaderAction) => {
  return async (dispatch: Function) => {
    await actionLoaderWrapper(
      getContest(key),
      dispatch,
      (result) => {
        dispatch(updateContests([result.object]));
      },
      setLoader,
      () => {
        history.push(ROUTES.CONTESTS.LIST_PAGE(ContestTimeStatus.LIVE));
      }
    );
  };
};

export const loadContestClarifications = (key: string, setLoader?: LoaderAction) => {
  return async (dispatch: Function, getState: () => RootState) => {
    await actionLoaderWrapper(
      getContestClarifications(key),
      dispatch,
      (result) => {
        if (JSON.stringify(result.list) !== JSON.stringify(getState()?.contests[key]?.clarifications || {})) {
          SuccessNotification({ title: 'UPDATE', description: 'Update on clarifications, review the clarifications' });
        }
        dispatch(updateContests([{ key, clarifications: result.list } as ContestState]));
      },
      setLoader
    );
  };
};

export const submitContestClarification = (key: string, body: NewClarification, setLoader?: LoaderAction) => {
  return async (dispatch: Function) => {
    await actionLoaderWrapper(
      apiPostContestClarifications(key, body),
      dispatch,
      () => {
        SuccessNotification({ description: 'Clarification successfully submit' });
      },
      setLoader
    );
  };
};
export const updateContestClarification = (key: string, idClarification: string, body: AnswerClarification, setLoader?: LoaderAction) => {
  return async (dispatch: Function) => {
    await actionLoaderWrapper(
      apiUpdateContestClarification(key, idClarification, body),
      dispatch,
      () => {
        SuccessNotification({ description: 'Clarification successfully updated!' });
      },
      setLoader
    );
  };
};

export const loadContestStatus = (key: string, search: string, clean: boolean, setLoader?: LoaderAction) => {
  return async (dispatch: Function, getState: () => RootState) => {
    if (clean) {
      dispatch(updateContests([{ key, submissions: { ...PAGED_ARRAY_EMPTY } } as ContestState]));
    }
    await actionLoaderWrapper(
      async () => {
        if (!getState().flags.requestingApiContestStatus || clean) {
          const now = new Date().getTime();
          dispatch(updateFlags({ requestingApiContestStatus: now }));
          const result = await getContestStatus(key, fillPageParams(objectOfSearch(search)))();
          if (getState().flags.requestingApiContestStatus === now) {
            dispatch(updateFlags({ requestingApiContestStatus: 0 }));
          }
          return result;
        }
      },
      dispatch,
      (result) => {
        if (result) {
          dispatch(updateContests([
            {
              key, submissions: {
                list: result.object.content,
                pageNumber: result.object.number,
                pageSize: result.object.size,
                totalPages: result.object.totalPages,
                totalElements: result.object.totalElements
              }
            } as ContestState
          ]));
        }
      },
      setLoader,
      () => {
        history.push(ROUTES.CONTESTS.VIEW(key, ContestTab.OVERVIEW));
      }
    );
  };
};

export const loadContestPendingStatus = (key: string, search: string, clean: boolean, setLoader?: LoaderAction) => {
  return async (dispatch: Function, getState: () => RootState) => {
    if (clean) {
      dispatch(updateContests([{ key, pendingSubmissions: { ...PAGED_ARRAY_EMPTY } } as ContestState]));
    }
    await actionLoaderWrapper(
      async () => {
        if (!getState().flags.requestingApiContestPendingStatus || clean) {
          const now = new Date().getTime();
          dispatch(updateFlags({ requestingApiContestPendingStatus: now }));
          const result = await getContestPendingStatus(key, fillPageParams(objectOfSearch(search)))();
          if (getState().flags.requestingApiContestPendingStatus === now) {
            dispatch(updateFlags({ requestingApiContestPendingStatus: 0 }));
          }
          return result;
        }
      },
      dispatch,
      (result) => {
        if (result) {
          dispatch(updateContests([
            {
              key,
              pendingSubmissions: {
                list: result.list,
                pageNumber: 1, //result.object.number,
                pageSize: 100, //result.object.size,
                totalPages: 1, //result.object.totalPages,
                totalElements: result.list.length //result.object.totalElements
              }
            } as ContestState
          ]));
        }
      },
      setLoader,
      () => {
        history.push(ROUTES.CONTESTS.VIEW(key, ContestTab.OVERVIEW));
      }
    );
  };
};

export const loadContestMyStatus = (key: string, search: string, clean: boolean, setLoader?: LoaderAction) => {
  return async (dispatch: Function, getState: () => RootState) => {
    if (clean) {
      dispatch(updateContests([{ key, mySubmissions: { ...PAGED_ARRAY_EMPTY } } as ContestState]));
    }
    await actionLoaderWrapper(
      async () => {
        if (!getState().flags.requestingApiContestMySubmissions || clean) {
          const now = new Date().getTime();
          dispatch(updateFlags({ requestingApiContestMySubmissions: now }));
          const result = await getContestMyStatus(key, fillPageParams(objectOfSearch(search)))();
          if (now === getState().flags.requestingApiContestMySubmissions) {
            dispatch(updateFlags({ requestingApiContestMySubmissions: 0 }));
          }
          return result;
        }
      },
      dispatch,
      (result) => {
        if (result) {
          dispatch(updateContests([
            {
              key, mySubmissions: {
                list: result.object.content,
                pageNumber: result.object.number,
                pageSize: result.object.size,
                totalPages: result.object.totalPages,
                totalElements: result.object.totalElements
              }
            } as ContestState
          ]));
        }
      },
      setLoader,
      () => {
        history.push(ROUTES.CONTESTS.VIEW(key, ContestTab.OVERVIEW));
      }
    );
  };
};

export const loadAllContest = (type: ContestTimeStatus, setLoader: LoaderAction) => {
  return async (dispatch: Function) => {
    const params: SearchParams = { filterType: [type ? type.toUpperCase() : 'UPCOMING'] };
    await actionLoaderWrapper(
      getContestList(params),
      dispatch,
      (result) => {
        dispatch(updateContests(result.list));
        dispatch(replaceContestsList(result.list.map(problem => problem.key), type));
      },
      setLoader
    );
  };
};

export const createNewContest = (contest: ContestNewState, setLoader: LoaderAction) => {
  return async (dispatch: Function) => {
    await actionLoaderWrapper(
      apiContestCreate(contest),
      dispatch,
      (result) => {
        SuccessNotification({ description: 'Contest created successfully!' });
        dispatch(updateContests([result.object]));
        history.push(ROUTES.CONTESTS.VIEW(result.object.key, ContestTab.OVERVIEW));
      },
      setLoader
    );
  };
};

export const editContest = (contest: ContestNewState, setLoader: LoaderAction) => {
  return async (dispatch: Function) => {
    await actionLoaderWrapper(
      apiContestEdit(contest),
      dispatch,
      (result) => {
        SuccessNotification({ description: 'Contest updated successfully!' });
        dispatch(updateContests([result.object]));
        history.push(ROUTES.CONTESTS.VIEW(result.object.key, ContestTab.OVERVIEW));
      },
      setLoader
    );
  };
};

export const archiveContest = (key: string, setLoader: LoaderAction) => {
  return async (dispatch: Function) => {
    await actionLoaderWrapper(
      apiContestChangeStatus(key, ContestStatus.ARCHIVED),
      dispatch,
      (result) => {
        SuccessNotification({ description: 'Contest archived successfully!' });
        dispatch(updateContests([result.object]));
        history.push(ROUTES.CONTESTS.VIEW(result.object.key, ContestTab.OVERVIEW));
      },
      setLoader
    );
  };
};

export const registerContest = (key: string, setLoader: LoaderAction) => {
  return async (dispatch: Function) => {
    await actionLoaderWrapper(
      apiContestRegister(key),
      dispatch,
      () => {
        SuccessNotification({ description: 'Register successfully!' });
        dispatch(loadContest(key, setLoader));
      },
      setLoader
    );
  };
};
