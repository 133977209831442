import React, { useState } from 'react';
import { Link, Redirect, useParams } from 'react-router-dom';
import { CaretLeftOutlined, InfoCircleOutlined, SettingOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';

import { ContestNewState } from '~/store/types';
import {
  ErrorNotification,
  FieldEditable,
  JukiLayout,
  JukiSider,
  LoaderButton,
  MdMathEditor,
  MenuSideBar,
  SuccessNotification
} from '~/components';
import { CONTEST_TAB, ERROR, ROUTES, SUCCESS, TEMPLATE_CONTEST_NEW } from '~/config/constants';
import { ContestCreateProblems } from './Problems';
import { ContestSetup } from './Setup';
import { useAccountState, useContestDispatch } from '~/hooks';
import { ContestParamsType, ContestTab, ContestTimeStatus } from '~/types';
import { can, classNames } from '~/helpers';
import { apiUploadFile, BASE_URL } from '~/services';

export const ContestCreate = () => {
  
  const account = useAccountState();
  const { tab } = useParams<ContestParamsType>();
  const { createNewContest } = useContestDispatch();
  const currentMinutes = Math.floor(new Date().getTime() / 60 / 1000);
  const [contestNew, setContestNew] = useState<ContestNewState>({
    ...TEMPLATE_CONTEST_NEW,
    settings: {
      ...TEMPLATE_CONTEST_NEW.settings,
      start: (currentMinutes + 10 - (currentMinutes % 5)) * (60 * 1000)
    }
  });
  const { t } = useTranslation();
  
  if (!can.createContest(account)) {
    return <Redirect to={ROUTES.CONTESTS.LIST_PAGE(ContestTimeStatus.LIVE)} />;
  }
  
  const menuSideBarItems = [{
    link: ROUTES.CONTESTS.CREATE(ContestTab.OVERVIEW),
    selected: tab === ContestTab.OVERVIEW,
    icon: <InfoCircleOutlined />,
    label: CONTEST_TAB[ContestTab.OVERVIEW].print
  }, {
    link: ROUTES.CONTESTS.CREATE(ContestTab.SETUP),
    selected: tab === ContestTab.SETUP,
    icon: <SettingOutlined />,
    label: CONTEST_TAB[ContestTab.SETUP].print
  }, {
    link: ROUTES.CONTESTS.CREATE(ContestTab.PROBLEMS),
    selected: tab === ContestTab.PROBLEMS,
    icon: <UnorderedListOutlined />,
    label: CONTEST_TAB[ContestTab.PROBLEMS].print
  }];
  return (
    <JukiSider
      sideBar={(isClosed: boolean) => (
        <>
          <JukiLayout type="title">
            <Link to={ROUTES.CONTESTS.LIST_PAGE(ContestTimeStatus.LIVE)}>
              <Button icon={<CaretLeftOutlined />} type="text">
                {isClosed ? '' : t('cancel and back')}
              </Button>
            </Link>
          </JukiLayout>
          <JukiLayout>
            <MenuSideBar
              items={menuSideBarItems}
              isClosed={isClosed}
            />
          </JukiLayout>
        </>
      )}
      sideMain={() => (
        <>
          <JukiLayout type="title">
            <div className="text-xh bold">
              {t('name')}: {tab !== ContestTab.OVERVIEW && contestNew.name}
              {tab === ContestTab.OVERVIEW && (
                <FieldEditable
                  value={contestNew.name}
                  onChange={(value) => setContestNew(prevState => ({ ...prevState, name: value }))}
                />
              )}
            </div>
            <div className="text-m bold">
              {t('key')}:
              <span className="label-url">
                {BASE_URL}/contest/view/ {tab === ContestTab.OVERVIEW ? (
                <FieldEditable
                  value={contestNew.key} onChange={(value) => setContestNew(prevState => ({ ...prevState, key: value }))}
                />
              ): contestNew.key}
                /overview
              </span>
            </div>
            <LoaderButton
              type="primary"
              onClick={(setLoader) => () => createNewContest(contestNew, setLoader)()}
            >
              {t('create contest')}
            </LoaderButton>
          </JukiLayout>
          <JukiLayout className={classNames(/*'with-scroll',*/ tab, {/*{ 'full-width': tab === ContestTab.OVERVIEW }*/ })}>
            {tab === ContestTab.OVERVIEW && (
              <div>
                <MdMathEditor
                  value={contestNew.description}
                  onChange={(value) => setContestNew(prevState => ({ ...prevState, description: value }))}
                  uploadImageRequest={async (imageBlob) => {
                    const result = await apiUploadFile('image', ['problem', 'test'], imageBlob)();
                    if (result.success === SUCCESS) {
                      SuccessNotification({ description: 'Image upload successfully' });
                      return [result.object.fileUrl, result.success];
                    }
                    ErrorNotification({ description: result.message });
                    return ['', ERROR];
                  }}
                />
              </div>
            )}
            {tab === ContestTab.SETUP && <ContestSetup contestNew={contestNew} setContestNew={setContestNew} newContest/>}
            {tab === ContestTab.PROBLEMS && (
              <ContestCreateProblems contestNew={contestNew} setContestNew={setContestNew} />
            )}
          </JukiLayout>
        </>
      )}
    />
  );
};
