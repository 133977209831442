import {
  ContestActionTypes,
  ContestProblem,
  ContestsActions,
  ContestsListActions,
  ContestsListActionTypes,
  ContestsState, ContestState
} from '../types';
import { EMPTY_CONTEST_PROBLEM, PAGED_ARRAY_EMPTY } from '~/config/constants';
import { objectsUpdate } from '~/helpers';
import { ContestSettingsParams, ContestTimeStatus, ProgrammingLanguage } from '~/types';

const fillDefaultContest = (contest: ContestState) => {
  if (!contest) {
    contest = {} as ContestState;
  }
  if (!contest.settings) {
    contest.settings = {
      [ContestSettingsParams.START]: 0,
      [ContestSettingsParams.CLARIFICATIONS]: false,
      [ContestSettingsParams.OPEN_REGISTRATION]: false,
      [ContestSettingsParams.OPEN_SCOREBOARD]: false,
      [ContestSettingsParams.LANGUAGES]: [
        ProgrammingLanguage.CPP,
        ProgrammingLanguage.CPP14,
        ProgrammingLanguage.CPP17,
        ProgrammingLanguage.JAVA,
        ProgrammingLanguage.JAVASCRIPT,
        ProgrammingLanguage.PYTHON,
        ProgrammingLanguage.PYTHON3
      ],
      [ContestSettingsParams.LIMIT_PROBLEM_TIME]: false,
      [ContestSettingsParams.FROZEN]: true,
      [ContestSettingsParams.MANUAL_JUDGE]: false,
      [ContestSettingsParams.NUMBER_MANUAL_JUDGES]: 0
    };
  }
  if (!contest.timing) {
    contest.timing = {
      duration: 0, // milliseconds
      penalty: 0, // milliseconds
      frozen: 0, // milliseconds
      unJudged: 0, // milliseconds
      toSolve: 0 // milliseconds
    };
  }
  if (!contest.members) {
    contest.members = {
      admins: [],
      guests: [],
      spectators: [],
      contestants: [],
      judges: []
    };
  }
  if (!contest.problems) {
    contest.problems = {};
  }
  if (!contest.clarifications) {
    contest.clarifications = [];
  }
  if (!contest.submissions) {
    contest.submissions = { ...PAGED_ARRAY_EMPTY };
  }
  if (!contest.pendingSubmissions) {
    contest.pendingSubmissions = { ...PAGED_ARRAY_EMPTY };
  }
  if (!contest.mySubmissions) {
    contest.mySubmissions = { ...PAGED_ARRAY_EMPTY };
  }
  
  return contest;
};

export const contests = (state: ContestsState = {}, action: ContestActionTypes) => {
  const newState = {...state};
  switch (action.type) {
    case ContestsActions.UPDATE_CONTESTS:
      action.contests.forEach(contest => {
        const newContest = fillDefaultContest(state[contest.key]);
        if (contest.name) {
          newContest.name = contest.name;
        }
        if (contest.description) {
          newContest.description = contest.description;
        }
        if (contest.key) {
          newContest.key = contest.key;
        }
        if (contest.settings) {
          const newSettings = { ...newContest.settings, ...contest.settings };
          if (JSON.stringify(newSettings) !== JSON.stringify(newContest.settings)) {
            newContest.settings = newSettings;
          }
        }
        if (contest.timing) {
          const newTiming = { ...newContest.timing, ...contest.timing };
          if (JSON.stringify(newTiming) !== JSON.stringify(newContest.timing)) {
            newContest.timing = newTiming;
          }
        }
        if (contest.problems) {
          const newProblems: { [key: string]: ContestProblem } = { ...newContest.problems };
          Object.values(contest.problems).forEach((problem) => {
            newProblems[problem.index] = { ...EMPTY_CONTEST_PROBLEM(newContest.timing.duration), ...newProblems[problem.index], ...problem };
            if (typeof newProblems[problem.index].active === 'undefined') {
              newProblems[problem.index].active = true;
            }
          });
          if (JSON.stringify(newProblems) !== JSON.stringify(newContest.problems)) {
            newContest.problems = newProblems;
          }
        }
        if (contest.members) {
          const newMembers = { ...newContest.members, ...contest.members };
          if (JSON.stringify(newMembers) !== JSON.stringify(newContest.members)) {
            newContest.members = newMembers;
          }
        }
        if (contest.tags) {
          newContest.tags = contest.tags;
        }
        if (contest.status) {
          newContest.status = contest.status;
        }
        if (contest.pendingSubmissions) {
          newContest.pendingSubmissions = contest.pendingSubmissions;
        }
        if (contest.clarifications) {
          if (JSON.stringify(contest.clarifications) !== JSON.stringify(newContest.clarifications)) {
            newContest.clarifications = [...contest.clarifications];
          }
        }
        if (contest.scoreboard) {
          const newScoreboard = { ...newContest.scoreboard, ...contest.scoreboard };
          if (JSON.stringify(newScoreboard) !== JSON.stringify(newContest.scoreboard)) {
            newContest.scoreboard = newScoreboard;
          }
        }
        if (contest.ownerNickname) {
          newContest.ownerNickname = contest.ownerNickname;
        }
        if (contest.totalRegistered) {
          newContest.totalRegistered = contest.totalRegistered;
        }
        if (contest.registered) {
          newContest.registered = contest.registered;
        }
        if (contest.canUpdate) {
          newContest.canUpdate = contest.canUpdate;
        }
        if (contest.canViewProblems) {
          newContest.canViewProblems = contest.canViewProblems;
        }
        if (contest.canViewScoreBoard) {
          newContest.canViewScoreBoard = contest.canViewScoreBoard;
        }
        if (contest.canRegister) {
          newContest.canRegister = contest.canRegister;
        }
        if (contest.canRejudge) {
          newContest.canRejudge = contest.canRejudge;
        }
        if (contest.submissions) {
          newContest.submissions = objectsUpdate(newContest.submissions, contest.submissions);
        }
        if (contest.mySubmissions) {
          newContest.mySubmissions = objectsUpdate(newContest.mySubmissions, contest.mySubmissions);
        }
        newState[contest.key] = newContest;
      });
      return newState;
    default :
      return state;
  }
};

const initialState = {
  [ContestTimeStatus.UPCOMING]: [],
  [ContestTimeStatus.LIVE]: [],
  [ContestTimeStatus.PAST]: []
};

export const contestsList = (state: { [key in ContestTimeStatus]: Array<string> } = initialState, action: ContestsListActionTypes) => {
  switch (action.type) {
    case ContestsListActions.REPLACE_CONTESTS_LIST:
      return { ...state, [action.timeStatus]: [...action.contests] };
    default:
      return state;
  }
};
