import React, { useEffect, useState } from 'react';
import { Button, Tabs } from 'antd';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

import { ERROR, LOADING, NONE, PROBLEM_TAB, ROUTES, SUCCESS, VERDICT } from '~/config/constants';
import { useAccountState, useProblemsDispatch, useProblemState } from '~/hooks';
import { history } from '~/store/reducers';
import { Statement } from './Statement';
import { CodeEditor } from './CodeEditor';
import { ProblemSubmission } from './Submissions';
import { LoaderAction, LoaderState, ProblemTab, ProblemVerdict, ProgrammingLanguage, Source } from '~/types';
import { can } from '~/helpers';
import { apiSubmitProblem } from '~/services';
import { ErrorNotification, JukiLayout, Loader, SuccessNotification, WarningNotification } from '~/components';

import './styles.scss';

const { TabPane } = Tabs;

export const ProblemView = () => {
  
  const { key, tab } = useParams<{ key: string, tab: ProblemTab }>();
  const account = useAccountState();
  const { loadProblem } = useProblemsDispatch();
  const problem = useProblemState(key);
  const [source, setSource] = useState<Source>({ language: ProgrammingLanguage.CPP, plainText: '' });
  const [loader, setLoader] = useState<LoaderState>([new Date().getTime(), NONE]);
  const { t } = useTranslation();
  useEffect(() => {
    loadProblem(key, setLoader)();
  }, [loadProblem, key, account.nickname]);
  
  const onSubmit = async (setLoading: LoaderAction) => {
    const now = new Date().getTime();
    setLoading([now, LOADING]);
    const result = await apiSubmitProblem(key, source.plainText, source.language)();
    if (result.success === SUCCESS) {
      if (result.object.answer === ProblemVerdict.AC) {
        SuccessNotification({ title: result.object.answer, description: VERDICT[ProblemVerdict.AC].print });
        setLoading([now, SUCCESS]);
      } else if (result.object.answer === ProblemVerdict.PA) {
        SuccessNotification({
          title: result.object.answer,
          description: `${VERDICT[ProblemVerdict.PA].print}, ${result.object.submitPoints} points`
        });
        setLoading([now, SUCCESS]);
      } else {
        WarningNotification({ title: result.object.answer, description: VERDICT[result.object.answer].print });
        setLoading([now, ERROR]);
      }
    } else {
      ErrorNotification({ description: result.message });
      setLoading([now, ERROR]);
    }
  };
  
  return (
    <Loader
      loading={loader[1] === LOADING || !problem}
      component={() => (
        <>
          <JukiLayout type="title" className="title-with-nav-tab">
            <div className="content-title">
              <h5>{problem.name}</h5>
              {can.updateProblem(account, problem) && (
                <Link
                  to={ROUTES.PROBLEMS.EDIT(problem.id, ProblemTab.STATEMENT)}
                  className="button-edit-contest"
                >
                  <Button type="ghost">{t('edit') + ' ' + t('problem')}</Button>
                </Link>
              )}
            </div>
            <div className="juki-nav-tab-title">
              <Tabs
                activeKey={tab}
                onChange={(tab: string) => history.push(ROUTES.PROBLEMS.VIEW(key, tab as ProblemTab))}
              >
                <TabPane
                  tab={<div className="tab-label">{t(PROBLEM_TAB[ProblemTab.STATEMENT].print)}</div>}
                  key={ProblemTab.STATEMENT}
                />
                {account.isLogged && (
                  <TabPane
                    tab={<div className="tab-label">{t(PROBLEM_TAB[ProblemTab.EDITOR].print)}</div>}
                    key={ProblemTab.EDITOR}
                  />
                )}
                <TabPane
                  tab={<div className="tab-label">{t(PROBLEM_TAB[ProblemTab.SUBMISSIONS].print)}</div>}
                  key={ProblemTab.SUBMISSIONS}
                />
              </Tabs>
            </div>
          </JukiLayout>
          <JukiLayout>
            {tab === ProblemTab.STATEMENT && (
              <Statement
                problem={problem}
                setSource={setSource}
                canSubmit={account.isLogged}
                languages={problem.settings.languages}
              />
            )}
            {tab === ProblemTab.EDITOR && (
              <CodeEditor
                redirect={account.isLogged ? '' : ROUTES.PROBLEMS.VIEW(key, ProblemTab.STATEMENT)}
                source={source}
                setSource={setSource}
                onSubmit={onSubmit}
                languages={problem.settings.languages}
              />
            )}
            {tab === ProblemTab.SUBMISSIONS && <ProblemSubmission />}
          </JukiLayout>
        </>
      )}
    />
  );
};
