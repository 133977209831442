import React, { useEffect, useState } from 'react';
import { Link, Redirect, useParams } from 'react-router-dom';
import ResizeObserver from 'rc-resize-observer';
import { Table, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import { useContestDispatch, useContestState } from '~/hooks';
import { ReactComponent as Balloon } from '~/graphics/balloon.svg';
import { ContestParamsType, ContestTab, LoaderState, ProblemTab, ProfileTab } from '~/types';
import { LoaderIcon } from '~/components';
import { classNames, dateToHHMM, dateToMMMMMDDYYYY, splitTime } from '~/helpers';
import { ROUTES, LOADING, NONE, DEFAULT_JUKI_PROFILE_IMAGE } from '~/config/constants';

import '../View/Scoreboard/styles.scss';

export const ContestPrintScore = () => {
  const { key } = useParams<ContestParamsType>();
  const {
    problems,
    canViewScoreBoard,
    scoreboard,
    settings,
    name,
    timing,
    totalRegistered,
    submissions
  } = useContestState(key);
  const { loadContest, loadScoreboard, loadContestStatus } = useContestDispatch();
  const [loading, setLoading] = useState<LoaderState>([new Date().getTime(), NONE]);
  const { t } = useTranslation();
  const [size, setSize] = useState({ width: 0, height: 0 });
  useEffect(() => {
    loadContest(key, setLoading)();
    loadScoreboard(key, setLoading)();
    loadContestStatus(key, '?page=1&size=1', false, setLoading)();
  }, [key, loadContest, loadContestStatus, loadScoreboard]);
  if (typeof canViewScoreBoard !== 'undefined' && !canViewScoreBoard) {
    return <Redirect to={ROUTES.CONTESTS.VIEW(key, ContestTab.OVERVIEW)} />;
  }
  const columns = [
    {
      title: () => <div>#</div>,
      dataIndex: '#',
      width: 54, // 30 + 8 + 16
      render: (position: number) => (
        <div className={classNames('cell-body-user-position', { 'no-points': position <= 0 })}>
          <div className="text-m semi-bold"> {position <= 0 ? '-' : position} </div>
        </div>
      ),
      fixed: true
    },
    {
      title: () => <div />,
      dataIndex: 'userPhoto',
      width: 58, // 42 + 16
      render: (imageUrl: string) => (
        <div className="user-photo text-l semi-bold"><img src={imageUrl || DEFAULT_JUKI_PROFILE_IMAGE} alt="user-profile" />
        </div>
      ),
      fixed: true
    },
    {
      title: () => <div>{t('name')}</div>,
      dataIndex: 'userName',
      width: 288, // 128 + 16
      render: ({ nickname, givenName, familyName }: { nickname: string, givenName: string, familyName: string }) => (
        <div className="user-complete-name">
          <Link to={ROUTES.PROFILE.PAGE(nickname, ProfileTab.PROFILE)}>
            <div className="text-m bold"> {givenName + ' ' + familyName} </div>
            <div className="text-xs semi-bold"> {nickname} </div>
          </Link>
        </div>
      ),
      fixed: true
    },
    {
      title: () => <div>PTS</div>,
      dataIndex: 'points',
      width: 100, // 100 + 8 + 16
      render: ({ points, penalty }: { points: number, penalty: number }) => (
        <div className="user-points">
          <div className="text-xl semi-bold"> {points} </div>
          <div className="text-s"> {penalty} </div>
        </div>
      ),
      fixed: true
    },
    ...Object.values(problems ? problems : {}).map((problem => (
      {
        title: () => (
          <div className="head-cell-problem-index">
            <Tooltip title={problem.name}>
              <Link to={ROUTES.CONTESTS.VIEW(key, ContestTab.PROBLEM, problem.index, ProblemTab.STATEMENT)}>
                {problem.index}
              </Link>
            </Tooltip>
          </div>
        ),
        dataIndex: 'problem-' + problem.index,
        width: 100,
        render: ({
                   attempts,
                   time,
                   color,
                   isAccepted
                 }: { attempts: number, time: number, color: string, isAccepted: boolean }) => (
          <div className="user-attempt">
            <div className="balloon" style={!isAccepted ? { opacity: 0 } : undefined}><Balloon color={color} /></div>
            <div className="text-xs"> {attempts === -1 ? ' - ' : attempts} / {time === -1 ? ' - ' : time} </div>
          </div>
        )
      }
    )))];
  
  const data = ((scoreboard && scoreboard.contestants) ? scoreboard.contestants : []).map(((row, index) => (
    {
      key: `place(${index + 1})`,
      '#': row.totalPoints <= 0 ? -1 : index + 1,
      userPhoto: row.imageUrl,
      userName: { nickname: row.nickname, givenName: row.givenName, familyName: row.familyName },
      points: { points: row.totalPoints, penalty: row.totalPenalty },
      ...Object.values(problems).reduce((result, problem) =>
        ({
            ...result, ['problem-' + problem.index]: {
              attempts: (row.problems[problem.index] && row.problems[problem.index].attempts) || ' - ',
              time: (row.problems[problem.index] && row.problems[problem.index].time) || ' - ',
              color: problem.color,
              isAccepted: row.problems[problem.index] && row.problems[problem.index].success
            }
          }
        ), {})
    }
  )));
  return (
    <ResizeObserver onResize={({ width, height }) => setSize({ width, height })}>
      <div className="contest-title">
        {loading[1] !== LOADING && (
          <>
            <h2>{name}</h2>
            <div className="content-date">
              <span className="label">{t('date')} :</span>
              {dateToHHMM(new Date(settings?.start || 0)) + ' ' + dateToMMMMMDDYYYY(new Date(settings?.start || 0))}
            </div>
            <div className="content-total-duration">
              <span className="label">{t('duration')} :</span>
              {splitTime(timing?.duration)?.filter(time => time.remaining > 0).map((time, index) => {
                return (
                  <span key={index + ''}>
                <span className="sub-label">{time.remaining}</span><span>{t(time.label)}</span>
              </span>
                );
              })}
            </div>
            <div className="content-total-registered"><span
              className="label">{t('contestants')} :</span>{totalRegistered}
            </div>
            <div className="contest-total-submissions">
              <span className="label">{t('total of submissions')} :</span>{submissions?.totalElements}
            </div>
          </>
        )}
      </div>
      <div className="scoreboard-layout">
        <Table
          columns={columns}
          dataSource={data}
          pagination={false}
          scroll={{ x: size.width, y: size.height - 48 - 64 }} //47: head height // 64: paginate height
          loading={{ indicator: <LoaderIcon />, spinning: loading[1] === LOADING }}
        />
      </div>
    </ResizeObserver>
  );
};
