import React, { useEffect } from 'react';

import { LayoutMain, LayoutTitle, ResizeTable } from '../shared';
import { useRankingDispatch, useRankingState } from '../../hooks';

import './Ranking.scss';

export const Ranking = () => {
  const { loadRanking } = useRankingDispatch();
  const { ranking } = useRankingState();
  
  useEffect(() => {
    loadRanking()();
  }, [loadRanking]);
  
  const columns = [
    {
      title: () => <div>position</div>,
      dataIndex: 'position',
      key: 'position',
      render: (position: string) => {
        return (
          <div className="position-column">
            <div className="color text-l semi-bold">{position}</div>
          </div>
        );
      }
    },
    {
      title: () => <div>nickname</div>,
      dataIndex: 'nickname',
      key: 'nickname',
      render: ({ name, imageUrl }: { name: string, imageUrl: string }) => {
        return (
          <div className="nickname-column">
            <img src={imageUrl} alt="user-profile" />
            <span className="name">{name}</span>
          </div>
        );
      }
    },
    {
      title: () => <div>points</div>,
      dataIndex: 'points',
      key: 'points',
      render: (points: number) => <div><span className="points-column text-l bold"> {points + ' '}</span> pts </div>
    },
    {
      title: () => <div>country</div>,
      dataIndex: 'country',
      key: 'country',
      render: (imageUrl: string) => <div className="country-column"><img src={imageUrl} alt="user-profile" /></div>
    },
    {
      title: () => <div>institution</div>,
      dataIndex: 'institution',
      key: 'institution',
      render: (text: string) => <div> {text}</div>
    }
  ];
  
  const data: any = [];
  
  Object.keys(ranking).map((key: string) => {
    const user = ranking[key];
    data.push(
      {
        key: user.key,
        position: user.key,
        nickname: {
          name: user.nickname,
          imageUrl: user.imageUrl
        },
        points: user.points,
        country: user.country,
        institution: user.institution
      }
    );
    return user;
  });
  
  return (
    <>
      <LayoutTitle className="page-list-ranking">
        <h5> Ranking </h5>
        <div className="text-m"> All people that participated in coding contests from beginner level to week-long coding
          marathons
        </div>
      </LayoutTitle>
      <LayoutMain className="page-list-ranking">
        <ResizeTable className="content-problems" columns={columns} dataSource={data} />
      </LayoutMain>
    </>
  );
};
