import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import { Button, Tag } from 'antd';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { JukiLayout, LineLoader, ResizeTable } from '~/components/shared';
import { LOADING, NONE, ROUTES } from '~/config/constants';
import { useAccountState, useProblemsDispatch, useProblemsState } from '~/hooks';
import { LoaderState, ProblemStatus, ProblemTab } from '~/types';
import { can } from '~/helpers';

import './styles.scss';

export const Problems = () => {
  
  const account = useAccountState();
  const { loadProblems } = useProblemsDispatch();
  const { problemsDataList } = useProblemsState();
  const { search } = useLocation();
  const [loader, setLoader] = useState<LoaderState>([new Date().getTime(), NONE]);
  const { t } = useTranslation();
  useEffect(() => {
    loadProblems(search, setLoader)();
  }, [loadProblems, search, account.nickname]);
  
  enum DataIndex {
    KEY = 'key',
    PROBLEM_ID = 'PROBLEM_ID',
    PROBLEM_NAME = 'PROBLEM_NAME',
    PROBLEM_SUCCESS_RATE = 'PROBLEM_SUCCESS_RATE',
    PROBLEM_TAGS = 'PROBLEM_TAGS',
    PROBLEM_SCOPE = 'PROBLEM_SCOPE'
  }
  
  type RowData = {
    [DataIndex.KEY]: string,
    [DataIndex.PROBLEM_ID]: string,
    [DataIndex.PROBLEM_NAME]: string,
    [DataIndex.PROBLEM_SUCCESS_RATE]: number,
    [DataIndex.PROBLEM_TAGS]: Array<string>,
    [DataIndex.PROBLEM_SCOPE]: string
  }
  const allTags = new Set<string>();
  problemsDataList.forEach(problem => {
    problem.tags.forEach(tag => {
      allTags.add(tag);
    });
  });
  const columns = [
    {
      title: ({ sortOrder, sortColumn }: { sortOrder: string, sortColumn: { dataIndex: DataIndex } }) => (
        <div>
          id
          {sortColumn?.dataIndex === DataIndex.PROBLEM_ID && (
            sortOrder === 'ascend' ? <ArrowUpOutlined /> : sortOrder === 'descend' ? <ArrowDownOutlined /> : null
          )}
        </div>
      ),
      dataIndex: DataIndex.PROBLEM_ID,
      render: (text: string) => (
        <div className="cell-body-link bold">
          <Link to={ROUTES.PROBLEMS.VIEW(text, ProblemTab.STATEMENT)} className="id-column">{text}</Link>
        </div>
      ),
      width: '100px',
      sorter: (a: RowData, b: RowData) => ('' + a[DataIndex.PROBLEM_ID]).localeCompare('' + b[DataIndex.PROBLEM_ID]),
      showSorterTooltip: false
    },
    {
      title: ({ sortOrder, sortColumn }: { sortOrder: string, sortColumn: { dataIndex: DataIndex } }) => (
        <div className="head-cell-problem-name">
          {t('problem name')}
          {sortColumn?.dataIndex === DataIndex.PROBLEM_NAME && (
            sortOrder === 'ascend' ? <ArrowUpOutlined /> : sortOrder === 'descend' ? <ArrowDownOutlined /> : null
          )}
        </div>
      ),
      width: '300px',
      dataIndex: DataIndex.PROBLEM_NAME,
      render: (text: string) => <div className="cell-body-left bold">{text}</div>,
      sorter: (a: RowData, b: RowData) => a[DataIndex.PROBLEM_NAME].localeCompare(b[DataIndex.PROBLEM_NAME]),
      showSorterTooltip: false
    },
    // {
    //   title: ({ sortOrder, sortColumn }: { sortOrder: string, sortColumn: { dataIndex: DataIndex } }) => (
    //     <div>
    //       {t('% success rate')}
    //       {sortColumn?.dataIndex === DataIndex.PROBLEM_SUCCESS_RATE && (
    //         sortOrder === 'ascend' ? <ArrowUpOutlined /> : sortOrder === 'descend' ? <ArrowDownOutlined /> : null
    //       )}
    //     </div>
    //   ),
    //   dataIndex: DataIndex.PROBLEM_SUCCESS_RATE,
    //   render: (rate: number) => <div className="rate-column">{rate === -1 ? '-' : rate}</div>,
    //   width: '180px',
    //   sorter: (a: RowData, b: RowData) => a[DataIndex.PROBLEM_SUCCESS_RATE] - b[DataIndex.PROBLEM_SUCCESS_RATE],
    //   showSorterTooltip: false
    // },
    {
      title: ({ sortOrder, sortColumn }: { sortOrder: string, sortColumn: { dataIndex: DataIndex } }) => (
        <div className="head-cell-tags">
          {t('tags')}
          {sortColumn?.dataIndex === DataIndex.PROBLEM_TAGS && (
            sortOrder === 'ascend' ? <ArrowUpOutlined /> : sortOrder === 'descend' ? <ArrowDownOutlined /> : null
          )}
        </div>
      ),
      dataIndex: DataIndex.PROBLEM_TAGS,
      render: (tags: Array<string>) => {
        const list: any = [];
        tags.forEach((tag) => {
          list.push(<Tag className="tag-column text-s">{tag}</Tag>);
        });
        return <div className="cell-body-tags">{list}</div>;
      },
      width: '200px',
      sorter: (a: RowData, b: RowData) => a[DataIndex.PROBLEM_TAGS].length - b[DataIndex.PROBLEM_TAGS].length,
      showSorterTooltip: false,
      filters: Array.from(allTags).map(tag => ({ text: tag, value: tag })),
      onFilter: (value: string | number | boolean, record: RowData) => record[DataIndex.PROBLEM_TAGS].indexOf('' + value) !== -1
    },
    ...(can.viewStatusProblem(account) ? [{
      title: ({ sortOrder, sortColumn }: { sortOrder: string, sortColumn: { dataIndex: DataIndex } }) => (
        <div className="head-cell-scope">
          {t('visibility')}
          {sortColumn?.dataIndex === DataIndex.PROBLEM_SCOPE && (
            sortOrder === 'ascend' ? <ArrowUpOutlined /> : sortOrder === 'descend' ? <ArrowDownOutlined /> : null
          )}
        </div>
      ),
      dataIndex: DataIndex.PROBLEM_SCOPE,
      render: (scope: string) => {
        return <div className="cell-scope">{t(scope.toLowerCase())}</div>;
      },
      width: '160px',
      sorter: (a: RowData, b: RowData) => a[DataIndex.PROBLEM_SCOPE].localeCompare(b[DataIndex.PROBLEM_SCOPE]),
      showSorterTooltip: false,
      filters: [ProblemStatus.PUBLIC, ProblemStatus.PRIVATE, ProblemStatus.ARCHIVED].map(scope => ({
        text: t(scope.toLocaleLowerCase()),
        value: scope
      })),
      onFilter: (value: string | number | boolean, record: RowData) => record[DataIndex.PROBLEM_SCOPE] === value
    }] : [])
  ];
  const data: Array<{ [key in DataIndex]: any }> = problemsDataList.map(problem => ({
    [DataIndex.KEY]: problem.id + problem.name,
    [DataIndex.PROBLEM_ID]: problem.id,
    [DataIndex.PROBLEM_NAME]: problem.name,
    [DataIndex.PROBLEM_SUCCESS_RATE]: problem.successRate,
    [DataIndex.PROBLEM_TAGS]: problem.tags,
    [DataIndex.PROBLEM_SCOPE]: problem.status
  }));
  
  return (
    <>
      <JukiLayout type="title" className="title-with-nav-tab">
        <div className="content-title">
          <h3>{t('problems')}</h3>
          {can.createProblem(account) && (
            <Link to={ROUTES.PROBLEMS.CREATE(ProblemTab.STATEMENT)} className="button-create-contest">
              <Button type="ghost">{t('create')}</Button>
            </Link>
          )}
        </div>
      </JukiLayout>
      <JukiLayout>
        {loader[1] === LOADING && data.length > 0 && <LineLoader />}
        <ResizeTable
          className="content-problems"
          columns={columns}
          dataSource={data}
          loading={loader[1] === LOADING && data.length === 0}
        />
      </JukiLayout>
    </>
  );
};
