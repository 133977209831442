import React from 'react';
import { useParams } from 'react-router';
import { EnvironmentFilled, MailFilled } from '@ant-design/icons';

import { useProfileState } from '~/hooks';
import { Judge} from '~/types';
import { ReactComponent as City } from './city.svg';
import { JUDGE, DEFAULT_JUKI_PROFILE_IMAGE } from '~/config/constants';

import './PersonalProfile.scss';

export const PersonalProfile = () => {
  const { key } = useParams<{ key: string }>();
  const { profile } = useProfileState(key);
  const handles: any = [];

  if (profile?.handles) {
    Object.keys(profile?.handles).map(key => {
        const handle = profile.handles[key as Judge];
        handles.push(
          <div className="handle" key={key}>
            <img style={{ width: '5%' }} src={JUDGE[key as Judge]?.logo} alt="" />{handle}
          </div>
        );
        return handle;
      }
    );
  }

  return (
    <div className="layout-personal-profile">
      <div className="content-profile-image">
        <img
          src={profile.imageUrl || DEFAULT_JUKI_PROFILE_IMAGE}
          alt="user-profile"
        />
      </div>
      <div className="content-profile-data">
        {/*<div>SCORE</div>*/}
        <div className="name-nickname-follow">
          <div className="name-nickname">
            <p className="nickname bold"> {profile?.nickname}</p>
            <p className="name"> {profile?.givenName + ' ' + profile?.familyName} </p>
          </div>
          {/*<Button type="primary" className="follow-button"> Follow </Button>*/}
        </div>
        {/*<div className="ranking-follwer-following">*/}
        {/*  <Row>*/}
        {/*    Row className="follow-row">*/}
        {/*    <Col>*/}
        {/*      <p className="numbers"> {profile?.followers} </p>*/}
        {/*      <p> FOLLOWER</p>*/}
        {/*    </Col>*/}
        {/*    <Col>*/}
        {/*      <p className="numbers"> {profile?.following} </p>*/}
        {/*      <p> FOLLOWING</p>*/}
        {/*    </Col>*/}
        {/*  </Row>*/}
        {/*</div>*/}
        <div className="nationality-institution-email-handles">
          <div className="nationality">
            <EnvironmentFilled /> {profile?.city}, <span className="text-m bold"> {profile?.country} </span>
          </div>
          <div className="institution">
            <City />{profile?.institution}
          </div>
          <div className="email">
            <MailFilled />{profile?.email}
          </div>
          {handles}
        </div>
      </div>
    </div>
  );
};
