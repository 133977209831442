import React, { useRef } from 'react';
import { DropTargetMonitor, useDrag, useDrop, XYCoord } from 'react-dnd';
import { Button } from 'antd';
import { DeleteOutlined, EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';

import { FieldEditable, ColorPicker } from '~/components';
import { ContestNewState, ContestProblem } from '~/store/types';
import { ReactComponent as DragIndicator } from '~/graphics/drag-indicator.svg';
import { ReactComponent as Balloon } from '~/graphics/balloon.svg';
import { DragItem, RowTableProblemsProps } from './types';
import { ContestSettingsParams } from '~/types';
import { letterIndex, numberIndex } from '~/helpers';

export const RowTableProblems = ({
                                   problem,
                                   index,
                                   setContest,
                                   moveProblems,
                                   isFutureContest,
                                   contest
                                 }: RowTableProblemsProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [, drop] = useDrop({
    accept: 'row',
    hover(item: DragItem, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      
      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;
      
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      
      // Time to actually perform the action
      moveProblems(dragIndex, hoverIndex);
      
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    }
  });
  
  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: 'row', problemIndex: problem.index, index },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging()
    })
  });
  drag(drop(ref));
  return (
    <div
      key={problem.link}
      className="add-contest-table-problems-row"
      ref={preview}
      style={isDragging ? { background: 'rgba(0, 0, 0, 0.3)' } : {}}
    >
      {isFutureContest && (
        <div ref={ref} className="cell-drag">
          <DragIndicator />
        </div>
      )}
      <div className="cell-number" ref={drop}>{problem.index}</div>
      <div className="cell-name"><a href={problem.link} target="_blank" rel="noopener noreferrer">{problem.name}</a>
      </div>
      <div className="cell-color">
        <ColorPicker
          color={problem.color}
          onChange={(color: string) => setContest(prevState => {
            const newProblems = { ...prevState.problems };
            newProblems[problem.index] = { ...problem, color };
            return { ...prevState, problems: newProblems };
          })}
        >
          <Balloon color={problem.color} />
        </ColorPicker>
      </div>
      <div className="cell-points">
        <FieldEditable<number>
          value={problem.points}
          onChange={value => setContest((prevState: ContestNewState) => {
            const newProblems = { ...prevState.problems };
            newProblems[problem.index] = {
              ...problem,
              points: (+value < 1) ? 1 : +value
            };
            return { ...prevState, problems: newProblems };
          })}
        />
      </div>
      <div className="cell-judge">{problem.judge}</div>
      {contest.settings[ContestSettingsParams.LIMIT_PROBLEM_TIME] && (
        <div className="cell-start">
          <FieldEditable<number>
            value={problem.start / 1000 / 60}
            onChange={value => setContest((prevState: ContestNewState) => {
              const newProblems = { ...prevState.problems };
              const start = Math.min(contest.timing.duration, Math.max(0, value * 1000 * 60));
              newProblems[problem.index] = {
                ...problem,
                start,
                duration: Math.min(contest.timing.duration - start, Math.max(0, problem.duration))
              };
              return { ...prevState, problems: newProblems };
            })}
          />
        </div>
      )}
      {contest.settings[ContestSettingsParams.LIMIT_PROBLEM_TIME] && (
        <div className="cell-duration">
          <FieldEditable<number>
            value={problem.duration / 1000 / 60}
            onChange={value => setContest((prevState: ContestNewState) => {
              const newProblems = { ...prevState.problems };
              newProblems[problem.index] = {
                ...problem,
                duration: Math.min(contest.timing.duration - problem.start, Math.max(0, value * 1000 * 60))
              };
              return { ...prevState, problems: newProblems };
            })}
          />
        </div>
      )}
      <div className="cell-delete">
        <Button
          icon={isFutureContest ? <DeleteOutlined /> : problem.active ? <EyeOutlined /> : <EyeInvisibleOutlined />}
          onClick={() => {
            if (isFutureContest) {
              setContest((prevState: ContestNewState) => {
                const newProblems = { ...prevState.problems };
                delete newProblems[problem.index];
                const newDataProblems: { [key: string]: ContestProblem } = {};
                Object.values(newProblems)
                  .sort((np1, np2) => numberIndex(np1.index) - numberIndex(np2.index))
                  .forEach((newProblem, index) => {
                    newDataProblems[letterIndex(index + 1)] = { ...newProblem, index: letterIndex(index + 1) };
                  });
                return { ...prevState, problems: newDataProblems };
              });
            } else {
              setContest((prevState: ContestNewState) => {
                const newProblems = { ...prevState.problems };
                newProblems[problem.index] = {
                  ...newProblems[problem.index],
                  active: !newProblems[problem.index].active
                };
                return { ...prevState, problems: newProblems };
              });
            }
          }}
        />
      </div>
    </div>
  );
};
