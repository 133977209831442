import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { Tabs } from 'antd';
import { useTranslation } from 'react-i18next';

import { LayoutMain } from '../shared/Layouts';
import { history } from '../../store/reducers';
import { ROUTES } from '../../config/constants/routes';
import { PersonalProfile } from './PersonalProfile';
import { PersonalContest } from './Contests';
import { Settings } from './Settings';
import { Submissions } from './Submissions';
import { useAccountState, useProfileDispatch, useProfileState } from '../../hooks';
import { Loader } from '../../commons/components/Loader';
import { classNames } from '../../commons/helpers';
import { ProfileTab } from '../../commons/types';

import './style.scss';

const { TabPane } = Tabs;

export const Profile = () => {
  
  const { key, tab } = useParams<{ key: string, tab: ProfileTab }>();
  const { loadUserProfile } = useProfileDispatch();
  const { nickname } = useAccountState();
  const { profile } = useProfileState(key);
  const { t } = useTranslation();
  useEffect(() => {
    loadUserProfile(key)();
  }, [key, loadUserProfile]);
  
  return (
    <Loader
      loading={!profile}
      component={() => (
        <LayoutMain className={classNames('page-profile', tab)}>
          <div className="content-tabs">
            <div className="content-tab">
              <Tabs
                activeKey={tab}
                onChange={currentTab => history.push(ROUTES.PROFILE.PAGE(key, currentTab as ProfileTab))}
              >
                <TabPane tab={<div className="tab-label">{t('personal profile')}</div>} key={ProfileTab.PROFILE} />
                {nickname === key && (
                  <>
                    <TabPane tab={<div className="tab-label">{t('settings')}</div>} key={ProfileTab.SETTINGS} />
                    {/*<TabPane tab={<div className="tab-label">{t('submissions')}</div>} key={ProfileTab.SUBMISSIONS} />*/}
                    {/*<TabPane tab={<div className="tab-label">Contests</div>} key={PROFILE_TABS.CONTESTS}/>*/}
                  </>
                )}
              </Tabs>
            </div>
          </div>
          <div className="content-main">
            {tab === ProfileTab.PROFILE && <PersonalProfile />}
            {tab === ProfileTab.SETTINGS && <Settings />}
            <PersonalContest />
            {tab === ProfileTab.SUBMISSIONS && <Submissions />}
          </div>
        </LayoutMain>
      )}
    />
  );
};
