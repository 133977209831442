import { AccountState, ContestNewState, ContestProblem, FlagsState, ProblemNewState, ProblemState, UserState } from '~/store/types';
import {
  AccountStatus,
  Code,
  ContestSettingsParams,
  Judge,
  Language,
  PagedArray,
  ProblemInput,
  ProblemMode,
  ProblemOutput,
  ProblemStatus,
  ProfileSettingOptions,
  ProgrammingLanguage,
  ScopeData,
  Theme
} from '~/types';

export const PAGED_ARRAY_EMPTY: PagedArray<any> = {
  list: [],
  pageNumber: 0,
  pageSize: 0,
  totalPages: 0,
  totalElements: 0
};

export const TEMPLATE_CONTEST_NEW: ContestNewState = {
  name: 'Entrenamiendo Semana 1',
  description: `# \\textAlign=center Bienvenido

\\textAlign=center
Este concurso esta enfocado para estudiantes de primer año de Informática o carreras afines.

## Contenido

\\textAlign=justify
En este concurso podras encontrar problemas de Grafos y Estructura de Datos. Para tener un buen desepeño en el concurso necesitarás conocimientos en:

* [Segment Tree](https://cp-algorithms.com/data_structures/segment_tree.html)
* Fenwick Tree
* BFS
* DFS
* Algoritmo de Dijkstra [🔗](https://en.wikipedia.org/wiki/Dijkstra%27s_algorithm)

## Modalidad

El Contest durara $5$ horas y sera formato ICPC.
> The International Collegiate Programming Contest is an algorithmic programming contest for college students

![contest](https://judge.juki.app/images/juki-sign-person.svg=textAlign=center)

## Personal

Nombre | Rol
-- | --
Juki | Admin
Alberto Jimenez | Juez
Fernando Savedra | Staff
Oscar Arias | Problem Setter`,
  key: 'contest-test',
  settings: {
    [ContestSettingsParams.START]: new Date().getTime(),
    [ContestSettingsParams.CLARIFICATIONS]: false,
    [ContestSettingsParams.OPEN_REGISTRATION]: true,
    [ContestSettingsParams.OPEN_SCOREBOARD]: true,
    [ContestSettingsParams.LIMIT_PROBLEM_TIME]: false,
    [ContestSettingsParams.LANGUAGES]: [
      ProgrammingLanguage.CPP,
      ProgrammingLanguage.CPP14,
      ProgrammingLanguage.CPP17,
      ProgrammingLanguage.JAVA,
      ProgrammingLanguage.JAVASCRIPT,
      ProgrammingLanguage.PYTHON,
      ProgrammingLanguage.PYTHON3
    ],
    [ContestSettingsParams.FROZEN]: true,
    [ContestSettingsParams.MANUAL_JUDGE]: false,
    [ContestSettingsParams.NUMBER_MANUAL_JUDGES]: 0,
  },
  timing: {
    duration: 5 * 60 * 60 * 1000, // milliseconds
    penalty: 20 * 60 * 1000, // milliseconds
    frozen: 4 * 60 * 60 * 1000, // milliseconds
    unJudged: 4 * 60 * 60 * 1000 + 45 * 60 * 1000, // milliseconds
    toSolve: 5 * 60 * 60 * 1000 // milliseconds
  },
  problems: {},
  members: {
    admins: [],
    guests: [],
    spectators: [],
    contestants: [],
    judges: []
  },
  tags: []
};

export const TEMPLATE_PROBLEM_NEW: ProblemNewState = {
  name: 'Nombre del problema',
  status: ProblemStatus.PRIVATE,
  description: {
    general: `\\textAlign=justify
Juki es un joven oso jukumari que le gusta mucho la programación competitiva. Juki se entrena cada día para ser uno de los mejores programadores.

\\textAlign=justify
Juki esta aprendiendo sobre los números primos, Juki sabe que un número primo es aquel número mayor a $1$ y aquel que tiene $2$ únicos divisores.

\\textAlign=justify
Juki te dara varios números y tu debes ayudarle a responder si el número es primo o no.`,
    input: `\\textAlign=justify
La entrada consiste en varias líneas, en cada línea Juki te dara un solo número $X$ ($1 \\leq X \\leq 100$).`,
    output: `\\textAlign=justify
Por cada número $X$ que Juki te pregunte debes imprimir "\`primo!\`" (sin comillas) si el número es primo y "\`no primo\`" (sin comillas) si no lo es.`
  },
  settings: {
    timeLimit: 2000,
    memoryLimit: 512 * 1000,
    typeInput: ProblemInput.STANDARD,
    typeOutput: ProblemOutput.STANDARD,
    mode: ProblemMode.TOTAL,
    languages: [ProgrammingLanguage.CPP, ProgrammingLanguage.JAVA],
    groupsPoint: {}
  },
  samples: [
    {
      input: '3\n20\n7',
      output: 'primo!\nno primo\nprimo!\n'
    },
    {
      input: '33\n100\n17',
      output: 'no primo\nno primo\nprimo!\n'
    }
  ],
  author: '',
  tags: ['new']
};

export const EMPTY_CONTEST_PROBLEM: (duration: number) => ContestProblem = (duration: number) => ({
  index: 'A', // "A", "B", "C",...
  id: '',
  judge: Judge.JUKI_JUDGE,
  link: '',
  points: 0,
  start: 0,
  duration: duration,
  color: '',
  myPoints: 0,
  successRate: 0,
  active: true
} as ContestProblem);

export const EMPTY_PROBLEM: ProblemState = {
  name: '',
  description: {
    general: '',
    input: '',
    output: ''
  },
  samples: [],
  settings: {
    timeLimit: 0,
    memoryLimit: 0,
    typeInput: ProblemInput.STANDARD,
    typeOutput: ProblemOutput.STANDARD,
    mode: ProblemMode.TOTAL,
    languages: [],
    groupsPoint: {}
  },
  status: ProblemStatus.ARCHIVED,
  author: '',
  tags: [],
  id: '',
  date: 0,
  successRate: 0,
  ownerNickname: '',
  rating: 0,
  submissions: { ...PAGED_ARRAY_EMPTY }
};

export const DEFAULT_PERMISSIONS: { [key in ScopeData]: string } = {
  [ScopeData.USER]: '9992',
  [ScopeData.PROBLEM]: '9993',
  [ScopeData.CONTEST]: '9993',
  [ScopeData.ATTEMPT]: '9999'
};

export const DEFAULT_SETTINGS: { [key in ProfileSettingOptions]: string } = {
  [ProfileSettingOptions.DIAGNOSTIC]: '',
  [ProfileSettingOptions.LANGUAGE]: Language.ENGLISH,
  [ProfileSettingOptions.LOW_RATE]: '',
  [ProfileSettingOptions.NOTIFICATION]: '',
  [ProfileSettingOptions.SOCIAL]: '',
  [ProfileSettingOptions.TALKS]: '',
  [ProfileSettingOptions.THEME]: Theme.LIGHT
};

export const USER_GUEST: AccountState = {
  givenName: 'Guest',
  familyName: 'Guest',
  email: 'GUEST',
  nickname: '',
  handles: {
    codeforces: '',
    topcoder: '',
    codechef: '',
    uva: ''
  },
  status: AccountStatus.ACTIVE,
  aboutMe: '',
  imageUrl: 'https://i.ibb.co/gvC4twc/juki.png',
  isLogged: false,
  permissions: { ...DEFAULT_PERMISSIONS },
  mySettings: { ...DEFAULT_SETTINGS },
  settings: [],
  city: '',
  country: '',
  institution: '',
  submissions: { ...PAGED_ARRAY_EMPTY }
};

export const DEFAULT_USER: UserState = {
  name: '',
  givenName: '',
  familyName: '',
  email: '',
  nickname: '',
  imageUrl: '',
  handles: {
    [Judge.AT_CODER]: '',
    [Judge.CODECHEF]: '',
    [Judge.CODEFORCES]: '',
    [Judge.JUKI_JUDGE]: '',
    [Judge.UVA_ONLINE_JUDGE]: ''
  },
  aboutMe: '',
  institution: '',
  city: '',
  country: '',
  status: AccountStatus.ARCHIVED,
  permissions: [],
  passPermissions: {},
  followers: 0,
  following: 0,
  settings: {}
};

export const DEFAULT_CODE: Code = {
  message: '',
  source: '',
  submitId: ''
};

export const DEFAULT_FLAGS_STATE: FlagsState = {
  openLoginModal: false,
  openSignUpModal: false,
  openWelcomeModal: false,
  requestingApiContestScoreboard: false,
  requestingApiContestMySubmissions: 0,
  requestingApiContestPendingStatus: 0,
  requestingApiContestStatus: 0,
  requestingApiProblemStatus: 0,
  requestingApiProfileSubmissions: 0,
  closeSideBar: false,
  lastNotification: null
};
