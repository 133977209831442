import { history } from '../reducers';
import { CLEAR_REDUX_DATA } from '../types';
import { consoleWarn } from '~/helpers';

export const clearRedux = () => {
  consoleWarn('Cleaning redux...');
  return async (dispatch: Function) => {
    dispatch({ type: CLEAR_REDUX_DATA });
    history.push('/');
  };
};

export * from './account';
export * from './contest';
export * from './flags';
export * from './problem';
export * from './profile';
export * from './ranking';
export * from './users';
