import { Language, ProgrammingLanguage, Status, Theme } from '../types';

export const LANGUAGES: { [key in Language]: { value: Language, print: string } } = {
  [Language.ENGLISH]: { value: Language.ENGLISH, print: 'English' },
  [Language.SPANISH]: { value: Language.SPANISH, print: 'Español' }
};

export const THEME: { [key in Theme]: { value: Theme, print: string } } = {
  [Theme.LIGHT]: { value: Theme.LIGHT, print: 'Light' },
  [Theme.DARK]: { value: Theme.DARK, print: 'Dark' }
};

// Languages to monaco https://monaco-react.surenatoyan.com/
export const PROGRAMMING_LANGUAGES: { [key in ProgrammingLanguage]: { value: ProgrammingLanguage, name: string, mime: string, fileExtension: Array<string>, monaco: string } } = {
  [ProgrammingLanguage.CPP]: {
    value: ProgrammingLanguage.CPP,
    name: 'C++',
    mime: 'text/x-c++src',
    fileExtension: ['c++', 'cpp'],
    monaco: 'cpp'
  }, // fileExtension: ['c++', 'cpp', 'cxx', 'cc']
  [ProgrammingLanguage.CPP14]: {
    value: ProgrammingLanguage.CPP14,
    name: 'C++ 14',
    mime: 'text/x-c++src',
    fileExtension: ['c++', 'cpp'],
    monaco: 'cpp'
  }, // fileExtension: ['c++', 'cpp', 'cxx', 'cc']
  [ProgrammingLanguage.CPP17]: {
    value: ProgrammingLanguage.CPP17,
    name: 'C++ 17',
    mime: 'text/x-c++src',
    fileExtension: ['c++', 'cpp'],
    monaco: 'cpp'
  }, // fileExtension: ['c++', 'cpp', 'cxx', 'cc']
  [ProgrammingLanguage.JAVA]: {
    value: ProgrammingLanguage.JAVA,
    name: 'Java',
    mime: 'text/x-java',
    fileExtension: ['java'],
    monaco: 'java'
  },
  [ProgrammingLanguage.PYTHON]: {
    value: ProgrammingLanguage.PYTHON,
    name: 'Python',
    mime: 'text/x-python',
    fileExtension: ['py'],
    monaco: 'python'
  },
  [ProgrammingLanguage.PYTHON3]: {
    value: ProgrammingLanguage.PYTHON,
    name: 'Python 3',
    mime: 'text/x-python',
    fileExtension: ['py'],
    monaco: 'python'
  },
  [ProgrammingLanguage.JAVASCRIPT]: {
    value: ProgrammingLanguage.JAVASCRIPT,
    name: 'Node.JS',
    mime: 'application/x-javascript',
    fileExtension: ['js'],
    monaco: 'javascript'
  },
  [ProgrammingLanguage.JSON]: {
    value: ProgrammingLanguage.JSON,
    name: 'Json',
    mime: 'application/json',
    fileExtension: ['json'],
    monaco: 'json'
  },
  [ProgrammingLanguage.TEXT]: {
    value: ProgrammingLanguage.TEXT,
    name: 'Plane text',
    mime: 'text/plain',
    fileExtension: ['txt', 'text'],
    monaco: 'text'
  }, // asc txt text diff pot
  [ProgrammingLanguage.MARKDOWN]: {
    value: ProgrammingLanguage.MARKDOWN,
    name: 'Markdown',
    mime: 'text/plain',
    fileExtension: ['md'],
    monaco: 'markdown'
  },
  [ProgrammingLanguage.ARDUINO]: {
    value: ProgrammingLanguage.ARDUINO,
    name: 'Arduino',
    mime: 'text/plain',
    fileExtension: ['txt', 'text', 'c', 'cpp', 'pde', 'h', 'ino'],
    monaco: 'cpp'
  },
};

export const PAGE_SIZES = ['32', '64', '128', '256'];

export const PALLETE = {
  VIVOS: [
    { color: '#FF0000', title: 'Rojo' },
    { color: '#FF5900', title: 'Rojo - Naranja' },
    { color: '#FF8600', title: 'Naranja' },
    { color: '#FFBF00', title: 'Ámbar' },
    { color: '#FFFF00', title: 'Amarillo' },
    { color: '#A6FF00', title: 'Lima o Verde limon' },
    { color: '#00FF8C', title: 'Verde puro' },
    { color: '#00FF8C', title: 'Verde cian' },
    { color: '#00FFFF', title: 'Cian' },
    { color: '#0099FF', title: 'Cerúleo o Azur' },
    { color: '#0000FF', title: 'Azul' },
    { color: '#9F00FF', title: 'Violeta' },
    { color: '#FF00FF', title: 'Magenta' },
    { color: '#FF0099', title: 'Fucsia' }
  ],
  OSCUROS: [
    { color: '#880000', title: 'Granate' },
    { color: '#882F00', title: 'Caoba o Rojo indio' },
    { color: '#884700', title: 'Marrón o Pardo' },
    { color: '#886600', title: 'Marrón dorado' },
    { color: '#888800', title: 'Oliva' },
    { color: '#588800', title: 'Verde palta' },
    { color: '#008800', title: 'Verde estándar' },
    { color: '#008858', title: 'Esmeralda' },
    { color: '#008888', title: 'Cerceta o Azul verde' },
    { color: '#005288', title: 'Añil' },
    { color: '#000080', title: 'Azul marino' },
    { color: '#880088', title: 'Azul púrpura' },
    { color: '#880088', title: 'Púrpura o Morado' },
    { color: '#880047', title: 'Vino' }
  ],
  AGRISADOS: [
    { color: '#BF4040', title: 'Lacre' },
    { color: '#BF6340', title: 'Cobre' },
    { color: '#BF8340', title: 'Cnalea o Ocre' },
    { color: '#BF9F40', title: 'Dorado' },
    { color: '#BFBF40', title: 'Chartreuse' },
    { color: '#93BF40', title: 'Verde manzana' },
    { color: '#93BF40', title: 'Verde bosque' },
    { color: '#40BF40', title: 'Verde mar' },
    { color: '#40BFBF', title: 'Turquesa' },
    { color: '#408CBF', title: 'Azul acero' },
    { color: '#4040BF', title: 'Zafiro' },
    { color: '#9340BF', title: 'Amastista' },
    { color: '#B140BF', title: 'Púrpureo' },
    { color: '#B140BF', title: 'Fandango' }
  ],
  CLAROS: [
    { color: '#FF7777', title: 'Coral' },
    { color: '#FF9977', title: 'Salmón' },
    { color: '#FFB977', title: 'Melón o Durazno' },
    { color: '#FFDA80', title: 'Crema' },
    { color: '#FFFF88', title: 'Maíz' },
    { color: '#CFFF77', title: 'Té verde' },
    { color: '#77FF77', title: 'Verde claro' },
    { color: '#77FFAA', title: 'Menta' },
    { color: '#80FFFF', title: 'Aguamarina' },
    { color: '#73C7FF', title: 'Celeste' },
    { color: '#8082FF', title: 'Bígaro o aciano' },
    { color: '#C977FF', title: 'Lavanda o lila' },
    { color: '#FF77FF', title: 'Malva' },
    { color: '#FF77C1', title: 'Rosado' }
  ]
};

export const LETTERS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

export const SUCCESS = Status.SUCCESS;
export const ERROR = Status.ERROR;
export const LOADING = Status.LOADING;
export const NONE = Status.NONE;

export const SEC_30 = 1000 * 30;
export const SEC_60 = 1000 * 60;
export const SEC_90 = 1000 * 90;
export const SEC_120 = 1000 * 120;

export const DEFAULT_JUKI_PROFILE_IMAGE = 'https://juki-judge.s3.us-east-2.amazonaws.com/public/file/5f69783aa7a8e048ef8d0ce2.';
