import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'antd';

import { classNames } from '../../helpers';
import { LoaderButtonProps } from './types';
import { LoaderState, Status } from '../../types';

import './styles.scss';

// no use memo when there are callbacks on the props, or be careful
export const LoaderButton = ({ type, className, onClick, children, icon, disabled = false, block = false }: LoaderButtonProps) => {
  
  const [loader, setLoader] = useState<LoaderState>([new Date().getTime(), Status.NONE]);
  const refTimeOut = useRef<ReturnType<typeof setTimeout> | null>(null);
  useEffect(() => {
    if (loader[1] === Status.SUCCESS || loader[1] === Status.ERROR) {
      refTimeOut.current = setTimeout(() => setLoader(prevState => [prevState[0], Status.NONE]), 1200);
    }
    return () => {
      if (refTimeOut.current) {
        clearTimeout(refTimeOut.current);
      }
    };
  }, [loader]);
  
  return (
    <Button
      type={type}
      className={classNames(
        className || '',
        { success: loader[1] === Status.SUCCESS }, { error: loader[1] === Status.ERROR }
      )}
      onClick={onClick(setLoader, loader)}
      loading={loader[1] === Status.LOADING}
      icon={icon}
      disabled={disabled}
      htmlType="submit"
      block={block}
    >
      {children}
    </Button>
  );
};

export * from './types';
