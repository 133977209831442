import React from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';

import { useAccountDispatch } from '../../../hooks';
import { NotificationType } from '../../../commons/types';
import { useFlagsDispatch } from '../../../hooks';
import { LoginModal, LoginInput } from '../../../commons/components';
import { LoaderAction } from '../../../types';

const loginSchema = yup.object().shape({
  nickname: yup.string()
    .required('Required'),
  password: yup.string()
    .required('Required')
});

const Login = () => {

  const { signIn, googleSignIn, recoverAccount } = useAccountDispatch();
  const { updateFlags } = useFlagsDispatch();

  const onSubmit = (data: LoginInput, setLoading: LoaderAction) => {
    signIn(data.nickname, data.password, setLoading)();
  };
  const useFormLoginInput = useForm<LoginInput>({
    resolver: yupResolver(loginSchema),
    mode: 'onChange',
    reValidateMode: 'onChange'
  });
  const loginWithGoogle = (setLoading?: LoaderAction) => (response: any) => {
    if (response && !response.error) {
      googleSignIn(response.tokenId, setLoading)();
    } else {
      updateFlags({ lastNotification: { type: NotificationType.ERROR, description: response.error } })();
    }
  };

  return (
    <LoginModal
      onCancel={updateFlags({ openLoginModal: false })}
      onSignUpButton={updateFlags({ openLoginModal: false, openSignUpModal: true })}
      onSubmit={onSubmit}
      useFormLoginInput={useFormLoginInput}
      imageSource="/images/juki-sign-person.svg"
      loginWithGoogle={loginWithGoogle}
      onForgotPasswordButton={recoverAccount}
      reactAppGoogleClientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
    />
  );
};

export default Login;
