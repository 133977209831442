import { ProfileActions, UserState } from '../types';
import { actionLoaderWrapper, apiUser } from '~/services';
import { ContestTimeStatus, LoaderAction } from '~/types';
import { history } from '../reducers';
import { DEFAULT_USER, ROUTES } from '~/config/constants';

export const replaceProfile = (profile: UserState) => {
  return {
    type: ProfileActions.REPLACE_PROFILE,
    profile
  };
};

export const loadUserProfile = (nickname: string, setLoader?: LoaderAction) => {
  return async (dispatch: Function) => {
    await actionLoaderWrapper(
      apiUser(nickname),
      dispatch,
      (result) => {
        dispatch(replaceProfile({ ...DEFAULT_USER, ...result.object }));
      },
      setLoader,
      () => {
        history.push(ROUTES.CONTESTS.LIST_PAGE(ContestTimeStatus.LIVE));
      }
    );
  };
};
