import { useSelector, useDispatch } from 'react-redux';
import { createSelector, ParametricSelector } from 'reselect';

import {
  googleSignIn,
  loadUserMySubmissions,
  logout,
  ping,
  recoverAccount,
  replaceAccount,
  signIn,
  signUp
} from '../store/actions';
import { AccountState } from '../store/types';
import { RootState } from '../store/reducers';
import { useCallback } from 'react';
import { LoaderAction } from '../commons/types';

const selectAccountStates: ParametricSelector<RootState, undefined, AccountState> =
  createSelector<RootState, AccountState, AccountState>(
    state => state.account,
    account => account
  );

export const useAccountState = () => {
  return useSelector((state: RootState) => selectAccountStates(state, undefined));
};

export const useAccountDispatch = () => {
  const dispatch = useDispatch();
  return {
    signIn: (nickname: string, password: string, setLoading: LoaderAction) => () => dispatch(signIn(nickname, password, setLoading)),
    googleSignIn: (tokenId: string, setLoading?: LoaderAction) => () => dispatch(googleSignIn(tokenId, setLoading)),
    recoverAccount: (email: string, setLoading?: LoaderAction) => () => dispatch(recoverAccount(email, setLoading)),
    signUp: (givenName: string, familyName: string, nickname: string, email: string, password: string, setLoading: LoaderAction) => () => dispatch(signUp(givenName, familyName, nickname, email, password, setLoading)),
    ping: useCallback(() => async () => await dispatch(ping()), [dispatch]),
    logout: (setLoader: LoaderAction) => () => dispatch(logout(setLoader)),
    loadMySubmissions: useCallback((search: string, setLoading: LoaderAction, clean) => () => dispatch(loadUserMySubmissions(search, setLoading, clean)), [dispatch]),
    replaceAccount: (account: AccountState) => () => dispatch(replaceAccount({ ...account }))
  };
};
