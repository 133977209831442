import React, { lazy, Suspense, useState } from 'react';
import { Checkbox, Input } from 'antd';
import { useTranslation } from 'react-i18next';

import { LoaderIcon } from '../Loader';
import { classNames } from '../../helpers';
import { MonacoEditorProps } from './types';

import './styles.scss';

const CodeEditor = lazy(() => import('react-monaco-editor'));
const { TextArea } = Input;

const AUTO = 'auto';

// the theme applies to all components on the same view...
export const MonacoEditor = ({ language, source, dark, onChange, height = AUTO, forceEditor = false }: MonacoEditorProps) => {
  const [editor, setEditor] = useState(forceEditor);
  const { t } = useTranslation();
  
  return (
    <div
      className={classNames('layout-monaco-editor', { 'force-editor': forceEditor })}
      style={{ height: height === AUTO ? source.split('\n').length * 19 + (editor ? 0 : 9) /* padding 8 * 2 */ + (forceEditor ? 0 : 24) /* check box */ + 'px' : height }}
    >
      <div className="text-xs">
        <Checkbox
          className="checkbox-label text-xs"
          onChange={e => setEditor(e.target.checked)}
          checked={editor}
        >
          {t('enable monaco editor')}
        </Checkbox>
      </div>
      <div className={'textarea-code-editor' + (!editor ? ' with-scroll' : '')}>
        {editor ? (
          <Suspense fallback={<LoaderIcon />}>
            <CodeEditor
              width="100%"
              height="100%"
              language={language}
              theme={dark ? 'vs-dark' : 'vs-light'}
              value={source}
              onChange={onChange}
              options={{
                fontSize: 14,
                readOnly: !onChange,
                scrollBeyondLastLine: false
              }}
            />
          </Suspense>
        ) : (
          <TextArea
            autoSize={{ minRows: 10 }}
            value={source}
            onChange={({ target }) => onChange && onChange(target.value)}
            style={{ lineHeight: '19px' }}
          />
        )}
      </div>
    </div>
  );
};

export * from './types';
