import React, { useRef, useState } from 'react';
import { Controller } from 'react-hook-form';
import { Divider, Form, Input, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import GoogleLogin from 'react-google-login';

import { LoaderButton } from '../LoaderButton';
import { consoleWarn } from '../../helpers';
import { FieldEditable } from '../FieldEditable';
import { LoaderAction } from '../../types';
import { LoginInput, LoginModalProps } from './types';

import './styles.scss';

export const LoginModal = ({ onCancel, onSignUpButton, onSubmit, imageSource, useFormLoginInput, loginWithGoogle, onForgotPasswordButton, reactAppGoogleClientId }: LoginModalProps) => {
  
  const { t } = useTranslation();
  const refSetLoading = useRef<undefined | LoaderAction>();
  const [openForgotPasswordModal, setOpenForgotPasswordModal] = useState(false);
  const [email, setEmail] = useState('');
  
  const { handleSubmit, errors, formState, control } = useFormLoginInput;
  const { isValid } = formState;
  
  return (
    <Modal visible={true} footer={null} onCancel={onCancel} className="modal-login" centered>
      <Modal
        visible={openForgotPasswordModal}
        footer={null}
        onCancel={() => setOpenForgotPasswordModal(false)}
        className="modal-forgot-password"
        centered
      >
        <h5>{t('recover your account')}</h5>
        <p>
          {t('enter your email, our team will send you an email with your new password, once you enter we suggest you change your password')}
        </p>
        <div>
          <Form layout="vertical">
            <Form.Item>
              <FieldEditable value={email} onChange={(value) => setEmail(value)} hotChange={true} type="email" />
            </Form.Item>
            <Form.Item>
              {onForgotPasswordButton && (
                <LoaderButton
                  type="primary"
                  onClick={(setLoading) => onForgotPasswordButton(email, setLoading)}
                  disabled={!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(email)}
                >
                  {t('send me')}
                </LoaderButton>
              )}
            </Form.Item>
          </Form>
        </div>
      </Modal>
      <div className="layout-left child-center">
        <div className="content-main">
          <p className="text-xh bold">{t('login')}</p>
          <p className="text-h">{t('Nice see you again!')}</p>
          <img src={imageSource} alt={'hello!'} />
        </div>
      </div>
      <div className="layout-right child-center">
        <div className="content-main">
          {loginWithGoogle && reactAppGoogleClientId && (
            <>
              <GoogleLogin
                clientId={reactAppGoogleClientId}
                onSuccess={loginWithGoogle(refSetLoading.current)}
                onFailure={(err) => consoleWarn({ message: 'FAILURE GOOGLE LOGIN', err })}
                cookiePolicy="single_host_origin"
                buttonText={t('login with Google')}
              />
              <Divider>{t('or')}</Divider>
            </>
          )}
          <Form layout="vertical">
            <Form.Item label={t('nickname')}>
              <Controller
                control={control}
                as={<Input className="short-box" />}
                name="nickname"
                defaultValue=""
              />
              <p className="validator-error">{t(errors.nickName && errors.nickname.message)}</p>
            </Form.Item>
            <Form.Item label={t('password')} className="input-password">
              {onForgotPasswordButton && (
                <span className="forgot-password-link" onClick={() => setOpenForgotPasswordModal(true)}>
                  {t('Forgot password?')}
                </span>
              )}
              <Controller
                control={control}
                as={<Input.Password />}
                name="password"
                defaultValue=""
              />
              <p className="validator-error">{t(errors.password && errors.password.message)}</p>
            </Form.Item>
            <Form.Item className="login-content">
              <p className="label">
                {t('Not a member?')},&nbsp;
                <span className="sign-up-link" onClick={onSignUpButton}>{t('sign up now')}</span>
              </p>
              <LoaderButton
                type="primary"
                onClick={(setLoading) => {
                  refSetLoading.current = setLoading;
                  return handleSubmit((data: LoginInput) => onSubmit(data, setLoading));
                }}
                disabled={!isValid}
              >
                {t('login')}
              </LoaderButton>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Modal>
  );
};

export * from './types';
