import { Submission } from './contest';
import { AccountStatus, PagedArray, ProfileSettingOptions, ScopeData } from '~/types';

export interface SubmissionProfile extends Submission {
  problemName: string,
  problemId: string,
  indexProblem?: string,
  contestKey?: string
}

export interface AccountDataBaseState {
  givenName: string,
  familyName: string,
  email: string,
  nickname: string,
  imageUrl: string,
  aboutMe: string,
  country: string,
  city: string,
  institution: string
  settings: Array<{ key: ProfileSettingOptions, value: string }>
  handles: { [key: string]: string },
}

export interface AccountBaseState extends AccountDataBaseState {
  status: AccountStatus,
  submissions: PagedArray<SubmissionProfile>,
}

export interface AccountStateFetch extends AccountBaseState {
  permissions: Array<{ key: ScopeData, value: string }>,
}

export interface AccountState extends AccountBaseState {
  permissions: { [key in ScopeData]: string },
  mySettings: { [key in ProfileSettingOptions]: string },
  isLogged: boolean,
}

export enum AccountActions {
  UPDATE_ACCOUNT = 'UPDATE_ACCOUNT'
}

export interface UpdateAccountAction {
  type: AccountActions.UPDATE_ACCOUNT,
  account: AccountState,
}

export type AccountActionsTypes = UpdateAccountAction;
