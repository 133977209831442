import { SearchParams } from '../types';
import { MONTH_NAMES, PAGE, PAGE_SIZE, PAGE_SIZES } from '../constants';

export const durationToDHMS = (duration: number): string => { // milliseconds
  const days = Math.floor(duration / (1000 * 60 * 60 * 24));
  duration %= 1000 * 60 * 60 * 24;
  const hours = Math.floor(duration / (1000 * 60 * 60));
  duration %= 1000 * 60 * 60;
  const minutes = Math.floor(duration / (1000 * 60));
  duration %= 1000 * 60;
  const seconds = Math.floor(duration / 1000);
  return (days ? (days + 'd ') : '') + hours + 'h ' + minutes + 'm ' + seconds + 's';
};

export const isJson = (str: string): boolean => {
  try {
    return (JSON.parse(str) && !!str);
  } catch (e) {
    return false;
  }
};

export const mex = (array: Array<number>) => {
  let i = 1;
  for (; array.indexOf(i) !== -1; i++) {
  }
  return i;
};

export const classNames = (...props: Array<string | { [key: string]: boolean }>) => {
  let classes = '';
  props.forEach(prop => {
    if (typeof prop === 'string') {
      classes += (classes ? ' ' : '') + prop;
    } else {
      for (const propKey in prop) {
        if (prop[propKey]) {
          classes += (classes ? ' ' : '') + propKey;
        }
      }
    }
  });
  return classes;
};

export const range = (start: number, end: number) => {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
};

export const getPlainText = async (url: string) => {
  return await fetch(url)
    .then(data => data.text())
    .catch((error) => {
      console.error('ERROR:', error);
      return '';
    });
};

export const objectUpdate = (base: any, update: any) => {
  if (JSON.stringify(base) === JSON.stringify(update)) {
    return base;
  }
  if (base !== null && update !== null && !Array.isArray(base) && typeof base === 'object' && typeof update === 'object') {
    const mergeKeys = new Set([...Object.keys(base), ...Object.keys(update)]);
    Array.from(mergeKeys).forEach(key => {
      if (update[key] !== null && update[key] !== undefined &&
        base[key] !== update[key]) {
        base[key] = objectUpdate(base[key], update[key]);
      }
    });
    return base;
  }
  if (update !== null && update !== undefined) {
    return update;
  }
  return base;
};

export const objectsUpdate = (base: any, ...objects: any) => {
  let newObject = { ...base };
  objects.forEach((update: any) => {
    newObject = objectUpdate(newObject, update);
  });
  return newObject;
};

export const splitTime = (timeRemaining: number): Array<{ remaining: number, label: string }> => {
  const remainings: Array<{ remaining: number, label: string }> = [];
  
  const aYearMilliseconds = 1000 * 60 * 60 * 24 * 365;
  const remainingYears = Math.floor(timeRemaining / aYearMilliseconds);
  remainings.push({ remaining: remainingYears, label: 'years' });
  
  const aWeekMilliseconds = 1000 * 60 * 60 * 24 * 7;
  const remainingWeeks = Math.floor((timeRemaining % aYearMilliseconds) / aWeekMilliseconds);
  remainings.push({ remaining: remainingWeeks, label: 'weeks' });
  
  const aDayMilliseconds = 1000 * 60 * 60 * 24;
  const remainingDays = Math.floor((timeRemaining % aWeekMilliseconds) / aDayMilliseconds);
  remainings.push({ remaining: remainingDays, label: 'days' });
  
  const aHourMilliseconds = 1000 * 60 * 60;
  const remainingHours = Math.floor((timeRemaining % aDayMilliseconds) / aHourMilliseconds);
  remainings.push({ remaining: remainingHours, label: 'hours' });
  
  const aMinuteMilliseconds = 1000 * 60;
  const remainingMinutes = Math.floor((timeRemaining % aHourMilliseconds) / aMinuteMilliseconds);
  remainings.push({ remaining: remainingMinutes, label: 'minutes' });
  
  const remainingSeconds = Math.floor((timeRemaining % aMinuteMilliseconds) / 1000);
  remainings.push({ remaining: remainingSeconds, label: 'seconds' });
  
  while (remainings[0].remaining <= 0) {
    remainings.shift();
    if (remainings.length === 3) {
      break;
    }
  }
  return remainings;
};

export const fillPageParams = (params: SearchParams) => {
  if (!(params[PAGE] && params[PAGE].length)) {
    params[PAGE] = [1];
  }
  params[PAGE][0]--;
  if (!(params[PAGE_SIZE] && params[PAGE_SIZE].length)) {
    params[PAGE_SIZE] = [PAGE_SIZES[0]];
  }
  return params;
};

export const searchBuilder = (params: SearchParams) => {
  let searchParamsString = '';
  for (const key in params) {
    if (params[key].length) {
      if (searchParamsString) {
        searchParamsString += '&';
      }
      searchParamsString += key + '=' + params[key].join(',');
    }
  }
  return (searchParamsString ? '?' : '') + searchParamsString;
};

export const objectOfSearch = (search: string): SearchParams => {
  if (!search) {
    return {};
  }
  try {
    const partial = JSON.parse('{"' + decodeURI((search || '?').substring(1)).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}');
    Object.keys(partial).forEach(key => {
      partial[key] = partial[key].split(',');
    });
    return partial;
  } catch (e) {
    console.error('ERROR: objectOfSearch', e);
  }
  return {};
};

export const consoleWarn = (warn: any) => {
  console.warn({ date: new Date(), warn });
};

export const dateToDDMMYYY = (date: Date) => {
  const month = (date.getMonth() + 1 + '').padStart(2, '0');
  const day = (date.getDate() + '').padStart(2, '0');
  const year = date.getFullYear();
  return day + '/' + month + '/' + year;
};

export const dateToHHMM = (date: Date) => {
  const hours = date.getHours();
  const minutes = date.getMinutes();
  return hours + ':' + minutes;
};

export const dateToMMMMMDDYYYY = (date: Date) => {
  const month = MONTH_NAMES[date.getMonth()];
  const day = (date.getDate() + '').padStart(2, '0');
  const year = date.getFullYear();
  return month + ' ' + day + ', ' + year;
};

export const startOfDay = (date: Date) => {
  const newDate = new Date(date);
  newDate.setHours(0, 0, 0, 0);
  return newDate;
};

export const isToday = (date: Date) => {
  const today = new Date();
  return date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear();
};

export const isSameDay = (dateA: Date, dateB: Date) => {
  return dateA.getDate() === dateB.getDate() &&
    dateA.getMonth() === dateB.getMonth() &&
    dateA.getFullYear() === dateB.getFullYear();
};

export const isSameHour = (dateA: Date, dateB: Date) => {
  return dateA.getHours() === dateB.getHours() && isSameDay(dateA, dateB);
};

