import React, { useEffect, useState } from 'react';
import { Modal, Tooltip } from 'antd';
import { CloseOutlined, CloudUploadOutlined, EditOutlined, EyeOutlined, LayoutOutlined, SaveOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import ResizeObserver from 'rc-resize-observer';

import { classNames } from '../../helpers';
import { MdMathViewer } from '../MdMathViewer';
import { UploadImage } from './UploadImage';
import { MdMathEditorProps } from './types';

import './styles.scss';

export const MdMathEditor = ({ value, onChange, uploadImageRequest }: MdMathEditorProps) => {
  
  const { t } = useTranslation();
  const [modalVisible, setModalVisible] = useState(false);
  // 0 editor-expanded, 1 editor-right-view-left, 2 editor-top-view-bottom, 3 view-expanded
  const [view, setView] = useState(1);
  const [editValue, setEditValue] = useState(value);
  const [saved, setSaved] = useState(false);
  const [size, setSize] = useState({ width: 0, height: 0 });
  useEffect(() => {
    if (saved) {
      const id = setTimeout(() => setSaved(false), 1000);
      return () => clearTimeout(id);
    }
  }, [saved]);
  useEffect(() => setEditValue(value), [value]);
  
  return (
    <div
      className="juki-layout-md-editor"
      onBlur={() => {
        if (editValue !== value) {
          onChange(editValue);
          setSaved(true);
        }
      }}
    >
      <div className="content-bar-options">
        {uploadImageRequest && (
          <div>
            <Tooltip title={t('upload a image')}>
              <CloudUploadOutlined onClick={() => setModalVisible(true)} />
            </Tooltip>
            <Modal
              visible={modalVisible}
              onOk={() => null}
              onCancel={() => setModalVisible(false)}
              footer={null}
              className="modal-upload-image"
            >
              <UploadImage uploadImageRequest={uploadImageRequest} />
            </Modal>
          </div>
        )}
        <div>
          <Tooltip title={t('cancel')}>
            <CloseOutlined
              onClick={() => {
                setView(3);
                setEditValue(value);
              }}
            />
          </Tooltip>
        </div>
        <Tooltip visible={saved} title={t('auto saved')}>
          <div>
            <Tooltip title={t(saved ? 'saved' : 'save')}>
              <SaveOutlined
                onClick={() => onChange(editValue)}
                className={classNames({ disabled: value === editValue })}
              />
            </Tooltip>
          </div>
        </Tooltip>
        <div>
          {view === 0 && (
            <Tooltip title={t('editor right & preview left')}>
              <LayoutOutlined onClick={() => setView(1)} />
            </Tooltip>
          )}
          {view === 1 && (
            <Tooltip title={t('editor top & preview bottom')}>
              <LayoutOutlined onClick={() => setView(2)} />
            </Tooltip>
          )}
          {view === 2 && (
            <Tooltip title={t('preview expanded')}>
              <EyeOutlined onClick={() => setView(3)} />
            </Tooltip>
          )}
          {view === 3 && (
            <Tooltip title={t('editor expanded')}>
              <EditOutlined onClick={() => setView(0)} />
            </Tooltip>
          )}
        </div>
      </div>
      <div
        className={classNames(
          'content-editor-preview',
          {
            'editor-expanded': view === 0,
            'editor-right-preview-left': view === 1,
            'editor-top-preview-bottom': view === 2,
            'preview-expanded': view === 3
          }
        )}
      >
        <div className="editor" style={view === 1 ? { height: size.height - 2 + 'px' } : undefined}>
          <textarea
            value={editValue}
            onChange={({ target }) => setEditValue(target.value)}
          />
        </div>
        <div className="preview">
          <ResizeObserver onResize={({ width, height }) => setSize({ width, height })}>
            <MdMathViewer source={editValue.trim()} />
          </ResizeObserver>
        </div>
      </div>
    </div>
  );
};

export * from './types';
