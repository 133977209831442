import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Col, Modal, Row } from 'antd';

import { LoaderButton, MdMathViewer, SuccessNotification } from '~/components';
import { useAccountState, useContestDispatch, useContestState, useFlagsDispatch } from '~/hooks';
import { ERROR, LOADING, ROUTES, SUCCESS } from '~/config/constants';
import { apiContestUnFrozen, BASE_URL } from '~/services';
import { ContestParamsType, ContestSettingsParams, LoaderAction } from '~/types';

import './styles.scss';

export const Overview = () => {
  
  const { key } = useParams<ContestParamsType>();
  const { canRegister, registered, timing, settings, description, canUpdate, canRejudge } = useContestState(key);
  const { isLogged } = useAccountState();
  const { registerContest, loadContest } = useContestDispatch();
  const { updateFlags } = useFlagsDispatch();
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  
  const unFrozen = async (setLoader: LoaderAction) => {
    setLoader([1, LOADING]);
    const result = await apiContestUnFrozen(key)();
    if (result.success) {
      loadContest(key)();
      setLoader([1, SUCCESS]);
      SuccessNotification({ description: 'Unfrozen successfully!' });
      setShowModal(false);
    } else {
      setLoader([1, ERROR]);
      SuccessNotification({ description: 'Error on Unfrozen' });
    }
  };
  return (
    <>
      <Modal
        visible={showModal}
        footer={null}
        onCancel={() => setShowModal(false)}
        className="modal-un-frozen-confirmation"
        centered
      >
        <p className="text-l bold">{t('Are you sure?')}</p>
        <p>{t('everyone will be able to see the final scoreboard')}.</p>
        <LoaderButton onClick={(setLoader) => () => unFrozen(setLoader)}>{t('open scoreboard')}</LoaderButton>
      </Modal>
      <Row className="content" justify="space-between">
        <Col lg={24} md={22} sm={35} className="juki-card">
          <MdMathViewer source={description} />
        </Col>
        <Col lg={10} md={12} sm={35}>
          {canUpdate ? <div className="content-side-right-bar-top">
              <div className="judge-admin">{t('you are admin')}</div>
            </div> :
            canRejudge ? <div className="content-side-right-bar-top">
              <div className="judge-admin">{t('you are judge')}</div>
            </div> : (canRegister && (registered ? (
              <div className="content-side-right-bar-top">
                <div className="registered bold">{t('registered')}</div>
              </div>
            ) : new Date().getTime() <= settings.start + timing.duration && (
              <div className="content-side-right-bar-top">
                <LoaderButton
                  onClick={(setLoader) => () => isLogged ? registerContest(key, setLoader)() : updateFlags({ openLoginModal: true })()}
                  type="primary"
                  className="color-secondary"
                  block
                >
                  {t(isLogged ? 'register' : 'to register, first login')}
                </LoaderButton>
              </div>
            )))}
          <div className="content-side-right-bar-bottom juki-card">
            {timing.duration !== timing.toSolve && (
              <div>
                <p className="text-xs semi-bold">{t('time for solve')}:</p>
                <p className="text-s semi-bold">{Math.ceil(timing.toSolve / 1000 / 60)} min</p>
              </div>
            )}
            <div>
              <p className="text-xs semi-bold">{t('penalty by incorrect answer')}:</p>
              <p className="text-s semi-bold">{Math.ceil(timing.penalty / 1000 / 60)} min</p>
            </div>
            <div>
              <p className="text-xs semi-bold">{t('scoreboard frozen after of')}:</p>
              <p className="text-s semi-bold">{Math.ceil(timing.frozen / 1000 / 60)} min</p>
            </div>
            <div>
              <p className="text-xs semi-bold">{t('submissions will not be responded to after of')}</p>
              <p className="text-s semi-bold">{Math.ceil(timing.unJudged / 1000 / 60)} min</p>
            </div>
            <div>
              <p />
              <p className="text-s semi-bold">
                {t(settings.clarifications ? 'clarifications available' : 'clarifications not available')}
              </p>
            </div>
            <div>
              {settings[ContestSettingsParams.FROZEN] && canUpdate && (
                <div className="content-clarifications">
                  <Button onClick={() => setShowModal(true)} block>
                    {t('open scoreboard')}
                  </Button>
                </div>
              )}
              {new Date().getTime() > settings.start && (
                <div className="content-side-right-bar-bottom-bottom">
                  <a
                    href={[BASE_URL, ROUTES.PARAMS.CONTEST, ROUTES.PARAMS.VIEW, key, ROUTES.PARAMS.PRINT_SCORE].join('/')}
                    target="_blank" rel="noopener noreferrer"
                  >
                    <Button block>{t('print scoreboard')}</Button>
                  </a>
                </div>
              )}
            </div>
          </div>
        </Col>
      </Row>
      {/*<LayoutSideMain></LayoutSideMain>*/}
      {/*<LayoutSideBar>
       
       </LayoutSideBar>*/}
    </>
  );
};
