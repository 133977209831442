import React, { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Layout } from 'antd';

import './i18n';
import {
  About,
  Admin,
  ContestCreate,
  ContestEdit,
  ContestPrintScore,
  Contests,
  ContestView,
  FooterApp,
  Home,
  Loader,
  NavigationBar,
  NotFound,
  NotificationTrigger,
  ProblemCreate,
  ProblemEdit,
  Problems,
  ProblemView,
  Profile,
  Ranking
} from './components';
import { useAccountDispatch, useAccountState, useRouter } from './hooks';
import { classNames } from './helpers';
import { Test } from './components/Test';
import { history } from './store/reducers';
import {
  ContestTab,
  ContestTimeStatus, ProblemTab,
  ProfileSettingOptions
} from './types';
import { ROUTES, _KEY, _SUB_SUB_TAB, _SUB_TAB, _TAB, _TYPE } from '~/config/constants';

const App = () => {
  
  const [loader, setLoader] = useState(true);
  const { ping } = useAccountDispatch();
  const { mySettings } = useAccountState();
  const { pathname } = useRouter();
  const { i18n } = useTranslation();
  useEffect(() => {
    ping()().then(() => setLoader(false));
  }, [ping]);
  useEffect(() => {
    i18n.changeLanguage(mySettings[ProfileSettingOptions.LANGUAGE]);
    document.documentElement.className = '';
    document.documentElement.classList.add(`theme-${mySettings[ProfileSettingOptions.THEME]}`);
  }, [i18n, mySettings]);
  
  const params = pathname.split('/');
  
  return (
    <Loader
      loading={loader}
      component={() => (
        <>
          <Layout
            className={classNames('juki-layout-app',
              ...params.map(param => ({ [param]: !!param })),
              { 'home': pathname === '/' },
              {
                'fixed-height': (params[1] === ROUTES.PARAMS.CONTESTS && params[2] === ContestTimeStatus.PAST) ||
                  params[1] === ROUTES.PARAMS.PROBLEMS ||
                  (params[1] === ROUTES.PARAMS.CONTEST && params[2] === ROUTES.PARAMS.VIEW && params[4] === ContestTab.SCOREBOARD) ||
                  (params[1] === ROUTES.PARAMS.CONTEST && params[2] === ROUTES.PARAMS.VIEW && params[4] === ContestTab.SUBMISSIONS) ||
                  (params[1] === ROUTES.PARAMS.CONTEST && params[2] === ROUTES.PARAMS.VIEW && params[4] === ContestTab.STATUS) ||
                  (params[1] === ROUTES.PARAMS.PROBLEM && params[2] === ROUTES.PARAMS.VIEW && params[4] === ProblemTab.EDITOR) ||
                  (params[1] === ROUTES.PARAMS.PROBLEM && params[2] === ROUTES.PARAMS.VIEW && params[4] === ProblemTab.SUBMISSIONS)
              })}
          >
            <NotificationTrigger />
            <NavigationBar />
            <main className="juki-layout-app-main">
              <Switch>
                <Route exact path={'/'} component={Home} />
                
                <Route exact path={ROUTES.CONTESTS.LIST_PAGE(_TYPE)} component={Contests} />
                <Route exact path={ROUTES.CONTESTS.PRINT_SCORE(_KEY)} component={ContestPrintScore} />
                <Route exact path={ROUTES.CONTESTS.VIEW(_KEY, _TAB, _SUB_TAB, _SUB_SUB_TAB)}
                       component={ContestView} />
                <Route exact path={ROUTES.CONTESTS.EDIT(_KEY, _TAB)} component={ContestEdit} />
                <Route exact path={ROUTES.CONTESTS.CREATE(_TAB)} component={ContestCreate} />
                
                <Route exact path={ROUTES.PROBLEMS.LIST_PAGE()} component={Problems} />
                <Route exact path={ROUTES.PROBLEMS.VIEW(_KEY, _TAB)} component={ProblemView} />
                <Route exact path={ROUTES.PROBLEMS.EDIT(_KEY, _TAB)} component={ProblemEdit} />
                <Route exact path={ROUTES.PROBLEMS.CREATE(_TAB)} component={ProblemCreate} />
                
                <Route exact path={ROUTES.RANKING.PAGE()} component={Ranking} />
                
                <Route exact path={ROUTES.ADMIN.PAGE(_TAB)} component={Admin} />
                
                <Route exact path={ROUTES.PROFILE.PAGE(_KEY, _TAB)} component={Profile} />
                
                <Route exact path={ROUTES.ABOUT.PAGE} component={About} />
                <Route exact path={'/test.test'} component={Test} />
                <Route
                  exact
                  path={'*'}
                  component={() => <NotFound redirectAction={() => {
                    setTimeout(() => {
                      history.push('/');
                    }, 2000);
                  }} />}
                />
              </Switch>
              <FooterApp />
            </main>
          </Layout>
          
          {/*<div className="app-viewport">
           <div className="app-layout">
           header
           <div className="app-body">
           <div
           className={classNames('app-main',
           ...params.map(param => ({ [param]: !!param })),
           { 'home': pathname === '/' },
           {
           'no-auto-height':
           // pathname === '/' ||
           params[1] === ROUTES.PARAMS.PROBLEMS || true ||
           (params[1] === ROUTES.PARAMS.PROBLEM && params[2] === ROUTES.PARAMS.VIEW && params[4] === ProblemTab.SUBMISSIONS) ||
           (params[1] === ROUTES.PARAMS.CONTESTS && params[2] === ContestTimeStatus.PAST) ||
           (params[1] === ROUTES.PARAMS.PROFILE && params[3] === ProfileTab.SUBMISSIONS) ||
           (params[1] === ROUTES.PARAMS.ADMIN && params[2] === AdminTab.USERS)
           })}>
           <div
           className="app-content-main">
           <Switch>
           <Route exact path={'/'} component={Home} />
           
           <Route exact path={ROUTES.CONTESTS.LIST_PAGE(_TYPE)} component={Contests} />
           <Route exact path={ROUTES.CONTESTS.PRINT_SCORE(_KEY)} component={ContestPrintScore} />
           <Route exact path={ROUTES.CONTESTS.VIEW(_KEY, _TAB, _SUB_TAB, _SUB_SUB_TAB)}
           component={ContestView} />
           <Route exact path={ROUTES.CONTESTS.EDIT(_KEY, _TAB)} component={ContestEdit} />
           <Route exact path={ROUTES.CONTESTS.CREATE(_TAB)} component={ContestCreate} />
           
           <Route exact path={ROUTES.PROBLEMS.LIST_PAGE()} component={Problems} />
           <Route exact path={ROUTES.PROBLEMS.VIEW(_KEY, _TAB)} component={ProblemView} />
           <Route exact path={ROUTES.PROBLEMS.EDIT(_KEY, _TAB)} component={ProblemEdit} />
           <Route exact path={ROUTES.PROBLEMS.CREATE(_TAB)} component={ProblemCreate} />
           
           <Route exact path={ROUTES.RANKING.PAGE()} component={Ranking} />
           
           <Route exact path={ROUTES.ADMIN.PAGE(_TAB)} component={Admin} />
           
           <Route exact path={ROUTES.PROFILE.PAGE(_KEY, _TAB)} component={Profile} />
           
           <Route exact path={ROUTES.ABOUT.PAGE} component={About} />
           <Route exact path={'/test.test'} component={Test} />
           <Route
           exact
           path={'*'}
           component={() => <NotFound redirectAction={() => {
           setTimeout(() => {
           history.push('/');
           }, 2000);
           }} />}
           />
           </Switch>
           </div>
           </div>
           <FooterApp />
           </div>
           </div>
           </div>*/}
        </>
      )}
    />
  );
};

export default App;
