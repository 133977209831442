import React, { useEffect, useState } from 'react';
import { Link, Redirect, useParams } from 'react-router-dom';
import {
  CaretLeftOutlined,
  InfoCircleOutlined,
  SettingOutlined,
  UnorderedListOutlined,
  WarningOutlined
} from '@ant-design/icons';

import { ContestNewState } from '~/store/types';
import {
  ErrorNotification,
  FieldEditable,
  JukiLayout,
  JukiSider,
  Loader,
  LoaderButton,
  MdMathEditor, MenuSideBar,
  SuccessNotification
} from '~/components';
import { CONTEST_TAB, ERROR, LOADING, ROUTES, SUCCESS } from '~/config/constants';
import { can } from '~/helpers';
import { useAccountState, useContestDispatch, useContestState } from '~/hooks';
import { ContestSetup } from '../Create/Setup';
import { ContestCreateProblems } from '../Create/Problems';
import { ContestParamsType, ContestTab, LoaderState } from '~/types';
import { apiUploadFile, BASE_URL } from '~/services';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

export const ContestEdit = () => {
  
  const { key, tab } = useParams<ContestParamsType>();
  const { editContest } = useContestDispatch();
  const contest = useContestState(key);
  const account = useAccountState();
  const { loadContest, archiveContest } = useContestDispatch();
  const [loading, setLoading] = useState<LoaderState>([new Date().getTime(), LOADING]);
  const [contestNew, setContestNew] = useState<ContestNewState>(contest);
  const { t } = useTranslation();
  
  useEffect(() => {
    loadContest(key, setLoading)();
  }, [key, loadContest]);
  useEffect(() => setContestNew(contest), [contest]);
  if (!contest?.canUpdate) {
    return <Redirect to={ROUTES.CONTESTS.VIEW(key, ContestTab.OVERVIEW)} />;
  }
  
  const menuSideBarItems = [{
    link: ROUTES.CONTESTS.EDIT(key, ContestTab.OVERVIEW),
    selected: tab === ContestTab.OVERVIEW,
    icon: <InfoCircleOutlined />,
    label: CONTEST_TAB[ContestTab.OVERVIEW].print
  }, {
    link: ROUTES.CONTESTS.EDIT(key, ContestTab.SETUP),
    selected: tab === ContestTab.SETUP,
    icon: <SettingOutlined />,
    label: CONTEST_TAB[ContestTab.SETUP].print
  }, {
    link: ROUTES.CONTESTS.EDIT(key, ContestTab.PROBLEMS),
    selected: tab === ContestTab.PROBLEMS,
    icon: <UnorderedListOutlined />,
    label: CONTEST_TAB[ContestTab.PROBLEMS].print
  }];
  if (can.changeStatusContest(account, contest)) {
    menuSideBarItems.push({
      link: ROUTES.CONTESTS.EDIT(key, ContestTab.ARCHIVE),
      selected: tab === ContestTab.ARCHIVE,
      icon: <WarningOutlined />,
      label: CONTEST_TAB[ContestTab.ARCHIVE].print
    });
  }
  
  return (
    <Loader
      loading={!(contestNew && contestNew.settings && contestNew.problems && contest.members && (loading[1] !== LOADING))}
      component={() => (
        <JukiSider
          sideBar={(isClosed: boolean) => (
            <>
              <JukiLayout type="title">
                <Link to={ROUTES.CONTESTS.VIEW(key, ContestTab.OVERVIEW)}>
                  <Button icon={<CaretLeftOutlined />} type="text">
                    {isClosed ? '' : t('cancel and back')}
                  </Button>
                </Link>
              </JukiLayout>
              <JukiLayout>
                <MenuSideBar items={menuSideBarItems} isClosed={isClosed} />
              </JukiLayout>
            </>
          )}
          sideMain={() => (
            <>
              <JukiLayout type="title">
                <div className="text-xh bold">
                  {t('title')}:
                  <FieldEditable
                    value={contestNew.name}
                    onChange={(value) => setContestNew(prevState => ({ ...prevState, name: value }))}
                  />
                </div>
                <div className="text-m bold">
                  {t('contest key')}:
                  <span className="label-url">
                      {BASE_URL}/contest/view/{contestNew.key}/overview
                    </span>
                </div>
                <LoaderButton
                  type="primary"
                  className="color-primary-light"
                  onClick={(setLoader) => () => editContest(contestNew, setLoader)()}
                >
                  {t('save contest')}
                </LoaderButton>
              </JukiLayout>
              <JukiLayout>
                {tab === ContestTab.OVERVIEW && (
                  <>
                    <MdMathEditor
                      value={contestNew.description}
                      onChange={(value) => setContestNew(prevState => ({ ...prevState, description: value }))}
                      uploadImageRequest={async (imageBlob) => {
                        const result = await apiUploadFile('image', ['problem', 'test'], imageBlob)();
                        if (result.success === SUCCESS) {
                          SuccessNotification({ description: 'Image upload successfully' });
                          return [result.object.fileUrl, result.success];
                        }
                        ErrorNotification({ description: result.message });
                        return ['', ERROR];
                      }}
                    />
                  </>
                )}
                {tab === ContestTab.SETUP &&
                <ContestSetup
                  contestNew={contestNew}
                  setContestNew={setContestNew}
                />}
                {tab === ContestTab.PROBLEMS && (
                  <ContestCreateProblems
                    contestNew={contestNew}
                    setContestNew={setContestNew}
                  />
                )}
                {tab === ContestTab.ARCHIVE && (
                  <>
                    Are you sure?<br />
                    This contest will no longer be visible to anyone<br />
                    <LoaderButton
                      type="primary"
                      className="color-primary-light"
                      onClick={(setLoader) => () => archiveContest(contestNew.key, setLoader)()}
                    >
                      ARCHIVE CONTEST
                    </LoaderButton>
                  </>
                )}
              </JukiLayout>
            </>
          )}
        />
      )}
    />
  );
};
