import { ContestMySubmission, ContestNewState, ContestPendingSubmission, ContestScoreboardContestant, ContestState, ContestSubmission } from '~/store/types';
import { API, authorizedRequest, GET, POST, PUT } from '../server.config';
import {
  AnswerClarification,
  Code,
  ContestStatus,
  ErrorResponse,
  NewClarification,
  OkListResponse,
  OkObjectResponse,
  OkPagedListResponse, OkResponse,
  ProblemVerdict,
  ProgrammingLanguage,
  SearchParams
} from '~/types';
import { clean } from './index';
import { searchBuilder } from '~/helpers';

const getContestListSTACK: Array<AbortController> = [];
export const getContestList = (params: SearchParams) => async (): Promise<ErrorResponse | OkListResponse<ContestState>> => {
  while (getContestListSTACK.length) {
    getContestListSTACK.pop()?.abort();
  }
  const controller = new AbortController();
  const signal = controller.signal;
  getContestListSTACK.push(controller);
  return clean(await authorizedRequest(API.CONTEST.CONTEST() + searchBuilder(params), GET, '', signal));
};

const getContestSTACK: Array<AbortController> = [];
export const getContest = (key: string) => async (): Promise<ErrorResponse | OkObjectResponse<ContestState>> => {
  while (getContestSTACK.length) {
    getContestSTACK.pop()?.abort();
  }
  const controller = new AbortController();
  const signal = controller.signal;
  getContestSTACK.push(controller);
  return clean(await authorizedRequest(API.CONTEST.CONTEST(key), GET, '', signal));
};

const getContestStatusStack: Array<AbortController> = [];
export const getContestStatus = (key: string, params: SearchParams) => async (): Promise<ErrorResponse | OkPagedListResponse<ContestSubmission>> => {
  while (getContestStatusStack.length) {
    getContestStatusStack.pop()?.abort();
  }
  const controller = new AbortController();
  const signal = controller.signal;
  getContestStatusStack.push(controller);
  return clean(await authorizedRequest(API.CONTEST.STATUS(key) + searchBuilder(params), GET, '', signal));
};

const getContestPendingStatusStack: Array<AbortController> = [];
export const getContestPendingStatus = (key: string, params: SearchParams) => async (): Promise<ErrorResponse | OkListResponse<ContestPendingSubmission>> => {
  while (getContestPendingStatusStack.length) {
    getContestPendingStatusStack.pop()?.abort();
  }
  const controller = new AbortController();
  const signal = controller.signal;
  getContestPendingStatusStack.push(controller);
  return clean(await authorizedRequest(API.CONTEST.PENDING_STATUS(key) + searchBuilder(params), GET, '', signal));
};

const getContestMyStatusStack: Array<AbortController> = [];
export const getContestMyStatus = (key: string, params: SearchParams) => async (): Promise<ErrorResponse | OkPagedListResponse<ContestMySubmission>> => {
  while (getContestMyStatusStack.length) {
    getContestMyStatusStack.pop()?.abort();
  }
  const controller = new AbortController();
  const signal = controller.signal;
  getContestMyStatusStack.push(controller);
  return clean(await authorizedRequest(API.CONTEST.MY_STATUS(key) + searchBuilder(params), GET, '', signal));
};

export const apiContestCreate = (contest: ContestNewState) => async (): Promise<ErrorResponse | OkObjectResponse<ContestState>> => {
  return clean(await authorizedRequest(API.CONTEST.CONTEST(), POST, JSON.stringify({
    ...contest
  })));
};

export type SubmitShortResponse = { answer: ProblemVerdict, submitPoints: number };

export const apiSubmitContestProblem = (id: string, indexProblem: string, source: string, language: ProgrammingLanguage) => async (): Promise<ErrorResponse | OkObjectResponse<SubmitShortResponse>> => {
  return clean(await authorizedRequest(API.CONTEST.SUBMIT(id, indexProblem), POST, JSON.stringify({
    source,
    language
  })));
};

export const apiContestEdit = (contest: ContestNewState) => async (): Promise<ErrorResponse | OkObjectResponse<ContestState>> => {
  const newContest = { ...contest };
  // @ts-ignore
  delete newContest.key;
  return clean(await authorizedRequest(API.CONTEST.CONTEST(contest.key), PUT, JSON.stringify(newContest)));
};

export const apiContestChangeStatus = (key: string, status: ContestStatus) => async (): Promise<ErrorResponse | OkObjectResponse<ContestState>> => {
  return clean(await authorizedRequest(API.CONTEST.CHANGE_STATUS(key, status), PUT));
};

export const apiContestRegister = (key: string) => async (): Promise<ErrorResponse | OkObjectResponse<ContestState>> => {
  return clean(await authorizedRequest(API.CONTEST.REGISTER(key), POST));
};

export const apiContestUnFrozen = (key: string) => async (): Promise<ErrorResponse | OkObjectResponse<ContestState>> => {
  return clean(await authorizedRequest(API.CONTEST.UN_FROZEN(key), POST));
};

export const getContestClarifications = (key: string) => async (): Promise<ErrorResponse | OkListResponse<AnswerClarification>> => {
  return clean(await authorizedRequest(API.CONTEST.CLARIFICATIONS(key), GET));
};

export const apiPostContestClarifications = (key: string, body: NewClarification) => async (): Promise<ErrorResponse | OkObjectResponse<AnswerClarification>> => {
  return clean(await authorizedRequest(API.CONTEST.CLARIFICATIONS(key), POST, JSON.stringify(body)));
};

export const apiUpdateContestClarification = (key: string, idClarification: string, body: AnswerClarification) => async (): Promise<ErrorResponse | OkObjectResponse<AnswerClarification>> => {
  return clean(await authorizedRequest(API.CONTEST.ANSWER_CLARIFICATION(key, idClarification), PUT, JSON.stringify({
    answer: body.answer,
    publicVisible: body.publicVisible,
    problem: body.problem,
    question: body.question
  })));
};

export const apiContestScoreboard = (key: string) => async (): Promise<ErrorResponse | OkListResponse<ContestScoreboardContestant>> => {
  return clean(await authorizedRequest(API.CONTEST.SCOREBOARD(key)));
};

export const apiContestRejudgeProblem = (key: string, problemIndex: string) => async (): Promise<ErrorResponse | OkListResponse<SubmitShortResponse>> => {
  return clean(await authorizedRequest(API.CONTEST.REJUDGE_PROBLEM(key, problemIndex), POST));
};

export const apiContestRejudgeSubmission = (key: string, submissionMongoId: string) => async (): Promise<ErrorResponse | OkObjectResponse<SubmitShortResponse>> => {
  return clean(await authorizedRequest(API.CONTEST.REJUDGE_SUBMISSION(key, submissionMongoId), POST));
};

export const apiContestSourceSubmission = (key: string, submissionMongoId: string) => async (): Promise<ErrorResponse | OkObjectResponse<Code>> => {
  return clean(await authorizedRequest(API.CONTEST.VIEW_SOURCE_SUBMISSION(key, submissionMongoId)));
};

export const apiContestJudgeSubmission = (key: string, submissionMongoId: string, verdict: ProblemVerdict) => async (): Promise<ErrorResponse | OkResponse> => {
  return clean(await authorizedRequest(API.CONTEST.JUDGE_SUBMISSION(key), POST, JSON.stringify({ submitId: submissionMongoId, verdict })));
};
