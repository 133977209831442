import React, { useEffect, useRef, useState } from 'react';

import { ERROR, LOADING, NONE, ROUTES, SEC_30, SUCCESS } from '~/config/constants';
import { useAccountState, useContestDispatch, useContestState, useFlagsDispatch, useInterval, useRouter } from '~/hooks';
import { ContestParamsType, ContestTab, LoaderState, NotificationType, ProblemTab } from '~/types';
import { SubmissionsTable } from './SubmissionTable';
import { LoaderButton, Pagination } from '~/components';
import { DataIndexSubmission, RowDataSubmission } from './SubmissionTable/types';
import { apiContestRejudgeSubmission } from '../../../../services';
import { ReloadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

export const Status = () => {
  
  const { search, params } = useRouter<{ params: ContestParamsType }>();
  const { key } = params;
  const { submissions, problems, settings, timing, canRejudge } = useContestState(key);
  const { loadContestStatus } = useContestDispatch();
  const [loading, setLoading] = useState<LoaderState>([new Date().getTime(), NONE]);
  const { nickname } = useAccountState();
  const { t } = useTranslation();
  const { updateFlags } = useFlagsDispatch();
  const firstCallRef = useRef(0);
  useEffect(() => {
    if (settings.start < new Date().getTime()) {
      loadContestStatus(key, search, !!firstCallRef.current, setLoading)();
      firstCallRef.current++;
    }
  }, [key, loadContestStatus, search, settings.start]);
  useInterval(() => {
    loadContestStatus(key, search, false, setLoading)();
    // ( started ) && ( live)
  }, settings.start < new Date().getTime() && new Date().getTime() - (3 * SEC_30) <= settings.start + timing.duration ? SEC_30 : 0);
  
  const data = submissions.list.map((submission): RowDataSubmission => ({
    [DataIndexSubmission.KEY]: submission.submitId,
    [DataIndexSubmission.WHO]: { nickname: submission.nickname, imageUrl: submission.imageUrl },
    [DataIndexSubmission.WHEN]: submission.when,
    [DataIndexSubmission.TIME_USED]: submission.timeUsed,
    [DataIndexSubmission.MEMORY_USED]: submission.memoryUsed,
    [DataIndexSubmission.VERDICT]: { verdict: submission.answer, points: submission.submitPoints },
    [DataIndexSubmission.LANGUAGE]: submission.language,
    [DataIndexSubmission.CODE]: (canRejudge || submission.nickname === nickname) ? submission : null,
    [DataIndexSubmission.PROBLEM]: {
      link: ROUTES.CONTESTS.VIEW(key, ContestTab.PROBLEM, problems[submission?.indexProblem]?.index, ProblemTab.STATEMENT),
      index: problems[submission?.indexProblem]?.index || '',
      name: problems[submission?.indexProblem]?.name
    },
    [DataIndexSubmission.OPS]: () => {
      return (
        <div className="cell-body-ops">
          <LoaderButton
            onClick={setLoader => async () => {
              setLoader([1, LOADING]);
              const result = await apiContestRejudgeSubmission(key, submission.submitId)();
              if (result.success === SUCCESS) {
                setLoader([1, SUCCESS]);
                updateFlags({
                  lastNotification: {
                    type: NotificationType.INFO,
                    description: t('successfully rejudge') + `${result.object.answer} ${result.object.submitPoints} pnts`
                  }
                })();
              } else {
                updateFlags({
                  lastNotification: {
                    type: NotificationType.INFO,
                    description: result.message
                  }
                })();
                setLoader([1, ERROR]);
              }
            }}
            icon={<ReloadOutlined />}
            className="rejudge"
          />
        </div>
      );
    }
  }));
  
  const head = {
    [DataIndexSubmission.KEY]: true,
    [DataIndexSubmission.WHO]: true,
    [DataIndexSubmission.WHEN]: true,
    [DataIndexSubmission.TIME_USED]: true,
    [DataIndexSubmission.MEMORY_USED]: true,
    [DataIndexSubmission.VERDICT]: true,
    [DataIndexSubmission.LANGUAGE]: true,
    [DataIndexSubmission.CODE]: canRejudge,
    [DataIndexSubmission.PROBLEM]: true,
    [DataIndexSubmission.OPS]: false
  };
  
  return (
    <>
      <div className="content-main-contest-status">
        <SubmissionsTable data={data} loading={loading} contestKey={key} head={head} />
      </div>
      {!!submissions.totalElements && <Pagination totalElements={submissions.totalElements} />}
    </>
  );
};
