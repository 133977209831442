import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { classNames } from '../../../commons/helpers';

import './styles.scss';

export const MenuSideBar = ({ items, isClosed }: { items: { link: string, icon: ReactNode, label: string, selected: boolean }[], isClosed: boolean }) => {
  const { t } = useTranslation();
  return (
    <div className={classNames('juki-menu-side-bar', { 'closed': isClosed })}>
      {items.map((item) => (
        <Link to={item.link}>
          <div className={classNames('item-sub-menu', { 'item-selected text-m bold': item.selected })}>
            {item.icon}<span className="item-label">{t(item.label)}</span>
          </div>
        </Link>
      ))}
    </div>
  );
};